import React, {InputHTMLAttributes, useId} from 'react'
import {RegisterOptions, useFormContext} from 'react-hook-form'

interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  id?: any
  rootclassname?: string
  rootstyle?: React.CSSProperties
  name: string
  params?: RegisterOptions<any, any>
  label?: JSX.Element | string
  className?: string
}

export function FormControlCheckbox(config: FormControlConfig) {
  const id = useId()
  const {register} = useFormContext()

  const className = `${config.className ? `form-check-input ${config.className}` : 'form-check-input'}`

  return (
    <div className={`d-flex gap-2 align-items-center ${config.rootclassname}`} style={config.rootstyle}>
      <input
        {...config}
        {...register(config.name, config.params)}
        style={{
          width: 24,
          height: 24,
          minWidth: 24,
          borderRadius: 6
        }}
        type="checkbox"
        className={className}
        autoComplete="off"
        id={id}
      />
      {config.label && <label className="color-gray-400" htmlFor={id}>{config.label}</label>}
    </div>
  )
}
