import {ApplicationContext} from "global-context"
import {ProductModel} from "core/models/product"
import {useContext, useEffect, useRef, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useNavigate, useSearchParams} from "react-router-dom"
import productService from "core/services/products.service"
import {useDebounce} from "core/utils/debounce"
import {ImageField} from "core/utils/image"
import {localeString} from "core/utils/localeString"
import {toPrice} from "core/utils/price"
import {Coin} from "components/coin"
import "./styles.scss"

export default function ProductSearch() {
  const ref = useRef(null)
  const {t} = useTranslation()
  const [searchParams] = useSearchParams()
  const [focused, setFocused] = useState(false)
  const [list, setList] = useState<ProductModel[]>([])
  const form = useForm<{search: string}>({defaultValues: {search: searchParams.get("search")}})
  const {handleSubmit, watch} = form
  const {getCategory} = useContext(ApplicationContext)
  const navigate = useNavigate()

  const searchText = watch().search
  const debouncedSearchTerm = useDebounce(searchText, 500)

  const onSubmit = handleSubmit((payload) => productService.listProducts(payload).then((res) => setList(res.results)))

  const onProduct = (e, product: ProductModel) => {
    e.preventDefault()
    navigate(`/${getCategory(product.category).slug}/${product.slug}`)
  }

  const search_results = list.map((product, key) => (
    <div key={key} className="search-results-item" onClick={(e) => onProduct(e, product)}>
      <div className="search-results-item-image-wrapper">
        <ImageField
          className="search-results-item-image"
          src={product.image}
          alt={product.slug}
          fallback="background"
        />
      </div>

      <div className="search-results-item-name flex-fill">
        <div className="weight-700 font-14">{t(localeString(product.extra, "name"))}</div>
        <div className="search-results-item-price-holder">
          <span className="search-results-item-price white-space">{toPrice(product.price_discount)}</span>

          {product.discount && product.discount_amount > 0 && (
            <span className="search-results-item-price white-space discount">{toPrice(product.price)}</span>
          )}
        </div>
      </div>

      <div className="d-flex flex-column gap-1 align-items-end">
        {product.bonus > 0 && <Coin bonus={product.bonus} className="product__item__badge discount" />}
        {product.discount && product.discount_amount > 0 && <div className="product__item__badge discount">-{product.discount_amount}%</div>}
        {product.type === 'set' && <div className="product__item__badge kit">{t("product.set")}</div>}
      </div>
    </div>
  ))

  useEffect(() => {
    onSubmit()
  }, [debouncedSearchTerm])

  useEffect(() => {
    const outsideClickListener = (e: PointerEvent) => setFocused(e.target === ref.current)
    document.addEventListener('click', outsideClickListener)
    return () => document.removeEventListener('click', outsideClickListener)
  }, [])

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} autoComplete="false" className="d-flex flex-column w-100 bg-white search-holder">

        <div className="input-group">
          <span className="input-group-icon yume yume-search font-18" />
          <input
            ref={ref}
            type="text"
            autoComplete="off"
            autoFocus={false}
            placeholder={t("product.searching")}
            className="form-control"
            value={watch("search")}
            onChange={(e) => form.setValue("search", e.target.value)}
          />
        </div>

        {focused && watch("search") && (
          list.length > 0 ? (
            <div className="search-results-list">{search_results}</div>
          ) : (
            <div className="search-results-list">
              <div className="d-flex p-3">Список пуст</div>
            </div>
          )
        )}
      </form>
    </FormProvider>
  )
}
