export const grant = {
  content_header: "Гранты",
  submit_btn: "Подать на грант",
  header: {
    slogan: "Выиграй грант<br>на творческий проект",
    period: "Прием заявок – каждый месяц <span class='weight-500'>с 20 по 22 число</span>",
    btn: "Ознакомиться с условиями",
  },
  goals: {
    header: "Цель проекта",
    text: `Каждый месяц <b class='color-primary'>с 20 по 22 число</b> yume.rent даёт возможность всем желающим получить грант на технику суммой <b class='color-primary'>до 300 тысяч</b> для реализации творческих проектов.`,
  },
  steps: {
    header: "Как получить грант?",
    list: [
      {
        header: "Шаг 1",
        text: "Создать личный кабинет",
      },
      {
        header: "Шаг 2",
        text: "Забронировать нужную технику",
      },
      {
        header: "Шаг 3",
        text: "Заполнить форму заявки",
      },
    ],
  },
  requirements: {
    header: "Условия получения гранта",
    list: [
      "Только для творческих проектов",
      "Blue – 200 000 ₸ • Silver – 300 000 ₸ • Gold – 400 000 ₸",
      "Оценка потенциала проекта",
      "Возраст от 18 лет",
    ],
  },
  winners: {
    header: "Предыдущие победители",
    month: "Победитель «{{date}}»",
    detail_btn: "О победителе",
    idea_header: "Идея проекта",
    basket_header: "Список техники",
    add_to_card: "Добавить все в корзину",
    success_add: "Товары успешно добавлены",
    to_basket: "Перейти в корзину",
    entity: "{{count}} шт.",
    file_btn: "Посмотреть работу",
  },
  about: {
    header: "О грантах",
    text: `Каждый месяц <b class='color-primary'>с 20 по 22 число</b> yume.rent даёт возможность всем желающим получить грант на технику суммой <b class='color-primary'>до 300 тысяч</b> для реализации творческих проектов.`,
    error: `Подача на гранты доступна каждый месяц <b class='color-primary white-space'>с 20 по 22 число</b>`,
    basket: "Выберите товары, которые хотите подать на грант и перейдите в корзину для подачи"
  },
  modal: {
    success: "Заявка подана",
    success_message: `Результаты будут доступны <span class="weight-500 color-primary">27-го числа</span> на главной странице сайта <span class="weight-500 color-primary">во вкладке «Грант»</span>`,
  },
  form: {
    first_name: 'Имя',
    last_name: 'Фамилия',
    phone: "Номер телефона",
    treatment: "Загрузить тритмент",
    instagram: {
      label: 'Ссылка на инстаграм',
      placeholder: "https://"
    },
    portfolio: {
      label: 'Ссылка на портфолио',
      placeholder: "https://"
    },
    description: {
      label: 'Описание проекта',
      placeholder: "Комментарий..."
    },
    comment: {
      label: 'Мотивационное письмо',
      placeholder: "Комментарий..."
    },
    submit: "Отправить заявку"
  }
}
