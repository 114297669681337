import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Application {
  loading: boolean;
}

const initialState: Application = {
  loading: false,
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state, { payload: loading }: PayloadAction<boolean>) => {
      state.loading = loading;
    },
  },
});

export const { setLoading } = slice.actions;

export default slice.reducer;
