import {useContext, useEffect, useState} from "react"
import {ApplicationContext} from "global-context"
import {useNavigate, useOutletContext, useParams} from "react-router-dom"
import {localeString} from "core/utils/localeString"
import {SubmitHandler, UseFormReturn} from "react-hook-form"
import {EventModel, EventsRequest} from "core/models/events"
import {useGlobalModalContext} from "components/modal/modal-context"
import {useTranslation} from "react-i18next"
import {eventsService} from "core/services/events.service"
import {FormControl} from "components/shared/input/form-control"
import {FormControlPhone} from "components/shared/input/form-control-mask"
import FormSelectV2 from "components/shared/input/form-select-v2"
import {jobTitleOptions} from "pages/auth/data"
import moment from "moment"
import {EventBadge} from "../event-badge"
import "./styles.scss"

const experienceOptions = Array.from({length: 10}, (_, i) => ({value: i + 1, label: moment.duration(i + 1, 'years').humanize()}))

export const EventsVisitPage = () => {
  const {isMobile} = useContext(ApplicationContext)
  const {showModal} = useGlobalModalContext()
  const {t} = useTranslation()
  const {form} = useOutletContext<{form: UseFormReturn<EventsRequest>}>()
  const {slug} = useParams<{slug: string}>()
  const [event, setEvent] = useState<EventModel | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    eventsService.getEvent(slug).then(setEvent)
    return () => setEvent(undefined)
  }, [slug])

  const submit: SubmitHandler<EventsRequest> = async (data) => {
    const isValid = await form.trigger()
    if (!isValid) return
    try {
      await eventsService.request(data, event?.slug)
      navigate(`/events/${event?.slug}/visit/success`)
    } catch (e) {
      const errorMessage = e.response.status >= 500 ? "Что-то пошло не так, повторите попытку еще раз" : e.response?.message

      showModal({
        centered: true,
        className: isMobile && "modal-bottom",
        component: (
          <div className={"d-flex flex-column gap-3"}>
            <span className="text-center weight-700 font-20">
              Ошибка
            </span>
            <p className="p-0 m-0 weight-500 text-center">
              {errorMessage}
            </p>
          </div>
        ),
      })
    }
  }

  const onBack = () => navigate(-1)

  return event && (
    <div>
      <div className="visit-header">
        <div className="d-flex gap-1 align-items-center pointer" onClick={onBack} >
          <span className="yume yume-left color-gray-400 font-18" />
          <span className="color-gray-400">{t('events.form.back')}</span>
        </div>

        <h1 className="visit-header-title mb-0 color-black">{t(localeString(event.extra, "title"))}</h1>
        <EventBadge event={event} />
      </div>
      <form onSubmit={form.handleSubmit(submit)} className="visit-form">
        <FormControl
          name="name"
          type="text"
          label={t('events.form.name')}
          placeholder={t('events.form.name')}
          className="form-control"
          params={{required: true}}
        />

        <FormControlPhone
          name="phone"
          className="form-control"
          label={t('events.form.phone')}
          params={{required: true}}
        />

        <FormControl
          name="age"
          type="number"
          label={t('events.form.age')}
          placeholder={t('events.form.age')}
          className="form-control"
          params={{required: true}}
        />

        <FormSelectV2
          name="profession"
          label={t('events.form.profession')}
          placeholder={t('events.form.profession')}
          options={jobTitleOptions}
          getOptionValue={(option: any) => option.value}
          getOptionLabel={(option: any) => t(option.label)}
          getValue={(ids: string[]) => jobTitleOptions.filter(g => ids.includes(g.value))}
          menuPortalTarget={document.body}
          params={{required: true}}
          isSearchable={false}
          isMulti
        />

        <FormSelectV2
          name="experience"
          label={t('events.form.experience')}
          placeholder={t('events.form.experience')}
          params={{required: true}}
          options={experienceOptions}
          getOptionValue={(option: any) => (option ? option.value : '')}
          getOptionLabel={(option: any) => (option ? t(option.label) : '')}
          getValue={(id: number) => experienceOptions.find(g => g.value === id)}
          isSearchable={false}
        />

        <button className="btn btn-primary weight-500 font-14" disabled={!form.formState.isValid || !event.form_access}>
          {t('events.form.submit')}
        </button>
        {!event.form_access && <span className="text-align-center mt-1">{t('events.form.noaccess')}</span>}
      </form>
    </div>
  )
}