import Modal, {ModalProps} from "react-bootstrap/Modal"
import {useGlobalModalContext} from "./modal-context"

export interface ModalConfig extends ModalProps {
  component: JSX.Element
  onHide?: () => void
  back?: boolean
  header?: string
  onBack?: () => void
  onBottom?: boolean
}

export const ModalContainer = (config: ModalConfig) => {
  const {hideModal, hasBack, onBack} = useGlobalModalContext()

  const handleModalToggle = () => {
    if (config.onHide !== undefined) config.onHide()
    hideModal()
  }

  return (
    <Modal
      className={`${config.onBottom ? "modal-bottom" : ""}`}
      show={config.show}
      {...config}
      onHide={handleModalToggle}
    >
      <Modal.Body>
        <div className="d-flex">
          <span className={hasBack ? "modal-remove font-12 yume yume-chevron-left" : ""} onClick={onBack} />
          {config.header && <span className="modal-header-text mb-2">{config.header}</span>}
          <span className="modal-remove font-16 color-gray-300 yume yume-remove" onClick={handleModalToggle} />
        </div>

        {config.component}
      </Modal.Body>
    </Modal>
  )
}
