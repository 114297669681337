import {useGlobalModalContext} from "components/modal/modal-context"
import {ChooseSet} from "./choose-set"
import {ProductModel} from "core/models/product"
import "./styles.scss"
import {SetModel} from "./model"
import {useTranslation} from "react-i18next"

export function AddedToFav(config: {
  group: ProductModel
  onSetAdd: (set: SetModel) => void
}) {
  // const {isMobile} = useContext(ApplicationContext)
  const {showModal} = useGlobalModalContext()
  const {t} = useTranslation()

  const onClick = () => {
    showModal({
      component: <ChooseSet group={config.group} onSetAdd={config.onSetAdd} />,
      centered: true,
      // className: isMobile && "modal-bottom",
      header: t("product.fav_set_title"),
      backdrop: "static",
    })
  }

  return (
    <div className="added-to-fav p-3" onClick={onClick}>
      <div className="added-to-fav-left">
        <span className="yume yume-like p-3 color-primary" style={{background: "#EBFBFB", borderRadius: "8px"}} />
        <div className="added-to-fav-text">
          <div className="added-to-fav-title">{t("product.fav_title")}</div>
          <div className="added-to-fav-subtitle">{t("product.fav_subtitle")}</div>
        </div>
      </div>
      <div className="yume yume-chevron-right font-12 color-gray-300"></div>
    </div>
  )
}
