import {FormControlTextarea} from "components/shared/input/form-control-textarea"
import authService from "core/services/auth.service"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"

export function CancelOrderModal({orderId, postSubmit}: {orderId: number, postSubmit: () => void}) {
  const form = useForm({defaultValues: {comment: ""}})
  const {handleSubmit, watch, setValue, register, formState} = form
  const reason = watch('comment')
  const {t} = useTranslation()

  const cancelList = [
    t("order.cancel.reason_list.0"),
    t("order.cancel.reason_list.1"),
    t("order.cancel.reason_list.2")
  ]

  const onSubmit = handleSubmit(async payload => {
    authService.cancelOrder(orderId, payload)
    postSubmit()
  })

  return (
    <FormProvider {...form}>
      <div className="d-flex flex-column gap-4">
        <div className="d-flex flex-column gap-2">
          <span className="font-14 color-gray-400">{t('order.cancel.reason')}</span>
          <div className="d-flex gap-2 flex-wrap">
            {cancelList.map((item) => (
              <div key={item} className={`feedback-item ${reason === item && "selected"}`} onClick={() => setValue('comment', item)}>
                {item}
              </div>
            ))}
          </div>
        </div>

        <FormControlTextarea params={register("comment")} rows={4} />

        <button className="btn btn-red color-white weight-500 font-14" disabled={!formState.isValid} onClick={onSubmit}>
          {t('order.cancel.submit')}
        </button>
      </div>
    </FormProvider>
  )
}
