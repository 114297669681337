import "./styles.scss"
import {ApplicationContext} from "global-context"
import {useContext} from "react"
import {ProductItem} from "components/product-item"
import {useTranslation} from "react-i18next"
import {Link} from "react-router-dom"

export function FavoritesList() {
  const {t} = useTranslation()
  const {favorites} = useContext(ApplicationContext)
  
  const favoritesView: JSX.Element[] = favorites.map((product) => <ProductItem key={product.id} product={product} />)

  return favorites.length > 0 ? (
    <div
      className="product-list-holder animation-opacity"
      children={favoritesView}
    />
  ) : (
    <div className="favorite-list-empty">
      <div className="font-16 color-gray-400">
        {t("order.empty_favorite_list")}
      </div>
      <Link to="/" className="btn btn-black font-15 weight-500">
        {t("order.go_to_catalog")}
      </Link>
    </div>
  )
}
