import {useGlobalNotificationContext} from "components/notification/notification-context"
import {AddedToFav} from "components/sets-modal/added-to-fav"
import {AddedToSet} from "components/sets-modal/added-to-set"
import {SetModel} from "components/sets-modal/model"
import {ApplicationContext} from "global-context"
import {ProductModel} from "core/models/product"
import {MouseEvent, useContext, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router"
import authService from "core/services/auth.service"
import basketService from "core/services/basket.service"
import {ImageField} from "core/utils/image"
import {localeString} from "core/utils/localeString"
import {toPrice} from "core/utils/price"
import {useGlobalModalContext} from "../modal/modal-context"
import "./styles.scss"
import CounterComponent from "components/counter"
import clsx from "clsx"
import {AuthGuestModal} from "pages/auth/modals/guess"
import {Coin} from "components/coin"

export function ProductItem(config: {
  product: ProductModel
  border?: boolean
  onNavigate?: () => void
  action?: (groupId: number) => Promise<void>
  set?: SetModel
  isNew?: boolean
}) {
  const {t} = useTranslation()
  const {authenticated, basket, getCategory, getBasket, isMobile, favorites} = useContext(ApplicationContext)
  const {showNotification, hideNotification} = useGlobalNotificationContext()
  const {showModal} = useGlobalModalContext()
  const uuid = localStorage.getItem("user_bag_id")
  const navigate = useNavigate()
  const product = config.product

  const bagItem = useMemo(() => {
    if (!product) return undefined
    return basket.children.find(b => {
      const isGroup = product.group_id === b.group_id && b.group_id !== null
      const isSet = product.set_id === b.set_id && b.set_id !== null
      const isService = product.service_id === b.service_id && b.service_id !== null
      return isGroup || isSet || isService
    })
  }, [basket, product])

  const [count, setCount] = useState(bagItem ? bagItem.count : 0)

  const onLikeAction = () => {
    if (!!config.action) {
      config.action(product.id)
    } else {
      const payload = {
        id: product.type === 'product' ? product.group_id : (product.type === 'set' ? product.set_id : product.service_id),
        type: product.type
      }
      authService
        .addToFavorites(payload)
        .then(() => !isLiked() && showNotification({
          component: (
            <AddedToFav
              group={product}
              onSetAdd={(set) => showNotification({component: <AddedToSet set={set} product={product} />})}
            />
          ),
        }))
    }
  }

  const sendBasket = (event) => {
    event.stopPropagation()

    const payload = {
      count: count === 0 ? count + 1 : count,
      group: config.product.type === 'product' ? config.product.group_id : null,
      set: config.product.type === 'set' ? config.product.set_id : null,
      service: config.product.type === 'service' ? config.product.service_id : null,
    }

    basketService.postProduct(uuid, payload).then(getBasket)
    const onBasket = () => {
      navigate("/basket")
      hideNotification()
    }

    showNotification({
      time: 2,
      component: (
        <div className="d-flex justify-content-between align-items-center p-3">
          <div className="d-flex flex-column gap-2">
            <div className="font-14 weight-600">
              Товар добавлен в корзину
            </div>
            <span onClick={onBasket} className="color-primary font-12 pointer">
              Перейти в корзину
            </span>
          </div>
          <span onClick={hideNotification} className="yume yume-remove font-16 color-gray-300" />
        </div>
      ),
    })

    if (count === 0) setCount((prev) => prev + 1)
  }

  const addToFavorite = (event) => {
    event.stopPropagation()
    if (!authenticated) {
      showModal({
        component: <AuthGuestModal text="profile.guest.product" />,
        className: isMobile && "modal-bottom",
        centered: true
      })
      return
    }
    onLikeAction()
  }

  const isLiked = () => {
    if (config.set) return true
    const id = product.type === 'product' ? product.group_id : (product.type === 'set' ? product.set_id : product.service_id)
    return product && favorites.find((fav) => fav.id === id)
  }

  const navigateProduct = () => {
    if (config.onNavigate !== undefined) {
      config.onNavigate()
    } else {
      const category = getCategory(product.category)
      navigate(`/${category.slug}/${product.slug}`)
    }
    window.scrollTo(0, 0)
  }

  const onDecrement = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    setCount((prev) => {
      if (prev !== 1) {
        const payload = {
          count: prev - 1,
          group: config.product.type === 'product' ? config.product.id : null,
          set: config.product.type === 'set' ? config.product.id : null,
          service: config.product.type === 'service' ? config.product.id : null,
        }

        basketService
          .patchProduct(uuid, bagItem.id, payload)
          .then(getBasket)
      } else {
        basketService.deleteProduct(uuid, bagItem.id).then(getBasket)
      }

      return prev - 1
    })
  }

  const onIncrement = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    setCount((prev) => {
      const payload = {
        count: prev + 1,
        group: config.product.type === 'product' ? config.product.id : null,
        set: config.product.type === 'set' ? config.product.id : null,
        service: config.product.type === 'service' ? config.product.id : null,
      }

      basketService
        .patchProduct(uuid, bagItem.id, payload)
        .then(getBasket)

      return prev + 1
    })
  }

  return (
    <div
      id={`${product.type}_${product.id}`}
      onClick={navigateProduct}
      className={clsx('product__item', {active: isMobile})}
    >
      <div className="product__item__image">
        <span
          className={`like pointer yume yume-like ${isLiked() ? 'color-primary' : 'color-gray-100'}`}
          onClick={addToFavorite}
        ></span>

        {product.bonus > 0 && <Coin bonus={product.bonus} className="bonus" />}

        <div className="product__item__image__wrapper">
          <ImageField
            className="product__item__image__content"
            src={product.image}
            fallback='background'
            alt={t(localeString(product.extra, "name"))}
          />
        </div>

        <div className="d-flex gap-1 product__item__badges">
          {config.isNew && <div className="product__item__badge new">New</div>}
          {product.type === 'set' ? <div className="product__item__badge kit">{t("product.set")}</div> : <></>}
          {product.discount_amount ? <div className="product__item__badge discount">-{product.discount_amount}%</div> : <></>}
        </div>
      </div>

      {!isMobile && (
        <span className="font-13 color-gray-400 overflow">
          {t(localeString(getCategory(product.category).extra, "name"))}
        </span>
      )}

      <div style={{minHeight: "50px"}} className="weight-700 font-16 overflow two">
        {t(localeString(product.extra, "name"))}
      </div>

      <div style={{marginTop: 0}} className="d-flex gap-2">
        <div className="weight-500">{toPrice(product.price_discount)}</div>
        {product.discount && <div className="weight-500 text-decoration-line-through color-red">{toPrice(product.price)}</div>}
      </div>

      <CounterComponent
        onIncrement={onIncrement}
        onDecrement={onDecrement}
        count={count}
        max={product.amount}
        onAdd={sendBasket}
        content={t("product.add_card")}
        className="w-100"
      />
    </div>
  )
}
