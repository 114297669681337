import {useState, useEffect, useContext, ChangeEvent, Dispatch, SetStateAction} from 'react'
import {useNavigate, useParams} from 'react-router'
import {ApplicationContext} from 'global-context'
import authService from 'core/services/auth.service'
import {Button, Tooltip} from 'antd'
import {toPrice} from 'core/utils/price'
import {useGlobalModalContext} from 'components/modal/modal-context'
import {SuccessModal} from 'components/modal/modal-success'
import CopyIcon from 'assets/icons/copy-icon.svg'
import KaspiIcon from 'assets/icons/kaspi-icon.svg'
import {useTranslation} from 'react-i18next'
import {OrderDetailModel} from 'core/models/order'
import {HeaderWrapper} from "components/content-header/wrapper"
import './payment.scss'

export function PaymentPage(config: {setOrder?: Dispatch<SetStateAction<OrderDetailModel>>}) {
  const {t} = useTranslation()
  const {profile, isMobile, setContentHeaderConfig} = useContext(ApplicationContext)
  const {showModal, hideModal} = useGlobalModalContext()
  const {orderId} = useParams()
  const [order, setOrder] = useState<OrderDetailModel>()
  const [payAmount, setPayAmount] = useState<number>()
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const isUsingBonus = order && +order.bonus_used > 0

  const toggleKaspiPay = () => {
    showModal({
      component: (
        <>
          <div style={{marginBottom: 24}}>
            {!isMobile && (
              <div className="payment-confirm-header" style={{marginBottom: 8, textAlign: 'center'}}>
                {t('order.kaspi.title')}
              </div>
            )}
            <div className="payment-confirm-text">
              <div>{t('order.kaspi.text_1')}</div>
              <div>{t('order.kaspi.text_2')}</div>
              <div>{t('order.kaspi.text_3')}</div>
            </div>
          </div>
          <div className="d-flex gap-2" style={{marginBottom: 24}}>
            <input className="form-control weight-600" type="text" disabled value={payAmount} />
            <Button
              className={`d-flex copy-price-btn ${loading && 'disabled'}`}
              loading={loading}
              onClick={copyToClipboard}
            >
              <img src={CopyIcon} alt="" style={{marginRight: 8}} />
              {t('order.kaspi.copy')}
            </Button>
          </div>
          <div className="payment-buttons-container no-padding">
            <Button
              className={`btn payment-btn kaspi-pay ${loading && 'disabled'}`}
              loading={loading}
              onClick={proceedToKaspi}
            >
              <img src={KaspiIcon} alt="" style={{marginRight: 8}} />
              {t('order.kaspi.proceed')}
            </Button>
          </div>
        </>
      ),
      centered: true,
      className: isMobile && 'modal-bottom',
    })
  }

  const toggleOnReceive = () => {
    showModal({
      component: (
        <>
          <div style={{marginBottom: 20, textAlign: 'center'}}>
            <div className="payment-confirm-header" style={{marginBottom: 8}}>
              {t('order.approve_title')}
            </div>
            <div className="payment-confirm-text">{t('order.on_receive_text')}</div>
          </div>
          <div className="payment-buttons-container no-padding">
            <Button
              className={`btn payment-btn card-pay ${loading && 'disabled'}`}
              loading={loading}
              onClick={proceedToPayOnReceivement}
            >
              {t('order.on_receive_confirm_btn')}
            </Button>
            <Button className={`btn payment-btn ${loading && 'disabled'}`} loading={loading} onClick={toggleOnReceive}>
              {t('order.deny_btn')}
            </Button>
          </div>
        </>
      ),
      centered: true,
      className: isMobile && 'modal-bottom',
    })
  }

  const toggleOnReceiveResult = () => {
    showModal({
      component: (
        <SuccessModal
          header={'Заказ успешно создан'}
          message={
            <div
              className="text-align-center"
              dangerouslySetInnerHTML={{
                __html: `Можете забрать его <span class="color-primary">с 09:00 до 21:00</span> по адресу Желтоксан 168А`,
              }}
            />
          }
          confirm_btn={'Вернуться в личный кабинет'}
          onConfirm={() => navigate(isMobile ? '/profile' : '/profile/orders')}
        />
      ),
    })
  }

  const onPayment = () => {
    const widget = (window as any).callPayment('ru-RU')
    const payload = {
      publicId: 'pk_24fcca7af25cea08623d447efb128',
      description: 'Оплата аренды в yume.rent',
      amount: order.price_discount - order.paid_amount,
      currency: 'KZT',
      invoiceId: order.id,
      skin: 'mini',
      autoClose: 5,
      data: {},
    }
    const actions = {
      onSuccess: async () => {
        await getOrder()
        navigate(`/profile/orders/${orderId}`)
        hideModal()
      },
      onFail: (reason, options) => { },
      onComplete: (paymentResult, options) => { },
    }

    widget.pay('auth', payload, actions)
  }

  const getOrder = async () => {
    const _order: OrderDetailModel = await authService.getOrder(orderId)
    setOrder(_order)
    setPayAmount(_order.price_discount - _order.paid_amount)
    if (config.setOrder) config.setOrder(_order)
  }

  useEffect(() => {
    getOrder()
    isMobile && setContentHeaderConfig({content: <HeaderWrapper>{t('order.payment_modal_title')}</HeaderWrapper>})
  }, [])

  const copyToClipboard = () => navigator.clipboard.writeText(String(payAmount))

  async function proceedToKaspi() {
    try {
      setLoading(true)
      window.open('https://pay.kaspi.kz/pay/snropnwt', '_self')
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  async function proceedToPayOnReceivement() {
    try {
      setLoading(true)
      toggleOnReceive()
      toggleOnReceiveResult()
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  async function onBonusUsage(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      await authService.createOrderBonus(+orderId)
    } else {
      await authService.deleteOrderBonus(+orderId)
    }
    await getOrder()
    authService.getProfile()
  }

  const maxBonusUsage = order && profile && Number(order.bonus_used) === 0 && Math.min(order.bonus_max, profile.bonus)
  const isOrderPaidWithBonus = isUsingBonus && payAmount - maxBonusUsage === 0

  return (
    order && (
      <div className="payment-wrapper">
        <div className="payment-info-wrapper">
          {!isMobile && <h4 className="text-align-center">{t('order.payment_modal_title')}</h4>}

          <div className="payment-info-container">
            <div className="payment-info-row">
              <div className="payment-info-text left">{t('order.sum')}</div>
              <div className="payment-info-text">{toPrice(order.price)}</div>
            </div>

            {+order.discount_amount > 0 && (
              <>
                <hr className="payment-info-hr" />
                <div className="payment-info-row">
                  <div className="payment-info-text left d-flex gap-2">
                    <span>{t('order.discounted_sum')}</span>
                    <span style={{color: 'var(--color-primary)'}}>
                      -{Math.trunc((order.discount_amount / order.price) * 100)}%
                    </span>
                  </div>
                  <div className="payment-info-text">{toPrice(order.price_discount)}</div>
                </div>
              </>
            )}
          </div>

          {order.paid_amount && (
            <div className="payment-info-container">
              <div className="payment-info-row">
                <div className="payment-info-text left">{t('order.paid')}</div>
                <div className="payment-info-text">{toPrice(order.paid_amount)}</div>
              </div>
              <hr className="payment-info-hr" />
              <div className="payment-info-row">
                <div className="payment-info-text left">{t('order.remainder')}</div>
                <div className="payment-info-text">{toPrice(order.price_discount - order.paid_amount)}</div>
              </div>
            </div>
          )}

          <div className="payment-info-container">
            <div className="payment-info-row">
              <div className="payment-bonus-switch-container">
                <Tooltip placement="bottom" title={t('order.bonus_hint')}>
                  <div className="switch">
                    <input type="checkbox" id="switch" onChange={onBonusUsage} checked={+order.bonus_used > 0} />
                    <label htmlFor="switch"></label>
                  </div>
                </Tooltip>
                <div className="mx-2">
                  <div className="payment-info-text">
                    {t('user.bonus', {value: toPrice(+order.bonus_used > 0 ? +order.bonus_used : profile.bonus, "")})}
                  </div>
                  <div className="payment-info-subtext">
                    {t(+order.bonus_used > 0 ? 'user.bonus_used' : 'user.bonus_acc')}
                  </div>
                </div>
              </div>

              <Tooltip placement="bottom" title={t('order.bonus_hint')}>
                <div className="payment-info-text spend-bonus">
                  <label htmlFor="switch">
                    {+order.bonus_used === 0 && <span>{t('order.spend_bonus')}</span>}
                    {+order.bonus_used > 0 && <span>-{toPrice(+order.bonus_used)}</span>}
                  </label>
                </div>
              </Tooltip>
            </div>
            <hr className="payment-info-hr" />
            <div className="payment-info-row">
              <div className="payment-info-text bold">{t('order.payment_total')}</div>
              <div className="payment-info-text bold">{toPrice(payAmount)}</div>
            </div>
          </div>
        </div>
        <div className="payment-buttons-container">
          <button className="btn payment-btn card-pay" disabled={true} onClick={onPayment}>
            <span className="yume yume-bank-card font-18"></span>
            {t('order.pay_card')}
          </button>
          <Button
            className={`btn payment-btn kaspi-pay ${loading && 'disabled'}`}
            loading={loading}
            disabled={isOrderPaidWithBonus}
            onClick={toggleKaspiPay}
          >
            <img src={KaspiIcon} alt="" style={{marginRight: 8}} />
            {t('order.pay_kaspi')}
          </Button>
        </div>
      </div>
    )
  )
}
