import {useNavigate} from "react-router-dom"
import {EventModel} from "../../core/models/events"
import {useTranslation} from "react-i18next"
import {localeString} from "../../core/utils/localeString"
import {MouseEvent} from "react"
import {ImageField, LazyBackgroundImage} from "core/utils/image"
import {EventBadge} from "./event-badge"
import "./style.scss"

export const EventCard = ({upcomming = false, event}: {upcomming?: boolean, event: EventModel}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    navigate(`/events/${event.slug}`)
  }

  return event && (
    <LazyBackgroundImage className={`events-card ${!upcomming ? 'past' : ''}`} src={event.image}>

      <EventBadge event={event} upcomming={upcomming}/>

      <div className="mt-3 events-card-title">
        <h1 className="mb-0 weight-700 color-white font-24 overflow two">{t(localeString(event.extra, "title"))}</h1>
        {event.residents.length > 0 && (
          <ImageField
            className="events-card-avatar"
            src={event.residents.length > 0 ? event.residents[0].image : ''}
            alt={event.slug}
            fallback="avatar"
          />
        )}
      </div>

      <button onClick={onClick} className={`btn d-flex gap-2 weight-500 w-100 ${upcomming ? 'btn-primary' : 'btn-white'}`}>
        {upcomming && !event.registered && t("events.about")}
        {upcomming && event.registered && t("events.registered")}
        {!upcomming && t("events.show")}
        {upcomming && <span className="yume yume-link font-18" />}
      </button>

    </LazyBackgroundImage>
  )
}

