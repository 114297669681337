import {useState, createContext, useContext} from 'react'
import {ModalConfig, ModalContainer} from './modal-container'

export interface ModalHistoryConfig {
  curr: ModalConfig
  prev: ModalHistoryConfig
}

export interface ModalContext {
  showModal: (props: ModalConfig) => void
  hideModal: () => void
  hasBack: boolean
  onBack: () => void
  clearHistory: () => void
}

const globalModalContext = createContext<ModalContext>(undefined)

export const useGlobalModalContext = () => useContext(globalModalContext)

export const GlobalModal = ({children}) => {
  const [config, setConfig] = useState<ModalHistoryConfig>(undefined)
  const hasBack = config && config.prev !== undefined

  const clearHistory = () => setConfig(undefined)

  const showModal = (_config: ModalConfig) => setConfig(prev => ({curr: {..._config, show: true}, prev}))
  const onBack = () => setConfig(prev => ({curr: {...prev.prev.curr, show: true}, prev: prev.prev?.prev}))

  const hideModal = () => {
    setConfig(prev => ({curr: {...prev?.curr, show: false}, prev: undefined}))
    setTimeout(clearHistory, 250)
  }

  return (
    <globalModalContext.Provider value={{showModal, hideModal, hasBack, onBack, clearHistory}}>
      <ModalContainer {...config?.curr} />
      {children}
    </globalModalContext.Provider>
  )
}
