import "./styles.scss"
import {toPrice} from "../../core/utils/price"
import check from "assets/icons/success.svg"
import {ApplicationContext} from "global-context"
import {useContext} from "react"
import {useTranslation} from "react-i18next"
import {Progress} from "antd"

export const BasketFreeDeliveryProgress = () => {
  const {basket, profile} = useContext(ApplicationContext)
  const {t} = useTranslation()
  const price = +basket.price_discount
  const delivery_price = profile ? +profile.delivery_limit : 15000
  const percent = price <= delivery_price ? 100 * price / delivery_price : 1
  const freeDelivery = price > delivery_price

  const percentView = (
    <>
      <div
        className="d-flex gap-2 align-items-center justify-content-center"
        dangerouslySetInnerHTML={{__html: t("basket.delivery.progress.pending", {price: toPrice(delivery_price - price)})}}
      />
      <Progress percent={percent} showInfo={false} trailColor='var(--color-gray-50)' strokeColor='var(--color-primary)' />
    </>
  )

  const introView = (
    <div className="d-flex gap-2 align-items-center">
      <img width={40} height={40} src={check} alt="check" />
      <div className="d-flex flex-column gap-1">
        <div className="weight-600 font-14">{t('basket.delivery.progress.title')}</div>
        <span className="font-12">{t('basket.delivery.progress.reached', {price: toPrice(delivery_price)})}</span>
      </div>
    </div>
  )

  return profile && (
    <div className="delivery-progress">
      {!freeDelivery && percentView}
      {freeDelivery && introView}
    </div>
  )
}
