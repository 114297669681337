import {EventModel} from "core/models/events"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router"
import {localeString} from "core/utils/localeString"
import toDateTime from "core/utils/date-time"
import moment from "moment"
import "./style.scss"

export const EventsDetailContent = ({event, onCancel}: {event: EventModel, onCancel: () => void}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()

  const onVisit = () => navigate(`/events/${event.slug}/visit`)
  const isStarted = moment(event.date) <= moment()
  const isRegistered = event.registered
  const isLimit = event.user_limit > event.user_registered

  return <div className="event-detail-content">
    <div
      className="event-detail-description p-0 m-0 mb-3"
      dangerouslySetInnerHTML={{__html: t(localeString(event.extra, "body"))}}
    />
    {!isRegistered && event.form_access && isLimit && (
      <button onClick={onVisit} disabled={!event.form_access} className="btn btn-primary d-flex gap-2 w-100">
        <span className="weight-500">{t("events.visit")}</span>
        <span className="yume yume-link font-18" />
      </button>
    )}
    {!isRegistered && !event.form_access && isLimit && !isStarted && (
      <div
        className="btn btn-bg-light d-flex gap-2 w-100"
        dangerouslySetInnerHTML={{__html: t("events.register_open", {date: toDateTime(event.form_access_date)})}}
      />
    )}
    {!isRegistered && !isStarted && (
      <div
        className="text-align-center color-primary font-14 my-2"
        dangerouslySetInnerHTML={{__html: t("events.remaining_places", {count: event.user_limit - event.user_registered})}}
      ></div>
    )}
    {isRegistered && !isStarted && <div className="btn btn-red-8 btn-color-red" onClick={onCancel}> {t("events.cancel")}</div>}
  </div>
}
