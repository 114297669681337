import {ProductModel} from "core/models/product"
import {ImageField} from "core/utils/image"
import {localeString} from "core/utils/localeString"
import {toPrice} from "core/utils/price"
import {useTranslation} from "react-i18next"
import "./styles.scss"
import {Coin} from "components/coin"

export function OrderGroupProduct({product, showPrice}: {product: ProductModel, showPrice: boolean}) {
  const {t} = useTranslation()
  const extraKeys = Object.keys(product.extra)
  const isPublishedProduct = extraKeys.includes('name_kk') && extraKeys.includes('name_ru')

  const name = isPublishedProduct ? localeString(product.extra, "name") : product.name

  return (
    <div className="order-container w-100">
      <ImageField src={product.image} className="order-container-image" fallback="background" />
      <div className="order-container-name flex-fill overflow two">{name}</div>
      <div className="d-flex flex-column align-items-end">
        <span className="d-flex gap-2 align-items-center font-16 weight-600 nowrap">
          {showPrice ? toPrice(product.price) : '-'}
          {product.bonus && product.bonus > 0 && <Coin bonus={product.bonus} />}
        </span>
        <span className="font-12 color-gray-300 nowrap">{product.count} {t("order.amount")}</span>
      </div>
    </div>
  )
}