import {useNavigate} from 'react-router-dom'
import {ProductModel} from 'core/models/product'
import {localeString} from 'core/utils/localeString'
import {useTranslation} from 'react-i18next'
import {SetModel} from './model'
import noImage from 'assets/icons/camera-icon.svg'
import {useGlobalNotificationContext} from 'components/notification/notification-context'
import {ImageField} from "core/utils/image"

export function AddedToSet(config: {set: SetModel; product: ProductModel}) {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {hideNotification} = useGlobalNotificationContext()
  const set = config.set
  const images = [...set.group_images, ...set.set_images, ...set.service_images]

  const message = `Продукт ${t(localeString(config.product.extra, 'name'))} добавлен в сет «${set.name}»`

  const image = images.length > 0 ? images[0] : noImage

  const showSet = () => {
    hideNotification()
    navigate(`profile/favorites/sets/${set.id}`)
  }

  return (
    <div className="added-to-fav p-3" onClick={showSet}>
      <div className="added-to-fav-left">
        <div className="set-card-icon">
          <ImageField src={image} fallback="background" alt="" />
        </div>
        <div className="added-to-fav-text">
          <div className="added-to-fav-title">{message}</div>
          <div className="added-to-fav-subtitle">Нажмите, чтобы перейти к сету</div>
        </div>
      </div>
      <div className="yume yume-chevron-right font-12 color-gray-300"></div>
    </div>
  )
}
