import clsx from "clsx"
import {useState} from "react"
import {useTranslation} from "react-i18next"

function BonusQA({index}: {index: number}) {
  const {t} = useTranslation()
  const [active, setActive] = useState(false)
  const onToggle = () => setActive(p => !p)

  return <div className={clsx("bonus-card w-100", {'active': active})}>
    <div className="d-flex gap-2 justify-content-between align-items-center pointer" onClick={onToggle}>
      <span className="font-16 weight-500">{t(`bonus.faq.questions.${index}`)}</span>
      <span className="yume yume-chevron-bottom font-16"></span>
    </div>
    <div className="bonus-faq-answer mt-2" onClick={onToggle} dangerouslySetInnerHTML={{__html: t(`bonus.faq.answers.${index}`)}}></div>
  </div>
}

export function BonusFAQ() {
  const {t} = useTranslation()

  return <div className="bonus-faq">
    <div className="font-14 color-gray-400">{t('bonus.faq.label')}</div>
    {Array(4).fill(0).map((_, i) => <BonusQA key={i} index={i} />)}
  </div>
}
