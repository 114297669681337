import timeIcon from "assets/icons/time-icon.svg"
import {useGlobalModalContext} from "components/modal/modal-context"
import {useTranslation} from "react-i18next"

export const BonusSubscriptionFormSuccess = () => {
  const {t} = useTranslation()
  const {hideModal} = useGlobalModalContext()
  
  return (
    <div className="d-flex flex-column justify-content-center align-items-center text-center">
      <img src={timeIcon} alt="time icon" className="mb-2" />
      <h1 className="weight-700 font-22 mb-0 mb-3">{t("basket.success.title")}</h1>
      <button className="btn btn-gray w-100" onClick={hideModal}>OK</button>
    </div>
  )
}
