export const jobTitleOptions = [
  {value: "producer", label: "auth.signup.job_title.producer"},
  {value: "director", label: "auth.signup.job_title.director"},
  {value: "illuminator", label: "auth.signup.job_title.illuminator"},
  {value: "camera mechanic", label: "auth.signup.job_title.camera_mechanic"},
  {value: "photograph", label: "auth.signup.job_title.photograph"},
  {value: "gaffer", label: "auth.signup.job_title.gaffer"},
  {value: "second director", label: "auth.signup.job_title.second_director"},
  {value: "sound director", label: "auth.signup.job_title.sound_director"},
  {value: "colorist", label: "auth.signup.job_title.colorist"},
  {value: "motion design", label: "auth.signup.job_title.motion_design"},
  {value: "vfx artist", label: "auth.signup.job_title.vfx"},
  {value: "sound designer", label: "auth.signup.job_title.sound_designer"},
  {value: "admin", label: "auth.signup.job_title.admin"},
  {value: "flashbang", label: "auth.signup.job_title.flashbang"},
]

export const genderOptions = [
  {value: "male", label: "auth.signup.gender.male"},
  {value: "female", label: "auth.signup.gender.female"},
]
