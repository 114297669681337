import {ArticleModel} from "core/models/article"
import {useContext, useEffect, useState} from "react"
import articleService from "core/services/articles.service"
import {Keyboard, Mousewheel} from "swiper"
import {Swiper, SwiperSlide} from "swiper/react"
import {ImageField} from "core/utils/image"
import {useGlobalModalContext} from "components/modal/modal-context"
import {ApplicationContext} from "global-context"
import {ArticleModal} from "./article-modal"
import {localeString} from "core/utils/localeString"
import {useNavigate} from "react-router"
import "./article-list.scss"

export function ArticleList() {
  const {isMobile} = useContext(ApplicationContext)
  const [articles, setArticles] = useState<ArticleModel[]>([])
  const {showModal} = useGlobalModalContext()
  const navigate = useNavigate()

  const onArticle = (article: ArticleModel) => {
    if (article.outer_url) {
      navigate(article.outer_url.replace('https://yume.rent', ''))
      return
    }
    showModal({
      component: <ArticleModal article={article} />,
      className: isMobile ? "modal-bottom" : "modal-lg",
      centered: true
    })
  }

  const slides = articles.map((article, key) => (
    <SwiperSlide
      key={key}
      className="articles-slider-slide"
      onClick={() => onArticle(article)}
    >
      <ImageField
        className="image"
        style={{objectFit: 'cover'}}
        src={localeString(article.extra, 'image') || article.image}
        fallback="background"
        alt={article.slug}
      />
    </SwiperSlide>
  ))

  useEffect(() => {
    articleService.listArticles().then(setArticles)
    return () => setArticles([])
  }, [])

  return (
    <Swiper
      className="articles-slider"
      loop={false}
      spaceBetween={12}
      slidesPerView="auto"
      keyboard={{enabled: true}}
      mousewheel={{forceToAxis: true, sensitivity: 0.7}}
      modules={[Keyboard, Mousewheel]}
    >
      {slides}
    </Swiper>
  )
}
