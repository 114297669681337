export const auth = {
  back: "Артқа",
  promo_title: "{{bonus}} ₸ бонус алыңыз",
  promo_decline_btn: "Маған қызық емес",
  promo: '<span class="color-primary">{{bonus}} бонус</span> алып, тапсырыстарыңыздан <span class="color-primary">{{percent}}% кэшбек</span> жинау үшін тіркеліңіз',
  create_account: "Тіркелу",
  telegram_signup: "Telegram",
  email_signup: "Эл. пошта",
  no_signup: "Тіркеусіз жалғастыру",
  has_account: "Аккаунт бар ма?",
  login: "Кіру",
  reset: "Қалпына келтіру",
  reset_password: "Құпия сөзді ұмыттыңыз ба?",
  login_error: "Құпия сөз сәйкес келмейді немесе сіз тіркелмегенсіз",
  login_header: "Аккаунтқа кіру",
  login_header_favorite: "Таңдаулыларға қосу үшін аккаунтқа кіріңіз",
  login_header_basket: "Жеткізу тапсырысы үшін сайтқа тіркеліңіз",
  phone: "Телефон нөмірі",
  password: "Құпия сөз",
  intro: {
    title: "Аккаунтқа кіріңіз",
    event_guest: "Ивенттерге қатысу үшін аккаунтқа кіріңіз немесе тіркеліңіз",
    basket_guest: "Тапсырысты рәсімдеу үшін аккаунтқа кіріңіз немесе тіркелгі жасаңыз",
    favorite_guess: "Тауарды таңдаулыларға қосу үшін аккаунтка кіріңіз немесе тіркелгі жасаңыз",
  },
  phone_form: {
    header: "Телефон нөмірін енгізіңіз",
    header_reset: "Құпия сөзді қалпына келтіру",
    request_otp: "Кодты қабылдау",
    phone_error: "Бұл нөмірі бар пайдаланушы тіркелген",
    nouser_error: "Бұл нөмірі бар пайдаланушы тіркелмеген",
    next: "Жаңа құпия сөзді сақтау",
  },
  otp_form: {
    confirm_title: "Телефон нөмірін растаңыз",
    confirm_text: "{{phone}} нөміріне SMS-код жіберілді. Оны төменде енгізіңіз",
    error: "Қате код енгізілген",
    send_again_text: "Кодты қайта жіберу:",
    send_again: "Кодты қайта жіберу",
  },
  signup: {
    title: "Тіркелу формасы",
    first_name: "Аты",
    first_name_error: "Атыңызды енгізіңіз",
    last_name: "Тегі",
    last_name_error: "Тегіңізді енгізіңіз",
    email_error: "Эл. поштаны енгізіңіз",
    birth_date: "Туған күні",
    birth_date_error: "Туған күнін енгізіңіз",
    gender: {
      none: "Жынысы",
      male: "Еркек",
      female: "Әйел",
    },
    job_title: {
      placeholder: "Қызмет түрі",
      producer: "Продюсер",
      director: "Режиссер",
      operator: "Оператор",
      illuminator: "Сәулелендіруші",
      camera_mechanic: "Механик",
      photograph: "Фотограф",
      gaffer: "Гаффер",
      second_director: "Екінші режиссер",
      sound_director: "Дыбыс режиссері",
      colorist: "Колорист",
      motion_design: "Motion дизайнер",
      editor: "Монтажер",
      vfx: "VFX артист",
      sound_designer: "Sound дизайнер",
      admin: "Администратор",
      flashbang: "Жарықтандырушы",
    },
    password: "Құпия сөз",
    password_error: "Құпия сөзді еңгізіңіз",
    re_password: "Құпия сөзді растаңыз",
    re_password_error: "Құпия сөз сәйкес келмеді",
    policy: `Мен сайттың <a href="{{privacy}}" className="link text-decoration-underline color-primary" target="_blank">құпиялылық саясатын</a> және <a href="{{offerta}}" className="link text-decoration-underline color-primary" target="_blank">көпшілікке арналған ұсынысын</a> қабылдаймын`,
    submit: "Тіркелу",
  },
  new_pass: "Жаңа құпия сөзді енгізіңіз",
  save: "Сақтау",
  signup_success_title: "Тіркелу сәтті аяқталды",
  signup_success_text: "Құттықтаймыз, сіз аккаунтты тіркедіңіз, енді сіз біздің сайттың барлық мүмкіндіктеріне қол жеткізе аласыз",
  reset_success: "Құпия сөз сәтті қалпына келтірілді"
}
