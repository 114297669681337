import {SwitcherComponent, SwitcherConfig} from "components/switch"
import {useTranslation} from "react-i18next"
import "./styles.scss"

export function OrdersTypeSwitchComponent({
  delivery,
  setDelivery
}: {
  delivery: boolean,
  setDelivery: (delivery: boolean) => void
}) {
  const {t} = useTranslation()

  const switcherConfig: SwitcherConfig[] = [
    {key: 'undefined', text: t("order.all_text"), onClick: () => setDelivery(undefined), className: "px-3 py-2"},
    {key: 'true', text: t("order.delivery_text"), onClick: () => setDelivery(true), className: "px-3 py-2"},
    {key: 'false', text: t("order.takeaway_text"), onClick: () => setDelivery(false), className: "px-3 py-2"},
  ]

  return <SwitcherComponent className="bg-light mb-3" active={String(delivery)} items={switcherConfig} />
}
