import {ApplicationContext} from "global-context"
import {useContext, useEffect} from "react"
import {Outlet, useNavigate} from "react-router"
import {useLocation} from "react-router-dom"

export function PrivateRoute({accessAllowWithOutAuthPages = []}: {accessAllowWithOutAuthPages?: string[]}) {
  const navigate = useNavigate()
  const {authenticated, setAuthModal} = useContext(ApplicationContext)
  const location = useLocation()

  useEffect(() => {
    if (!authenticated && !accessAllowWithOutAuthPages.includes(location.pathname)) {
      setAuthModal("intro")
      navigate(-1)
    }
  }, [authenticated, accessAllowWithOutAuthPages])

  return (accessAllowWithOutAuthPages.includes(location.pathname) || authenticated) && <Outlet />
}
