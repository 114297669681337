import "./styles.scss"

export interface SwitcherConfig {
    key: string
    text: string
    onClick: () => void
    className: string
}

export function SwitcherComponent(config: {active: string, items: SwitcherConfig[], className?: string}) {
    const className = config.className ? `${config.className} switcher` : `switcher`
    
    return <div className={className}>
        {config.items.map(item => (
            <button
                key={item.key}
                onClick={item.onClick}
                className={`btn switcher-btn ${item.key === config.active ? 'selected' : ''} ${item.className}`}
            >
                {item.text}
            </button>
        ))}
    </div>
}
