import {useContext, useEffect, useState} from "react"
import {useNavigate} from "react-router"
import {OrderModel} from "core/models/order"
import {OrderCurrentCardComponent} from "pages/user/orders/order-card/card-current"
import {ApplicationContext} from "global-context"
import authService from "core/services/auth.service"
import {ListSlider} from "components/list-slider"

export function CurrentOrderList() {
  const {profile} = useContext(ApplicationContext)
  const [orders, setOrders] = useState<OrderModel[]>([])
  const navigate = useNavigate()

  const onOrder = (order: number) => navigate(`/profile/orders/${order}`)

  const getOrders = async () => authService.getOrders({status__in: "0,1,2,5"}).then(setOrders)

  useEffect(() => {
    if (!profile) setOrders([])
    if (profile) getOrders()
    return () => setOrders([])
  }, [profile])

  return profile && orders.length > 0 && (
    <ListSlider<OrderModel>
      className="mt-3"
      list={orders}
      render={(order) => <OrderCurrentCardComponent order={order} onClick={() => onOrder(order.id)} updateOrders={getOrders} />}
      navigation={false}
      breakpoints={() => {
        let w = window.innerWidth
        if (w >= 1440) return 3
        return 1
      }}
    />
  )
}
