import {useTranslation} from "react-i18next"

export function BonusSubscription({onSubscription}: {onSubscription: () => void}) {
  const {t} = useTranslation()

  return <div className="bonus-card bonus-subscription">
    <h3 className="font-22 wight-600 mb-2">{t('bonus.subscription.label')}</h3>
    <div className="font-15 mb-3">{t('bonus.subscription.hint')}</div>
    <button className="btn btn-primary weight-500 w-100" onClick={onSubscription}>{t('bonus.subscription.sign')}</button>
  </div>
}
