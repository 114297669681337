import {useTranslation} from "react-i18next"
import {useNavigate, useOutletContext} from "react-router"
import {SubmitHandler, UseFormReturn} from "react-hook-form"
import {FormControlPassword} from "components/shared/input/form-control-password"
import {useEffect} from "react"
import authService from "core/services/auth.service"
import "./styles.scss"


export function PasswordForm() {
  const {t} = useTranslation()
  const {form, type} = useOutletContext<{form: UseFormReturn<any>, type: "register" | "reset"}>()
  const navigate = useNavigate()

  const submit: SubmitHandler<any> = async (data) => {
    const isValid = await form.trigger()
    if (!isValid) return

    if (type === 'reset') {
      authService.reset_password(data)
        .then(() => navigate(`/auth/login`))
        .catch(() => navigate(`/auth/reset`))
    } else {
      navigate(`/auth/${type}`)
    }
    return
  }

  useEffect(() => {
    if (!form.watch('phone')) {
      form.reset({phone: undefined, session_id: undefined, confirm_id: undefined})
      navigate(type === "register" ? "/auth/request" : "/auth/reset")
      return
    }
    if (!form.watch('session_id')) {
      form.reset({session_id: undefined})
      navigate(type === "register" ? "/auth/otp" : "/auth/reset/otp")
      return
    }
  }, [])

  const valid = form.formState.isValid && form.watch('password') === form.watch('confirm_password')

  return (
    <form onSubmit={form.handleSubmit(submit)}>
      <h4 className="mb-3 align-self-baseline weight-700" style={{alignSelf: "center"}}>
        {t("auth.new_pass")}
      </h4>
      <FormControlPassword
        name="password"
        placeholder={t("auth.signup.password")}
        className="form-control mb-3"
        params={{required: true}}
      />
      <FormControlPassword
        name="confirm_password"
        placeholder={t("auth.signup.re_password")}
        className="form-control mb-3"
        params={{required: true}}
      />
      <button className="btn btn-primary mb-0 auth-submit p-3" disabled={!valid}>
        {t("auth.phone_form.next")}
      </button>
    </form>
  )
}
