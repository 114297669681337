import {Outlet, useNavigate} from "react-router"
import {OrderForm} from "pages/basket"
import {FormProvider, useForm} from "react-hook-form"
import {useContext, useEffect} from "react"
import {ApplicationContext} from "global-context"
import {useGlobalModalContext} from "components/modal/modal-context"
import {AuthGuestModal} from "pages/auth/modals/guess"
import {useTranslation} from "react-i18next"
import "./styles.scss"

export function BasketLayout() {
    const navigate = useNavigate()
    const {basket, isMobile, profile} = useContext(ApplicationContext)
    const {showModal} = useGlobalModalContext()
    const {t} = useTranslation()

    const onBasket = () => navigate('/basket')

    const form = useForm<OrderForm>()

    useEffect(() => {
        if (profile) {
            form.reset({...basket, extra: {name: profile.last_name, last_name: profile.first_name, phone: profile.phone}})
            return
        }
        if (profile === null) {
            showModal({
                centered: true,
                component: <AuthGuestModal text="auth.intro.basket_guest" />,
                className: isMobile && "modal-bottom",
                onHide: () => navigate(-1)
            })
        }
    }, [profile])

    return <div className="layout-basket">

        <div className="layout-basket-header">
            <div onClick={onBasket} className="layout-basket-header-back">
                <span className="yume yume-left font-24" />
                <span className="weight-500 font-15">{t('basket.checkout.back')}</span>
            </div>

            <div onClick={onBasket} className="layout-basket-header-name">
                <span className="yume yume-left font-24" />
                <span className="weight-700 font-20">{t('basket.checkout.title')}</span>
            </div>
        </div>

        <div className="layout-basket-outlet-wrapper">
            <div className="layout-basket-outlet">
                <FormProvider {...form}>
                    <Outlet context={{form}} />
                </FormProvider>
            </div>
        </div>

    </div>
}