export interface ListModel<T> {
  page: number;
  results: T[];
  count: number;
  next: string | boolean;
}

export const EMPTY_LIST: ListModel<any> = {
  page: 0,
  results: [],
  count: 0,
  next: false,
};

export interface ListPageParams {
  page: number;
  pageSize: number;
  [key: string]: any;
}
