import {useState, useEffect, useContext} from "react"
import {useNavigate} from "react-router-dom"
import authService from "core/services/auth.service"
import {ApplicationContext} from "global-context"
import toDateTime from "core/utils/date-time"
import {OrdersTypeSwitchComponent} from "./switch"
import {OrderModel} from "core/models/order"
import {groupOrderByDate} from "./grouping"
import {OrderCardComponent} from "./order-card/card-web"
import {OrderCardMobileComponent} from "./order-card/card-mobile"
import {useTranslation} from "react-i18next"
import {HeaderWrapper} from "components/content-header/wrapper"
import "./styles.scss"

export function OrdersPage() {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const [params, setParams] = useState<any>({delivery: undefined})
  const [list, setList] = useState<Map<string, OrderModel[]>>(new Map<string, OrderModel[]>())
  const {isMobile, setContentHeaderConfig} = useContext(ApplicationContext)

  const getOrders = async () => {
    const _list = await authService.getOrders(params)
    const grouped_list = _list.map((ord) => ({...ord, date: new Date(ord.rent_start.slice(0, 7))}))
    setList(groupOrderByDate(grouped_list, o => o.date, "rent_start", 'rent_end'))
  }

  const dateGroups = Array.from(list).map(([date, orders]) => (
    <div key={date}>
      <div className="mb-2 color-gray-300 font-14">{toDateTime(date, "MMMM")}</div>
      <div className="d-flex flex-column gap-3 mb-3">
        {orders.map(order => isMobile ? (
          <OrderCardMobileComponent key={order.id} order={order} updateOrders={getOrders} />
        ) : (
          <OrderCardComponent key={order.id} order={order} updateOrders={getOrders} />
        ))}
      </div>
    </div>
  ))

  const onBack = () => navigate('/')

  useEffect(() => {
    setContentHeaderConfig({content: <HeaderWrapper>{t("user.orders_history")}</HeaderWrapper>})
    return () => setContentHeaderConfig({hidden: false})
  }, [])

  useEffect(() => {
    getOrders()
  }, [params])

  return (
    <div className="orders-list">
      <OrdersTypeSwitchComponent
        delivery={params.delivery}
        setDelivery={(delivery) => setParams({...params, delivery})}
      />
      {dateGroups && dateGroups.length ? (
        <div className="d-flex flex-column">{dateGroups}</div>
      ) : (
        <div className="orders-list-empty">
          <div className="font-16 color-gray-400">{t("order.empty_list")}</div>
          <button className="btn btn-black font-15 weight-500" onClick={onBack}>{t("order.go_to_catalog")}</button>
        </div>
      )}
    </div>
  )
}
