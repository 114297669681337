import {BreadcrumbItemType, BreadcrumbSeparatorType} from "antd/es/breadcrumb/Breadcrumb"
import successIcon from "assets/icons/success.svg"
import CounterComponent from "components/counter"
import {ProductDetail} from "core/models/product"
import basketService from "core/services/basket.service"
import productService from "core/services/products.service"
import {localeString} from "core/utils/localeString"
import {toPrice} from "core/utils/price"
import {ApplicationContext} from "global-context"
import {useContext, useEffect, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router"
import {useParams} from "react-router-dom"
import {BreadcrumbComponent} from "../../components/breadcrumb"
import {useGlobalModalContext} from "../../components/modal/modal-context"
import {useGlobalNotificationContext} from "../../components/notification/notification-context"
import {AddedToFav} from "../../components/sets-modal/added-to-fav"
import {AddedToSet} from "../../components/sets-modal/added-to-set"
import authService from "../../core/services/auth.service"
import {ProductImages} from "./product-images"
import {ProductRelatedList} from "./product-related"
import {ImageField} from "core/utils/image"
import {AuthGuestModal} from "pages/auth/modals/guess"
import {Coin} from "components/coin"
import "./product.scss"

export function ProductPage() {
  const {t} = useTranslation()
  const {productSlug, slug} = useParams<{slug: string, productSlug: string}>()
  const [product, setProduct] = useState<ProductDetail>()
  const [count, setCount] = useState<number>(0)
  const {authenticated, basket, isMobile, getBasket, getCategory, favorites} = useContext(ApplicationContext)

  const {showModal} = useGlobalModalContext()
  const {showNotification} = useGlobalNotificationContext()
  const navigate = useNavigate()
  const uuid: string | null = localStorage.getItem("user_bag_id")

  const bagItem = useMemo(() => {
    if (!product) return undefined
    return basket.children.find(b => {
      const isGroup = product.group_id === b.group_id && b.group_id !== null
      const isSet = product.set_id === b.set_id && b.set_id !== null
      const isService = product.service_id === b.service_id && b.service_id !== null
      return isGroup || isSet || isService
    })
  }, [basket, product])

  const inFavorites = useMemo(() => {
    if (!product) return false
    return favorites.length && favorites.some((f) => f.id === product.id)
  }, [favorites, product])

  const onIncrement = () => {
    setCount((prev) => {
      basketService
        .patchProduct(uuid, bagItem.id, {count: prev + 1})
        .then(getBasket)
      return prev + 1
    })
  }

  const onDecrement = () => {
    setCount((prev) => {
      if (prev === 0) return prev
      if (prev === 1) {
        basketService.deleteProduct(uuid, bagItem.id).then(getBasket)
        return prev - 1
      }
      basketService
        .patchProduct(uuid, bagItem.id, {count: prev - 1})
        .then(getBasket)

      return prev - 1
    })
  }

  useEffect(() => {
    productService.getProduct(productSlug).then(setProduct)
    return () => {
      setProduct(undefined)
      setCount(0)
      
    }
  }, [productSlug])

  const addToBasket = () => {
    const payload = {
      count: count === 0 ? count + 1 : count,
      set: product.type === 'set' ? product.set_id : null,
      group: product.type === 'product' ? product.group_id : null,
      service: product.type === 'service' ? product.service_id : null
    }
    basketService.postProduct(uuid, payload).then(getBasket)
    if (count === 0) setCount((prev) => prev + 1)
  }

  const onAddFavorites = async () => {
    if (!authenticated) {
      showModal({
        component: <AuthGuestModal text="profile.guest.product" />,
        className: isMobile && "modal-bottom",
        centered: true,
      })
      return
    }
    const payload = {
      id: product.id,
      type: product.type
    }
    await authService.addToFavorites(payload)

    if (!inFavorites) {
      showNotification({
        component: (
          <AddedToFav
            group={product}
            onSetAdd={(set) => showNotification({component: <AddedToSet set={set} product={product} />})}
          />
        ),
      })
    }
  }

  const copyToProductUrl = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      showNotification({
        component: (
          <div className="d-flex shadow-md px-2 py-1 gap-3 rounded-3 align-items-center">
            <ImageField width={60} height={60} src={successIcon} alt="success icon" fallback="background" />
            <span className="weight-600 font-15">Ссылка скопирована</span>
          </div>
        ),
      })
    })
  }

  const breadcrumbConfig: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = product && [
    {
      title: "Главная",
      onClick: () => navigate("/")
    },
    {
      onClick: () => navigate(`/${slug}`),
      title: t(localeString(getCategory(product.category).extra, "name")),
    },
    {
      onClick: () => navigate(`/${slug}/${productSlug}`),
      title: t(localeString(product.extra, "name")),
    },
  ]

  return product && (
    <div className="animation-opacity">
      <BreadcrumbComponent items={breadcrumbConfig} header={t(localeString(product.extra, "name"))} />

      {!isMobile && <div className="d-flex gap-4 mt-3">
        <div onClick={onAddFavorites} className="d-flex pointer gap-2 color-primary align-items-center">
          <span className={`yume yume-like font-22 ${inFavorites ? 'color-primary' : 'color-gray-300'}`}></span>
          <span className={`weight-500 ${inFavorites ? 'color-primary' : 'color-gray-400'}`}>{t("product.favorite_title")}</span>
        </div>
        <div onClick={copyToProductUrl} className="d-flex gap-2 pointer color-primary align-items-center">
          <span className="yume yume-upload font-24"></span>
          <span className="weight-500">{t("product.share")}</span>
        </div>
      </div>}

      {!isMobile && <hr className="mb-5 mt-3 color-divider" />}

      <div className="product">

        <ProductImages product={product} />

        <div className="product-info">
          <div className="d-flex flex-column">

            <div className="d-flex justify-content-start align-items-baseline mp-2">

              <span className="product-price">{toPrice(product.price_discount)}</span>

              {product.discount && (
                <span className="line-througth font-15 color-red weight-500">
                  {toPrice(product.price)}
                </span>
              )}

            </div>

            <div className="d-flex gap-2">
              {product.type === 'set' && <div className="badge kit">Комплект</div>}
              {product.discount && <div className="badge discount">-{product.discount_amount}%</div>}
              {product.bonus > 0 && <Coin bonus={product.bonus} />}
            </div>

            <div className="d-flex gap-2 mt-3 w-100 justify-content-between">
              <CounterComponent
                onDecrement={onDecrement}
                onIncrement={onIncrement}
                count={count}
                max={product.amount}
                onAdd={addToBasket}
                content={<>
                  <span className="yume yume-plus font-16" />
                  {t("product.add_card")}
                </>}
              />
              {isMobile && <div className="d-flex gap-2 align-items-center justify-content-end">
                <button onClick={copyToProductUrl} className="btn p-2 yume yume-upload font-24"></button>
                <button onClick={onAddFavorites} className={`btn p-2 yume  yume-like font-24 ${inFavorites ? 'btn-color-primary' : 'btn-color-gray-200'}`}></button>
              </div>}
            </div>
          </div>

          <hr className="d-none d-lg-block my-4 color-divider" />

          {localeString(product.extra, "description") && (
            <div
              className="description"
              dangerouslySetInnerHTML={{__html: t(localeString(product.extra, "description"))}}
            ></div>
          )}
        </div>
      </div>

      <ProductRelatedList slug={productSlug} />
    </div >
  )
}
