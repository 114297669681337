import productService from "core/services/products.service"
import {useContext, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {localeString} from "core/utils/localeString"
import {ApplicationContext} from "global-context"
import {CategoryProducts} from "core/models/category"
import {ProductList} from "./product-list"
import {useNavigate} from "react-router"
import "./styles.scss"

export function CategoryProductList() {
  const [index, setIndex] = useState(0)
  const [list, setList] = useState<CategoryProducts[]>([])
  const {isMobile} = useContext(ApplicationContext)
  const {t} = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    productService.categoryProductList().then(setList)
    return () => setList([])
  }, [])

  const categoriesView: JSX.Element[] = list.map((category, key) => (
    <div key={key}>
      {!isMobile && (
        <div className="category-item">
          <img
            src={category.icon}
            alt={t(localeString(category.extra, "name"))}
            className="icon"
          />
          <span className="category">{t(localeString(category.extra, "name"))}</span>
        </div>
      )}
      {!(isMobile && index !== key) && <ProductList products={category.groups} />}
    </div>
  ))

  const mobileCategories: JSX.Element[] = list.map((category, key) => (
    <div
      className={`category-item ${index === key ? "selected" : ""}`}
      key={key}
      onClick={() => setIndex(key)}
    >
      <img
        src={category.icon}
        alt={t(localeString(category.extra, "name"))}
        className="icon"
      />
      <span className="category">{t(localeString(category.extra, "name"))}</span>
    </div>
  ))

  return (
    <>
      <div className="font-20 weight-700 d-flex gap-2 align-items-center mb-4">
        <span className="yume yume-left font-24" onClick={() => navigate(-1)} />
        {t('profile.tech_list')}
      </div>

      {isMobile && <div className="category-holder">{mobileCategories}</div>}

      {categoriesView}
    </>
  )
}
