export const grant = {
  dev: "бөлімді жақсартудамыз, жақында оралады",
  name: "Аты-жөнінңізді енгізіңіз",
  name_placeholder: "Аты-жөнінңіз",
  phone: "Телефон нөмірін енгізіңіз",
  phone_placeholder: "+ 7 (777) 999 99 99",
  instagram: "Instagram парақшаңызға сілтеме",
  instagram_placeholder: "https://www.instagram.com/yumerental",
  portfolio: "Портфолиоға сілтеме",
  portfolio_placeholder: "https://vimeo.com",
  description: "Жобаның сипаттамасы",
  description_placeholder: "Сіз не түсіруді жоспарлап отырсыз?",
  file: "Тритментпен файлды жүктеңіз",
  file_placeholder: "Файлды жүктеу",
  date: "Түсірілім күнін белгілеңіз",
  date_placeholder: "2023-01-01",
  comment: "Пікір",
  comment_placeholder: "",
  policy: "Мен құпиялылық саясатымен келісемін және жеке деректерімді өңдеуге келісім беремін",
  submit: "Грантқа қатысу",
  url_error: "Жарамды сілтеме енгізіңіз",
  date_error: "Дұрыс күнді енгізіңіз",
  success_message: "Сіздің өтінішіңіз қабылданды",
  error_header: "Формада қателер табылды",
  error_message: "Қате формаларды дұрыстаңыз",
  header: {
    slogan: "Грант ұтып алыңыз<br>шығармашылық жобаға",
    period: "Өтінімдерді қабылдау - ай сайын <span class='weight-500'>20-сы мен 22-сі</span> арасында",
    btn: "Шарттармен танысу",
  },
  goals: {
    header: "Жоба мақсаты",
    text: `Ай сайын <b class="color-primary">20-сы мен 22-сі</b>  арасында yume.rent шығармашылық жобаларды іске асыру үшін <b class="color-primary">300 мыңға дейін</b> техникаға грант алғысы келетіндердің барлығына мүмкіндік береді.`,
  },
  steps: {
    header: "Грантты қалай алуға болады?",
    list: [
      {
        header: "1-қадам",
        text: "Жеке кабинет құру",
      },
      {
        header: "2-қадам",
        text: "Қажетті техниканы брондау",
      },
      {
        header: "3-қадам",
        text: "Анкетаны толтыру",
      },
    ],
  },
  requirements: {
    header: "Грант алу шарттары",
    list: [
      "Тек шығармашылық жобалар үшін",
      "Blue – 200 000 ₸ • Silver – 300 000 ₸ • Gold – 400 000 ₸",
      "Жобаның әлеуетін бағалау",
      "18 жастан бастап",
    ],
  },
  submit_btn: "Грантқа өтініш беру",
  winners: {
    header: "Алдыңғы жеңімпаздар",
    month: "Қазан 2023  «{{date}} жеңімпазы»",
    detail_btn: "Жеңімпаз туралы",
    idea_header: "Жоба идеясы",
    basket_header: "Техника тізімі",
    add_to_card: "Барлығын себетке қосыңыз",
    success_add: "Тауарлар сәтті қосылды",
    to_basket: "Себетке өту",
    entity: "{{count}} дана.",
    file_btn: "Жұмысты қарау",
  },
  about: {
    header: "Грант туралы",
    text: `Ай сайын <b class="color-primary">20-сы мен 22-сі</b>  арасында yume.rent шығармашылық жобаларды іске асыру үшін <b class="color-primary">300 мыңға дейін</b> техникаға грант алғысы келетіндердің барлығына мүмкіндік береді.`,
    error: `Грант беру ай сайын қол жетімді <b class='color-primary white-space'>20-дан 22-ге дейін</b>`,
    basket: "Грантқа өтініш бергіңіз келетін элементтерді таңдап, жіберу себетіне өтіңіз"
  },
  modal: {
    success: "Өтінім жіберілді",
    success_message: `Нәтижелер <span class="weight-500 color-primary">27-ші</span> күні сайттың басты бетіндегі <span class="weight-500 color-primary">«Грант» қойындысында</span> қолжетімді болады`
  },
  form: {
    first_name: 'Аты',
    last_name: 'Тегі',
    phone: "Телефон нөмірі",
    treatment: "Тритментті жүктеу",
    instagram: {
      label: 'Instagram сілтемесі',
      placeholder: "https://"
    },
    portfolio: {
      label: 'Портфолиоға сілтеме',
      placeholder: "https://"
    },
    description: {
      label: 'Жоба сипаттамасы',
      placeholder: "Комментарий..."
    },
    comment: {
      label: 'Мотивациялық хат',
      placeholder: "Комментарий..."
    },
    submit: "Өтінім жіберу"
  }
};
