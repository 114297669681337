import LangSwitcher from "components/lang-switcher/lang-switcher"
import {ProfileAddressModal} from "./profile-address-modal"
import {useGlobalModalContext} from "components/modal/modal-context"
import {useNavigate} from "react-router"
import {useTranslation} from "react-i18next"

interface ProfileSection {
  label: string
  icon: string
  onClick: () => void
}
export const ProfileControls = () => {
  const {showModal} = useGlobalModalContext()
  const navigate = useNavigate()
  const {t} = useTranslation()

  const onProducts = () => navigate("/products/all")
  const onFaq = () => navigate("/faqs")
  
  const onAddress = () => {
    showModal({
      component: <ProfileAddressModal />,
      className: "modal-bottom",
      centered: true,
    })
  }

  const sections: ProfileSection[] = [
    {label: t("profile.tech_list"), onClick: onProducts, icon: 'yume-document'},
    {label: 'FAQ`s', onClick: onFaq, icon: 'yume-document'},
    {label: t("profile.contacts"), onClick: onAddress, icon: 'yume-list'},
  ]

  return (
    <>
      <div className="profile-wrapper-card d-flex justify-content-between align-items-center shadow py-2 mb-3">
        <div className="d-flex gap-2 align-items-center">
          <span className="yume yume-globe font-24"></span>
          <span className="weight-500">{t("profile.lang")}</span>
        </div>
        <LangSwitcher />
      </div>

      <div className="profile-wrapper-card d-flex flex-column shadow p-0 mb-3">
        {sections.map((section, index) =>
          <div key={index} onClick={section.onClick} className="d-flex flex-fill justify-content-between align-items-center pointer" style={{padding: 20}}>
            <div className="d-flex pointer gap-2 align-items-center">
              <span className={`yume ${section.icon} font-24`} />
              <span className="weight-500">{section.label}</span>
            </div>
            <span className="yume yume-chevron-right font-14 pointer color-gray-300" />
          </div>
        )}
      </div>
    </>
  )
}
