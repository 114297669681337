import {useNavigate} from "react-router"

export function HeaderWrapper({children, back = -1}: {children: any, back?: string | number}) {
    const navigate = useNavigate()
    const onBack = () => navigate(back as any)

    return (
        <div className="content-header-default">
            <div className="yume yume-left pointer" onClick={onBack}></div>
            <span className="mb-0">{children}</span>
        </div>
    )
}