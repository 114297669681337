export const bonus = {
  level_current: "Менің деңгейім",
  level_passed: "Деңгей өтілді",
  max_level: "Сіз соңғы деңгейге жеттіңіз",
  promo: {
    cashback: `
      <div class="color-white font-14">
        <div class="weight-600 font-15">{{percent}}% кэшбек</div>
        <div>әрбір жалдаудан</div>
      </div>
    `,
    grant: `
      <div class="color-white font-14">
        <div class="weight-600 font-15">{{price}}</div>
        <div>гранттар байқауында</div>
      </div>
    `,
    delivery: `
      <div class="color-white font-14">
        <div class="weight-600 font-15">{{price}} теңгеден бастап жалдау кезінде</div>
        <div>Бір жаққа тегін жеткізу</div>
      </div>
    `,
    event_available: `
      <div class="color-white font-14">
        <div class="weight-600 font-15">Алдын ала қол жеткізу</div>
        <div>біздің іс-шараларға алдын-ала жазылу</div>
      </div>
    `,
    event_reserved: `
      <div class="color-white font-14">
        <div class="weight-600 font-15">Кепілдендірілген орын</div>
        <div>сізге ыңғайлы болу үшін біздің іс-шараларымызда</div>
      </div>
    `,
  },
  faq: {
    label: "Сұрақтарға жауаптар",
    questions: [
      "Y коиндерді қалай жинауға болады?",
      "Y коиндерді қалай жұмсауға болады?",
      "Y коиндер қанша уақыт сақталады?",
      "Кэшбэкті қалай көбейтуге болады?",
    ],
    answers: [
      `
        <span><a href="https://yume.rent/">yume.rent</a></span> сайтында тапсырыс берген сайын сіз автоматты түрде Y коиндер аласыз.
      `,
      `
        Егер сіздің сайтта тіркелген аккаунтыңыз болса, эквайринг арқылы немесе менеджер арқылы төлеу кезінде Y коиндерді пайдалана аласыз. Сіз тапсырысыңыздың құнының <b>30%-ын дейін</b> Y коиндермен төлей аласыз.
      `,
      `
        Егер сіз <b>365 күн</b> ішінде Y коиндермен ешқандай транзакция жасамасаңыз, олар күйіп кетеді.
      `,
      `
        <div class="d-flex flex-column gap-2">
          <div>Біздің адалдық бағдарламамыз аясында, <a href="https://yume.rent/">yume.rent</a> сайтындағы сатып алуларыңыздың көлеміне байланысты кэшбэк мөлшерін ұлғайту мүмкіндігін ұсынамыз. Міне, бұл қалай жұмыс істейді:</div>
          <div><b>“Blue”</b> деңгейі: Әр клиент, жалпы сомасы <b>50 000 тг</b> және одан жоғары сатып алулар жасағанда, <b>“Blue”</b> деңгейін алады, бұл <b>3%</b> тұрақты кэшбэк деген сөз.</div>
          <div><b>“Silver”</b> деңгейіне өту: <b>6%</b> кэшбэк алу үшін сіздің жалпы шығындарыңыз yume.rent сайтында <b>1 000 000 ₸-ден</b> асуы тиіс. Бұл соманы жеткізген сәтте сіз автоматты түрде <b>“Silver”</b> деңгейіне көтерілесіз.</div>
          <div>Ең жоғары <b>“Gold”</b> деңгейі: Ең жоғары деңгейге жету үшін, сіздің жалпы сатып алуларыңыз <b>3 000 000 ₸-ден</b> асып кетуі тиіс. <b>“Gold”</b> деңгейінде сіз <b>9%</b> кэшбэк аласыз.</div>
          <div>Сондай-ақ, сіз <b>YUME Plus</b> жазылымын пайдаланып, <b>“Silver”</b> немесе <b>“Gold”</b> деңгейлеріне тікелей өту мүмкіндігіне ие боласыз.</div>
          <div>Айта кету керек: Келесі деңгейге өту үшін есептелетін жалпы сома тек сайтта карта арқылы жүргізілген төлемдер мен кеңседе қолма-қол ақшамен төленген төлемдерден тұрады. Бонустармен жүргізілген төлемдер есепке алынбайды. Мысалы, егер сіздің тапсырысыңыздың жалпы құны <b>10 000 ₸</b> болса және оның <b>3 000 ₸-сін</b> бонустармен төлесеңіз, онда сіздің жұмсалған сомаңыз тек <b>7 000 ₸-ға</b> артады.</div>
        </div>
      `
    ]
  },
  subscription: {
    btn_text: `<span class="weight-600">YUME Plus</span> жазылымын қосу`,
    label: "YUME Plus жазылымы",
    hint: "Деңгейді арттыру және барлық қосымша бонустар",
    sign: "Жазылуды рәсімдеу",
    durations: ["6 ай", "12 ай"],
    sections: [
      "Әрбір жалдаудан <span class='weight-600'>{{percent}}% кэшбек</span>",
      "<span class='weight-600'>{{price}} дейінгі</span> сомаға гранттарға қатысу мүмкіндігі",
      "<span class='weight-600'>{{price}} теңгеден</span> бастап жалдау кезінде бір жаққа тегін жеткізу",
      "Біздің іс-шараларға алдын-ала жазылу <span class='weight-600'>алдын ала қол жетімді.</span>",
      "Сіздің ыңғайлылығыңыз үшін біздің іс-шараларда <span class='weight-600'>кепілдендірілген орын.</span>"
    ],
    recommended: "Ұсынылады",
    submit: "Рәсімдеуге өту"
  },
  history: {
    label: "Бонустар тарихы"
  }
}