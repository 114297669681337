export const auth = {
  back: "Назад",
  promo_title: "Получи бонус – {{bonus}} ₸",
  promo_decline_btn: "Мне не интересно",
  promo: 'Создайте аккаунт, чтобы получить <span class="color-primary">{{bonus}} ₸</span> и копить <span class="color-primary">{{percent}}% кэшбека</span> с каждого заказа',
  create_account: "Создать аккаунт",
  telegram_signup: "Telegram",
  email_signup: "Эл. почта",
  no_signup: "Продолжить без регистрации",
  has_account: "Уже есть аккаунт?",
  login: "Войти",
  reset: "Восстановить",
  reset_password: "Забыли пароль?",
  login_error: "Пароль не совпадает или вы не зарегистрированы",
  login_header: "Вход в аккаунт",
  login_header_favorite: "Войдите чтобы добавить в избранное",
  login_header_basket: "Создайте личный кабинет, чтобы заказать доставку",
  phone: "Номер телефона",
  password: "Пароль",
  intro: {
    title: "Войдите в аккаунт",
    event_guest: "Для того, чтобы посещать ивенты – войдите или создайте аккаунт",
    basket_guest: "Для того, чтобы создать заявку на аренду – войдите или создайте аккаунт",
    favorite_guess: "Для того, чтобы добавить товар в избранное – войдите или создайте аккаунт"
  },
  phone_form: {
    header: "Введите номер телефона",
    header_reset: "Восстановить пароль",
    request_otp: "Получить код",
    phone_error: "Пользователь с таким номером уже существует",
    nouser_error: "Пользователь с таким номером не существует",
    next: "Далее",
  },
  otp_form: {
    confirm_title: "Подтверждение номера",
    confirm_text: "На номер {{phone}} был отправлен cмс-код. Введите его ниже",
    error: "Неверный код",
    send_again_text: "Отправить код повторно через",
    send_again: "Отправить код повторно",
  },
  signup: {
    title: "Основная информация",
    first_name: "Имя",
    first_name_error: "Введите имя",
    last_name: "Фамилия",
    last_name_error: "Введите фамилию",
    email_error: "Введите эл. почту",
    birth_date: "Дата рождения",
    birth_date_error: "Введите дату рождения",
    gender: {
      none: "Выберите пол",
      male: "Мужской",
      female: "Женский",
    },
    job_title: {
      label: "Выберите вид деятельности",
      placeholder: "Выберите вид деятельности",
      producer: "Продюсер",
      director: "Режиссер",
      operator: "Оператор",
      illuminator: "Осветитель",
      camera_mechanic: "Механик",
      photograph: "Фотограф",
      gaffer: "Гаффер",
      second_director: "Второй режиссер",
      sound_director: "Звукорежиссёр",
      colorist: "Колорист",
      motion_design: "Motion дизайнер",
      editor: "Монтажер",
      vfx: "VFX артист",
      sound_designer: "Sound дизайнер",
      admin: "Администратор",
      flashbang: "Осветитель",
    },
    password: "Введите пароль",
    password_error: "Введите пароль",
    re_password: "Повторите пароль",
    re_password_error: "Пароли не совпадают",
    policy: `Я принимаю <a href="{{privacy}}" className="link text-decoration-underline color-primary" target="_blank">политику конфиденциальности</a> и <a href="{{offerta}}" className="link text-decoration-underline color-primary" target="_blank">публичную оферту</a> сайта`,
    submit: "Создать аккаунт",
  },
  new_pass: "Придумайте пароль",
  save: "Сохранить",
  signup_success_title: "Регистрация прошла успешно",
  signup_success_text: "Поздравляем, вы создали аккаунт, теперь вам доступны все возможности нашего сайта",
  reset_success: "Пароль успешно сброшен"
}
