import {useLocation, useNavigate} from "react-router"
import {ProfileControls} from "./profile-contorls"
import {useContext, useEffect} from "react"
import {ApplicationContext} from "global-context"
import {useTranslation} from "react-i18next"

export const ProfileGuestPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {t} = useTranslation()
  const {authenticated, setContentHeaderConfig, isMobile} = useContext(ApplicationContext)

  const onLogin = () => navigate("/auth/login", {state: location.pathname})
  const onSignup = () => navigate("/auth/request", {state: location.pathname})

  useEffect(() => {
    if (authenticated) navigate("/profile")
  }, [authenticated])

  useEffect(() => {
    if (isMobile) setContentHeaderConfig({hidden: true})
    return () => {
      if (isMobile) setContentHeaderConfig({hidden: false})
    }
  }, [isMobile])

  return (
    <>
      <h1 className="mb-2 font-24 weight-700">{t("profile.guest.title")}</h1>
      <p className="p-0 mb-3 mb-0">{t("profile.guest.subtitle")}</p>

      <div className="d-flex align-items-center gap-2 mb-3">
        <button onClick={onLogin} className="btn btn-primary col p-3 weight-500">
          {t("profile.signin")}
        </button>
        <button onClick={onSignup} className="btn btn-black col p-3 weight-500">
          {t("profile.signup")}
        </button>
      </div>

      <ProfileControls />
    </>
  )
}
