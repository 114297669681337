import {App, ConfigProvider} from "antd"
import ruRU from "antd/locale/ru_RU"
import {PrivateRoute} from "components/private-route"
import "core/services/interceptor"
import i18n from "i18n/i18n"
import {AuthLayout, AuthRegisterLayout, AuthResetLayout} from "layouts/auth"
import {ProductsLayout} from "layouts/products"
import moment from "moment"
import "moment/locale/kk"
import "moment/locale/ru"
import {ArticleList} from "pages/articles/article-list"
import {AuthLogin} from "pages/auth/login"
import {PasswordForm} from "pages/auth/password-form"
import {OtpForm} from "pages/auth/otp-form"
import {PhoneForm} from "pages/auth/phone-form"
import {AuthSignup} from "pages/auth/signup"
import {BasketPage} from "pages/basket"
import GrantFormComponent from "pages/grant/form"
import {GrantWinnerComponent} from "pages/grant/winners"
import {CurrentOrderList} from "pages/product-list/current-orders"
import {NewProductList} from "pages/product-list/new-products"
import {Sections} from "pages/sections"
import {AboutBonusesPage} from "pages/user/bonus"
import {ProfileEditPage} from "pages/user/edit"
import {FavoritePage} from "pages/user/favorite"
import {FavoritesList} from "pages/user/favorite/favorites"
import {SetProductPage} from "pages/user/favorite/set-page"
import {SetsList} from "pages/user/favorite/sets"
import {OrderItemPage} from "pages/user/order-item"
import {OrdersPage} from "pages/user/orders"
import {PaymentPage} from "pages/user/payment/payment"
import {ProfilePage} from "pages/user/profile"
import {createRoot} from "react-dom/client"
import {Navigate, Outlet, RouterProvider, createBrowserRouter} from "react-router-dom"
import {Application} from "./global-context"
import {BasketCheckoutSuccessPage} from "./pages/basket/checkout-success"
import {CategoryList} from "./pages/category-list"
import {CategoryProductList} from "./pages/category-product-list"
import {CommunityPage} from "./pages/community/community"
import {EventsList} from "./pages/events"
import {EventsDetailPage} from "./pages/events/event-detail"
import {EventsVisitPage} from "./pages/events/visit"
import {EventsVisitSuccess} from "./pages/events/visit/success"
import {FaqList} from "./pages/faq/faq"
import {GrantPage} from "./pages/grant"
import {ProductList} from "./pages/product-list"
import {ProductPage} from "./pages/product/product"
import {ProfileGuestPage} from "./pages/user/profile/profile-guest"
import {BasketLayout} from "layouts/basket"
import {EventFormLayout} from "layouts/event"
import {BasketFormComponent} from "pages/basket/basket-form"
import "./index.scss"

moment.locale(i18n.language === "kk" ? "kk" : "ru")

const content = document.getElementById("root")
const root = createRoot(content)

const config = {
  token: {
    colorPrimary: "var(--color-primary)",
    colorPrimaryBg: "transparent",
  },
}

const router = createBrowserRouter([
  {
    path: "",
    element: (
      <Application>
        <Outlet />
      </Application>
    ),
    errorElement: (
      <Application>
        <div className="d-flex align-items-center justify-content-center p-5">
          <h1 className="mb-0 p-5">404 Not Found</h1>
        </div>
      </Application>
    ),
    children: [
      {
        path: "",
        element: <ProductsLayout />,
        children: [
          {
            path: "", element: <>
              <ArticleList />
              <CurrentOrderList />
              <Sections />
              <NewProductList type="bonus" />
              <NewProductList type="new" />
              <NewProductList type="discount" />

              <ProductList />
            </>
          },
          {path: ":slug", element: <ProductList />},
          {path: ":slug/:productSlug/", element: <ProductPage />},
        ]
      },

      {
        path: "auth",
        element: <AuthLayout />,
        children: [
          {
            path: "",
            element: <AuthRegisterLayout />,
            children: [
              {path: "request", element: <PhoneForm />},
              {path: "otp", element: <OtpForm />},
              {path: "password", element: <PasswordForm />},
              {path: "register", element: <AuthSignup />},
            ]
          },
          {
            path: "reset",
            element: <AuthResetLayout />,
            children: [
              {path: "", element: <PhoneForm />},
              {path: "otp", element: <OtpForm />},
              {path: "password", element: <PasswordForm />},
            ]
          },
          {path: "login", element: <AuthLogin />},
        ],
      },

      // TODO
      {
        path: "profile",
        element: <PrivateRoute accessAllowWithOutAuthPages={["/profile/guest"]} />,
        children: [
          {path: "", element: <ProfilePage popover={false} />},
          {path: "guest", element: <ProfileGuestPage />},
          {path: "edit", element: <ProfileEditPage />},
          {path: "orders", element: <OrdersPage />},
          {path: "orders/:orderId", element: <OrderItemPage />},
          {path: "orders/:orderId/payment", element: <PaymentPage />},
          {
            path: "favorites",
            element: <FavoritePage />,
            children: [
              {path: "", element: <FavoritesList />},
              {path: "sets", element: <SetsList />},
            ],
          },
          {path: "favorites/sets/:id", element: <SetProductPage />},
        ],
      },

      {path: "basket", element: <BasketPage />},
      {
        path: "basket/checkout",
        element: <BasketLayout />,
        children: [
          {path: "", element: <BasketFormComponent />},
          {path: "success", element: <BasketCheckoutSuccessPage />},
        ]
      },

      {path: "events", element: <Navigate to="/events/upcomming" />},
      {path: "events/upcomming", element: <EventsList upcomming={true} />},
      {path: "events/previous", element: <EventsList upcomming={false} />},
      {path: "events/:slug", element: <EventsDetailPage />},
      {
        path: "events/:slug/visit",
        element: <EventFormLayout />,
        children: [
          {path: "", element: <EventsVisitPage />},
          {path: "success", element: <EventsVisitSuccess />},
        ]
      },

      {path: "products/all", element: <CategoryProductList />},
      {path: "categories", element: <CategoryList />},
      {path: "faqs", element: <FaqList />},
      {path: "community", element: <CommunityPage />},

      // grants
      {path: "grant", element: <GrantPage />},
      {path: "grant/form", element: <GrantFormComponent />},
      {path: "grant/winner/:id", element: <GrantWinnerComponent />},



      // bonus
      {path: "bonus", element: <AboutBonusesPage />},
    ],
  },
])

root.render(
  <ConfigProvider theme={config} locale={ruRU}>
    <App>
      <RouterProvider router={router} />
    </App>
  </ConfigProvider>,
)
