import { HTMLInputTypeAttribute, useRef, useState } from "react";

export default function DragDropFile(config: {
  content: JSX.Element | string;
  type?: HTMLInputTypeAttribute;
  accept?: string;
  multiple?: boolean;
  on_select: (files: File | FileList) => void;
  on_reset?: () => void;
  disabled?: boolean;
}) {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      config.on_select(e.dataTransfer.files);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      config.on_select(e.target.files);
    }
  };

  return (
    <div id="form-file-upload" onDragEnter={handleDrag}>
      <input
        disabled={config.disabled}
        accept={config.accept}
        ref={inputRef}
        multiple={config.multiple}
        onChange={handleChange}
        autoComplete="off"
        type="file"
        id="input-file-upload"
      />

      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        {config.content}
      </label>

      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </div>
  );
}
