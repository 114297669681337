import "./styles.scss"
import {useState, useEffect, useContext} from "react"
import {useParams} from "react-router"
import {useNavigate} from "react-router-dom"
import {ApplicationContext} from "global-context"
import authService from "core/services/auth.service"
import {PaymentPage} from "pages/user/payment/payment"
import {toPrice} from "core/utils/price"
import {useGlobalModalContext} from "components/modal/modal-context"
import {useGlobalNotificationContext} from "components/notification/notification-context"
import {ORDER_CANCELED, ORDER_COMPLETED, ORDER_DEBTOR, ORDER_INRENT, ORDER_PAYMENT_PAID, ORDER_REQUEST, ORDER_RESERVE} from "core/const"
import {OrderDetailModel} from "core/models/order"
import toDateTime from "core/utils/date-time"
import {statusColorClass, statusName} from "../orders/const"
import {SuccessModal} from "components/modal/modal-success"
import {OrderGroupProduct} from "./list"
import {OrderTimeline} from "./timeline"
import {useTranslation} from "react-i18next"
import {FeedbackPage} from "pages/user/feedback"
import {CancelOrderModal} from "pages/user/feedback/cancel-order"
import {ProductModel, ProductType} from "core/models/product"
import {OrderInfoRow} from "./info-row"
import {HeaderWrapper} from "components/content-header/wrapper"
import basketService from "core/services/basket.service"
import {BasketModel} from "core/models/basket"
import {isNil} from "core/utils/isNil"
import {OrderReservedModal} from "./modal/reserved"
import {NotificationMessage} from "components/notification/notification-message"
import moment from "moment"


const paymentRequire = [ORDER_INRENT, ORDER_RESERVE, ORDER_DEBTOR]

export function OrderItemPage() {
  const {t} = useTranslation()
  const {setContentHeaderConfig, profile, isMobile, getBasket} = useContext(ApplicationContext)
  const {showModal, hideModal} = useGlobalModalContext()
  const {showNotification} = useGlobalNotificationContext()
  const {orderId} = useParams()

  const navigate = useNavigate()
  const [order, setOrder] = useState<OrderDetailModel>()
  const [basket, setBasket] = useState<BasketModel>()
  const [groups, setGroups] = useState<Record<ProductType, ProductModel[]>>()

  const orderAgain = async () => {
    if (!order.bag) return
    localStorage.setItem("user_bag_id", order.bag)
    await basketService.updateBag(order.bag, {start_at: moment(), end_at: moment().add(1, 'day')})
    await getBasket()
    showNotification({
      component: <NotificationMessage
        icon="yume-basket font-24"
        label={t('order.repeat.label')}
        hint={t('order.repeat.hint')}
        onClick={() => navigate('/basket')}
      />
    })
  }

  const leaveFeedback = () => {
    showModal({
      component: <FeedbackPage orderId={order.id} postSubmit={getOrder} />,
      centered: true,
      className: isMobile && "modal-bottom",
      header: t("order.feedback.header"),
      backdrop: "static",
    })
  }

  const getOrder = () => authService.getOrder(orderId).then(setOrder)

  const onOrderCancel = () => {
    getOrder().then(() => {
      showModal({
        component: (
          <SuccessModal
            header={t('order.cancel.modal.header')}
            confirm_btn={t('order.cancel.modal.confirm')}
            onConfirm={() => {
              hideModal()
              navigate("/")
            }}
          />
        ),
        centered: true,
      })
    })
  }

  const togglePayment = () => {
    if (isMobile) {
      hideModal()
      navigate(`/profile/orders/${orderId}/payment`)
    }
    if (!isMobile) showModal({component: <PaymentPage setOrder={setOrder} />, centered: true})
  }

  const cancellingHint = () => {
    showModal({
      component: <div className="text-align-center font-14 my-3 mt-4">{t("order.cancel.restrict")}</div>,
      className: isMobile && "modal-bottom",
      centered: true,
    })
  }

  const toggleCancelling = () => {
    showModal({
      component: <CancelOrderModal orderId={order.id} postSubmit={onOrderCancel} />,
      header: t("order.cancel.commit"),
      className: isMobile && "modal-bottom",
      centered: true,
    })
  }

  useEffect(() => {
    Promise.all([authService.getOrder(orderId), authService.getGroups(orderId)]).then(async ([order, groups]) => {
      setOrder(order)
      setGroups(groups)

      if (order.status === ORDER_REQUEST && order.bag) basketService.getBasket(order.bag).then(setBasket)

      if (paymentRequire.includes(order.status) && order.payment_status !== ORDER_PAYMENT_PAID) {
        showModal({
          component: <OrderReservedModal order={order} onToggle={togglePayment} />,
          className: isMobile && "modal-bottom",
          centered: true,
        })
      }
    })

    return () => {
      setOrder(undefined)
      setGroups(undefined)
    }
  }, [orderId])

  useEffect(() => {
    if (!order) return
    setContentHeaderConfig({content: <HeaderWrapper>{t('order.header', {order_id: order.unique_id ? order.unique_id : `№${order.id}`})}</HeaderWrapper>})
    return () => setContentHeaderConfig({hidden: false})
  }, [order])

  return order && (
    <div className="order-wrapper">
      <div className="order-wrapper-content flex-fill">
        {order.status !== ORDER_CANCELED && (
          <div className="order-wrapper-status d-flex flex-column gap-3">
            <div className="weight-600 font-16">{t("order.status.order.label")}</div>
            <OrderTimeline order={order} />
          </div>
        )}
        <hr className="divider mobile" />

        <div className="order-wrapper-status">
          {order.status === 3 && (
            <div className="order-wrapper-info">
              <div className="order-wrapper-info-title">{t("order.status.order.label")}</div>
              <div className={`order-wrapper-info-value ${statusColorClass[order.status][0]}`}>{statusName[order.status]}</div>
            </div>
          )}

          <div className="order-wrapper-info">
            <div className="order-wrapper-info-title">{t("order.period")}</div>
            <div className="order-wrapper-info-value">{toDateTime(order.rent_start)} - {toDateTime(order.rent_end)}</div>
          </div>

          <div className="order-wrapper-info">
            <div className="order-wrapper-info-title">{t("order.delivery_address")}</div>
            <div className="order-wrapper-info-value">{order.delivery ? order.delivery.extra.delivery_address : "-"}</div>
          </div>
        </div>

        <hr className="divider mobile" />

        <div className="font-18 weight-600 mb-2">{t("order.list_title")}</div>

        <div className="d-flex flex-column w-100">
          {groups.set.map(product => <OrderGroupProduct key={product.id} product={product} showPrice={isNil(basket)} />)}
          {groups.product.map(product => <OrderGroupProduct key={product.id} product={product} showPrice={isNil(basket)} />)}
          {groups.service.map(product => <OrderGroupProduct key={product.id} product={product} showPrice={isNil(basket)} />)}
        </div>
      </div>

      <div className="order-wrapper-content order-wrapper-payment">
        <hr className="divider mobile" />

        <div className="order-wrapper-payment-title">{t("order.payment_title")}</div>

        <OrderInfoRow label={t('order.sum')} value={toPrice(order.price)} />

        {order.discount_amount > 0 &&
          <OrderInfoRow
            label={<>{t("order.discounted_sum")}&nbsp; <span className="color-primary">-{Math.trunc((order.discount_amount / order.price) * 100)}%</span></>}
            value={toPrice(order.price_discount)}
          />
        }

        {order.status !== ORDER_CANCELED && (
          <>
            <OrderInfoRow
              label={order.status === ORDER_COMPLETED ? t("order.bonus_added") : t("order.bonus_will_add")}
              value={<>+&nbsp;{toPrice(order.bonus_amount)}</>}
              valueClass="bonus-count"
            />

            <hr className="color-gray-100" />

            <OrderInfoRow
              label={t("order.payment_status")}
              value={t(`order.status.payment.${order.payment_status}`)}
              valueClass={`payment-status ${order.payment_status === ORDER_RESERVE && "success"}`}
            />

            {+order.paid_amount > 0 && order.payment_status !== ORDER_PAYMENT_PAID && (
              <>
                <OrderInfoRow
                  label={t("order.paid")}
                  value={basket ? toPrice(basket.price_discount) : toPrice(order.paid_amount)}
                  valueClass="payment-status"
                />
                <OrderInfoRow
                  label={t("order.remainder")}
                  value={basket ? toPrice(basket.price_discount) : toPrice(order.price_discount - order.paid_amount)}
                  valueClass="payment-status"
                />
              </>
            )}
          </>
        )}

        {(order.status <= ORDER_INRENT || order.status === ORDER_DEBTOR) && (
          <div className="order-payment-buttons">
            {order.payment_status !== ORDER_PAYMENT_PAID && (
              <button
                className="btn btn-primary weight-500 p-3"
                disabled={[
                  ORDER_REQUEST,
                  ORDER_CANCELED,
                  ORDER_COMPLETED,
                ].includes(order.status)}
                onClick={togglePayment}
              >
                {t("order.payment_btn")}
              </button>
            )}
            <div className="d-flex gap-2 align-items-center">
              <button
                className="btn btn-black flex-fill weight-500 p-3"
                disabled={!order.can_cancel}
                onClick={toggleCancelling}
              >
                {t("order.cancel.submit")}
              </button>
              {!order.can_cancel && (
                <span className="yume yume-question font-21" onClick={cancellingHint}></span>
              )}
            </div>
          </div>
        )}

        {order.status === ORDER_COMPLETED && (
          <div className={`d-flex gap-2 ${isMobile ? "order-again-wrapper shadow" : "mt-3"}`}>
            <button className="btn btn-black nowrap w-100" onClick={orderAgain}>
              <span className="yume yume-refresh font-18 weight-700"></span>
              <span>{t("order.order_again")}</span>
            </button>
            {order.reviews_cnt === 0 && (
              <button className="btn btn-primary nowrap w-100" onClick={leaveFeedback}>
                <span className="yume yume-star font-18 weight-700"></span>
                <span>{t("order.feedback.commit")}</span>
              </button>
            )}
          </div>
        )}

        <div
          className="mt-3 text-align-center color-gray-400"
          dangerouslySetInnerHTML={{__html: t("order.problem")}}
        ></div>
      </div>
    </div>
  )
}
