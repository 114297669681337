import {AuthIntro} from "./intro"
import {AuthNoLogin} from "./no-login"
import {useContext, useEffect, useMemo} from "react"
import {useGlobalModalContext} from "components/modal/modal-context"
import {ApplicationContext} from "global-context"
import {UserWelcomeModal} from "components/sets-modal/user-welcome-modal"
import {useGlobalNotificationContext} from "components/notification/notification-context"
import {useLocation} from "react-router"
import {AuthGuestModal} from "./guess"
import authService from "core/services/auth.service"
import {firstValueFrom} from "rxjs"

export type AuthPageType = "intro" | "login" | "guest"

export interface AuthModalConfig {
  [key: string]: any
}

export interface AuthPageConfig {
  page: AuthPageType
}

export type AuthPage = {
  [key in AuthPageType]: () => JSX.Element
}

export const AuthPages: AuthPage = {
  intro: () => <AuthIntro />,
  login: () => <AuthNoLogin />,
  guest: () => <AuthGuestModal text="profile.guest.product" />,
}

export default function Authorization() {
  const location = useLocation()
  const {authModal, setAuthModal, isMobile} = useContext(ApplicationContext)
  const {showModal} = useGlobalModalContext()
  const {showNotification} = useGlobalNotificationContext()
  const isAuthPage = location.pathname.startsWith("/auth")

  useMemo(() => {
    if (authModal && !isAuthPage) {
      showModal({
        component: AuthPages[authModal.page](),
        centered: true,
        onHide: () => setAuthModal(undefined),
        className: isMobile ? "modal-bottom" : null,
      })
    }
  }, [authModal, isAuthPage])

  useEffect(() => {
    firstValueFrom(authService.profile$).then((profile) => {
      if (profile) showNotification({show: false, component: <UserWelcomeModal firstName={profile.first_name} />})
    })
  }, [])

  return <></>
}
