import {Outlet, useNavigate} from "react-router"
import {FormProvider, useForm} from "react-hook-form"
import {useContext, useEffect} from "react"
import {ApplicationContext} from "global-context"
import {useGlobalModalContext} from "components/modal/modal-context"
import {AuthGuestModal} from "pages/auth/modals/guess"
import {EventsRequest} from "core/models/events"
import {use100vh} from "react-div-100vh"
import dayjs from "dayjs"
import "./styles.scss"

export function EventFormLayout() {
    const form = useForm<EventsRequest>()
    const {profile, isMobile} = useContext(ApplicationContext)
    const {showModal} = useGlobalModalContext()
    const navigate = useNavigate()
    const height = use100vh()

    useEffect(() => {
        if (profile) {
            form.reset({
                phone: profile.phone,
                name: `${profile.first_name} ${profile.last_name}`,
                profession: profile.extra?.job_title || [],
                age: profile.birth_date ? dayjs().diff(dayjs(profile.birth_date), 'years') : undefined
            })
            return
        }
        if (profile === null) {
            showModal({
                centered: true,
                component: <AuthGuestModal text="auth.intro.event_guest" />,
                className: isMobile && "modal-bottom",
                onHide: () => navigate(-1)
            })
        }
    }, [profile])

    return <div className="layout-event-form">
        <div className="layout-event-form-outlet-wrapper" style={{minHeight: height}}>
            <div className="layout-event-form-outlet">
                <FormProvider {...form}>
                    <Outlet context={{form}} />
                </FormProvider>
            </div>
        </div>
    </div>
}