import {BonusPromo} from "./item"
import {useEffect, useState} from "react"
import {LoyaltyModel} from "core/models/user"
import authService from "core/services/auth.service"

export function BonusPromoList({onSubscription}: {onSubscription: () => void}) {
  const [plans, setPlans] = useState<LoyaltyModel[]>([])

  useEffect(() => {
    authService.loyaltyPlans().then(setPlans)
    return () => setPlans([])
  }, [])

  return <div className="bonus-promo">
    {plans.map(plan => <BonusPromo key={plan.id} plan={plan} onSubscription={onSubscription} />)}
  </div>
}
