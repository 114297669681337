import {useTranslation} from "react-i18next"
import {useGlobalModalContext} from "components/modal/modal-context"
import {useNavigate} from "react-router"
import "../styles.scss"

export function AuthIntro() {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {hideModal} = useGlobalModalContext()

  const onRequest = () => {
    navigate("/auth/request")
    hideModal()
  }

  return (
    <div className="d-flex flex-column gap-3">
      <h4 className="text-align-left weight-700 mb-0">{t("auth.promo_title", {bonus: 6000})}</h4>
      <span className="mb-3 font-14" dangerouslySetInnerHTML={{__html: t("auth.promo", {bonus: 6000, percent: 3})}}></span>
      <div className="d-flex flex-column w-100">
        <button className="btn btn-primary btn-color-black font-13 weight-500 mb-2 w-100" onClick={onRequest}>
          {t("auth.create_account")}
        </button>
        <button className="btn py-3 font-13 weight-500 btn-gray-50 btn-color-gray-500 w-100" onClick={hideModal}>
          {t("auth.promo_decline_btn")}
        </button>
      </div>
    </div>
  )
}
