export const validationRules = {
  required: (message?: string) => ({
    value: true,
    message: message ?? "Обязательное поле",
  }),
  emailPattern: (message?: string) => ({
    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
    message: message ?? "Некорректный email",
  }),
};
