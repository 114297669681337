import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react"
import {Outlet, useLocation, useParams} from "react-router"
import {ApplicationContext} from "global-context"
import productService from "core/services/products.service"
import {EMPTY_LIST, ListModel, ListPageParams} from "core/models/common"
import {ProductModel} from "core/models/product"
import {useSearchParams} from "react-router-dom"
import {UseFormReset, useForm} from "react-hook-form"
import "./styles.scss"

export interface ProductContext {
    products: ListModel<ProductModel>
    params: ListPageParams
    reset: UseFormReset<ListPageParams>
    setPos: Dispatch<SetStateAction<{top: number; pathname: string}>>
}

export function ProductsLayout() {
    const {contentRef, isMobile, setContentHeaderConfig} = useContext(ApplicationContext)

    const params = useParams()
    const {slug} = params

    const [searchParams] = useSearchParams()
    const location = useLocation()
    const productSearch = searchParams.get("search")
    const {watch, reset, setValue} = useForm<ListPageParams>({
        defaultValues: {
            page: 1,
            pageSize: 10,
            category__slug: slug
        }
    })

    const [products, setProducts] = useState<ListModel<ProductModel>>(EMPTY_LIST)
    const [pos, setPos] = useState<{top: number; pathname: string}>({top: 0, pathname: undefined})

    const scrollToRef = (ref: any) => {
        if (location.pathname === pos.pathname) {
            ref.current?.scroll({top: pos.top, behavior: "instant"})
            setPos({top: 0, pathname: undefined})
        } else {
            ref.current?.scroll({top: 0, behavior: "smooth"})
        }
    }

    useEffect(() => scrollToRef(contentRef), [location.pathname])

    const listProducts = (params: any) => {
        const firstPage = params.page === 1
        productService.listProducts(params).then((list) => {
            if (firstPage) setProducts(list)
            if (!firstPage) setProducts(({results}) => ({...list, results: [...results, ...list.results]}))
        })
    }

    const values = watch()

    useEffect(() => reset({search: productSearch, page: 1}), [productSearch, reset])
    useEffect(() => reset({category__slug: slug, page: 1}), [slug, reset])

    useEffect(() => {
        listProducts(values)
        const sub = watch((params) => listProducts(params))
        return () => {
            sub.unsubscribe()
            setProducts(EMPTY_LIST)
        }
    }, [])

    useEffect(() => {
        setContentHeaderConfig({hidden: false})
        return () => setContentHeaderConfig({hidden: false})
    }, [slug, isMobile, setContentHeaderConfig])

    return <Outlet context={{
        products,
        reset,
        setValue,
        setPos,
        params: values,
    }} />
}