import {ListSlider} from "components/list-slider"
import {ProductItem} from "components/product-item"
import {ProductModel} from "core/models/product"
import {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import productService from "core/services/products.service"

export function ProductRelatedList(config: {slug: string}) {
  const {t} = useTranslation()
  const [list, setList] = useState<ProductModel[]>([])

  useEffect(() => {
    productService.getRelatedProduct(config.slug).then(setList)
  }, [config.slug])

  return <ListSlider<ProductModel>
    header={t("product.related")}
    list={list}
    render={(product) => <ProductItem product={product} />}
  />
}
