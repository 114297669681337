import './styles.scss'
import {ApplicationContext} from 'global-context'
import {useContext, useEffect} from 'react'
import {SetCardView} from './set-card'
import {useGlobalModalContext} from 'components/modal/modal-context'
import {CreateSet} from 'components/sets-modal/create-set'
import {SetModel} from 'components/sets-modal/model'
import {useNavigate} from 'react-router'
import {HeaderWrapper} from "components/content-header/wrapper"

export function SetsList() {
  const {sets, isMobile, setContentHeaderConfig} = useContext(ApplicationContext)
  const setsView: JSX.Element[] = sets.map(set => <SetCardView key={set.id} set={set} />)
  const navigate = useNavigate()
  const {showModal, hideModal} = useGlobalModalContext()

  useEffect(() => {
    setContentHeaderConfig({content: <HeaderWrapper>Сеты</HeaderWrapper>})
  }, [])

  const onSetAdd = (set: SetModel) => {
    hideModal()
    navigate(`/profile/favorites/sets/${set.id}`)
  }

  const onCreate = () => {
    showModal({
      component: <CreateSet onSetAdd={onSetAdd} />,
      centered: true,
      className: isMobile && 'modal-bottom',
      header: 'Новый сет',
      backdrop: 'static',
    })
  }

  return (
    <div className="d-flex flex-column animation-opacity">
      <div className="set" onClick={onCreate}>
        <div className="set-card-icon">
          <span className="yume yume-plus" />
        </div>
        <span className="set-card-title">Создать сет</span>
      </div>
      {setsView}
    </div>
  )
}
