import {useStore} from "core/store/storeHooks"
import "./styles.scss"

export function Loader() {
  const {loading} = useStore(({app}) => app)
  return loading && (
    <div className="global-loader">
      <div className="spinner-border color-gray-300"></div>
    </div>
  )
}
