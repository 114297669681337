import "./styles.scss"
import {useContext, useEffect} from "react"
import {ApplicationContext} from "global-context"
import {BasketProductList} from "./basket-list"
import {useTranslation} from "react-i18next"
import {useLocation, useNavigate} from "react-router-dom"
import {BasketIntroCard} from "./basket-intro-card"
import {toPrice} from "../../core/utils/price"
import {BasketFreeDeliveryProgress} from "./basket-free-delivery-progress"
import {BasketEmptyCard} from "./basket-empty-card"
import clsx from "clsx"
import {use100vh} from "react-div-100vh"

export interface OrderForm {
  start_at: any
  end_at: any
  delivery: boolean
  delivery_return: boolean
  address: string
  address_return: string
  extra: {
    name: string
    last_name: string
    phone: string
    comment: string
  }
}

export function BasketPage() {
  const {t} = useTranslation()
  const {basket, setContentHeaderConfig, isMobile, setContentHeight} = useContext(ApplicationContext)
  const trueHeight = use100vh()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (isMobile) {
      setContentHeaderConfig({hidden: true})
      setContentHeight(trueHeight - 60)
    }

    return () => {
      setContentHeaderConfig({hidden: false})
      if (isMobile) setContentHeight(trueHeight - 114)
    }
  }, [isMobile, location.pathname, trueHeight])


  const basketProductCount = basket.children.reduce((acc, item) => acc + item.count, 0)
  const onCheckout = () => navigate("/basket/checkout")
  if (basket.children.length === 0) return <BasketEmptyCard />

  return (
    <div className="basket">
      <div className="d-flex justify-content-between align-items-center mb-2">

        <div className="d-flex gap-2 align-items-center">
          <h1 className="weight-700 color-black font-24 mb-0">{t("basket.title")}</h1>
          <span className="weight-400 font-13 color-gray-400 mb-0 align-self-end">
            {t("basket.products", {count: basket.children.length})}
          </span>
        </div>

        <span className="font-13 weight-400">{t("basket.step", {step: 1, steps: 2})}</span>
      </div>

      {isMobile && <BasketFreeDeliveryProgress />}

      {/* {isMobile && <BasketIntroCard />} */}

      <div style={{gap: !isMobile && 64}} className="d-flex flex-grow-1 flex-lg-row flex-column">

        <BasketProductList />

        <div className="basket-footer">
          <div className="basket-footer-content">
            {!isMobile && <BasketFreeDeliveryProgress />}
            {/* {!isMobile && <BasketIntroCard />} */}

            <div className={clsx("basket-footer-btn", {"d-flex flex-fill justify-content-between": isMobile})}>
              <div className="d-flex flex-column">
                <span className="basket-footer-price">
                  {toPrice(basket.price_discount)}&nbsp;&nbsp;
                  {basket.price_discount !== basket.price && <span className="weight-500 text-decoration-line-through color-red">{toPrice(basket.price)}</span>}
                </span>
                <span className="font-13 weight-400 color-gray-300">{t('basket.products', {count: basketProductCount})}</span>
              </div>
              <button onClick={onCheckout} className="btn btn-primary d-flex gap-2 weight-600">
                {t("basket.submit.checkout")}
                <span className="yume yume-right font-18" />
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
