import {Keyboard, Mousewheel, Pagination, Navigation} from "swiper"
import {Swiper, SwiperSlide} from "swiper/react"
import {ApplicationContext} from "global-context"
import {useContext} from "react"
import {NavigationOptions, PaginationOptions} from "swiper/types"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "./styles.scss"

type Props<T> = {
  className?: string
  header?: string
  list: T[]
  render: (product: T) => JSX.Element
  breakpoints?: () => number | "auto",
  pagination?: PaginationOptions | boolean
  navigation?: NavigationOptions | boolean
}

export function ListSlider<T extends {id: number}>({
  className = '',
  header,
  list = [],
  render,
  breakpoints = () => {
    let w = window.innerWidth
    if (w >= 2560) return 8
    if (w >= 1440 && w <= 2560) return 6
    if (w >= 1200 && w <= 1440) return 5
    if (w >= 768 && w <= 1200) return 4
    if (w <= 768 && w >= 500) return 3
    return 2
  },
  pagination = {enabled: true, clickable: true},
  navigation = {enabled: true}
}: Props<T>) {

  const {isMobile} = useContext(ApplicationContext)

  const slides = list.map((product, index) =>
    <SwiperSlide key={`${product.id}_${index}`}>
      {render(product)}
    </SwiperSlide>
  )

  return (
    list.length > 0 && (
      <div className={`list-slider ${className}`}>
        {header && <h5 className="mb-3 weight-700 list-slider-header">{header}</h5>}

        <Swiper
          className="list-slider-slider"
          pagination={pagination}
          spaceBetween={isMobile ? 20 : 24}
          slidesPerView={breakpoints()}
          keyboard={{enabled: false}}
          mousewheel={{forceToAxis: true, sensitivity: 0.7}}
          modules={[Keyboard, Mousewheel, Pagination, Navigation]}
          children={slides}
          navigation={navigation}
        />
      </div>
    )
  )
}
