import {ApplicationContext} from "global-context"
import {BasketChildrentModel} from "core/models/basket"
import {useCallback, useContext, useState} from "react"
import {useTranslation} from "react-i18next"
import basketService from "core/services/basket.service"
import {localeString} from "core/utils/localeString"
import {toPrice} from "core/utils/price"
import {BasketProductDelete} from "./delete"
import "../styles.scss"
import CounterComponent from "components/counter"
import {ImageField} from "core/utils/image"

export function BasketProductChild(config: {child: BasketChildrentModel}) {
  const {t} = useTranslation()
  const {getBasket} = useContext(ApplicationContext)
  const [toDelete, setDelete] = useState(false)
  const product = config.child

  const onDelete = () => setDelete(true)
  const onCancel = useCallback(() => setDelete(false), [])
  const uuid: string | null = localStorage.getItem("user_bag_id")

  const onIncrement = async () => {
    await basketService.patchProduct(uuid, product.id, {count: product.count + 1})
    await getBasket()
  }

  const onDecrement = async () => {
    if (product.count - 1 === 0) {
      onDelete()
      return
    }
    await basketService.patchProduct(uuid, product.id, {count: product.count - 1})
    await getBasket()
  }

  if (toDelete) {
    return <BasketProductDelete product={config.child} onCancel={onCancel} />
  }

  const productInfoView = (
    <div className="info">
      <div className="info-name">{localeString(product.extra, "name")}</div>
      <div className="d-flex gap-2 mt-2">
        <div className="price-detailed width overflow">
          {toPrice(product.price_discount)}
        </div>
        <div
          className="price-detailed color-gray-300 width"
          dangerouslySetInnerHTML={{
            __html: t(product.discount ? "basket.price_discount" : "basket.price", {
              count: product.count,
              price: toPrice(product.price),
              price_discount: toPrice(product.price_discount)
            })
          }}
        />
      </div>
    </div>
  )

  const productDetailView = <CounterComponent
    onDecrement={onDecrement}
    onIncrement={onIncrement}
    count={product.count}
    max={product.amount}
    onAdd={() => { }}
    content={<>
      <span className="yume yume-plus font-16" />
      {t("product.add_card")}
    </>}
  />

  return (
    <div className="basket-product">
      <ImageField
        key={config.child.id}
        className="image"
        src={product.image}
        fallback="background"
      />

      <div className="detail">
        {productInfoView}
        {productDetailView}
      </div>
    </div>
  )
}
