import {useEffect} from "react"
import {createPortal} from "react-dom"
import {useCountdown} from "core/utils/countdown"
import {NotificationConfig, useGlobalNotificationContext} from "./notification-context"
import "./notification-style.scss"

export const NotificationContainer = ({config, bottom}: {config: NotificationConfig, bottom: number}) => {
  const {hideNotification} = useGlobalNotificationContext()
  const {time} = useCountdown(config.time || 5)

  useEffect(() => {
    if (time === 0) hideNotification()
  }, [time])

  return createPortal(
    <div className={`portal-alert shadow ${!config.show ? 'd-none' : ''}`} style={{bottom: bottom + 8}}>{config.component}</div>,
    document.body
  )
}
