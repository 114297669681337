import {useGlobalModalContext} from "components/modal/modal-context"
import {ApplicationContext} from "global-context"
import {GrantInfoModal} from "pages/grant/modal/grant-date"
import {useContext} from "react"
import {useTranslation} from "react-i18next"

export const BasketFormGrantBanner = () => {
  const {t} = useTranslation()
  const {profile, isMobile} = useContext(ApplicationContext)
  const {showModal} = useGlobalModalContext()

  const onGrant = () => showModal({component: <GrantInfoModal />, className: isMobile && "modal-bottom", centered: true})

  return profile && profile.grant_accessible && !profile.grant_sent && (
    <div onClick={onGrant} className="banner mb-3">
      <h1 className="mb-0 color-white text-uppercase font-18 weight-700">{t("basket.grant.title")}</h1>
      <h1 className="mb-0 text-uppercase font-18 weight-700 color-primary">{t("basket.grant.subtitle")}</h1>
      <div className="color-white" dangerouslySetInnerHTML={{__html: t("basket.grant.footer")}}></div>
    </div>
  )
}
