export const profile = {
  download_smeta: "сметаны жүктеу",
  edit_title: "Аккаунтты өңдеу",
  phone_label: "Телефон нөмеріңіз",
  date_label: "Туған күніңіз",
  name_label: "Атыңыз",
  lastname_label: "Жөніңіз",
  job_title_label: "Қызмет түрін таңдаңыз",
  password: "Құпия сөз",
  password_change: "Құпия сөзді ауыстыру",
  submit: "Өзгерістерді сақтау",
  signin: "Кіру",
  signup: "Тіркелу",
  lang: "Тіл",
  tech_list: "Жабдықтар тізімі",
  contacts: "Байланыс және мекен-жай",
  title: "Профиль",
  address_placeholder: "Мекен-жай",
  address: `Алматы қ., Қазақстан, <span class="color-primary">Желтоқсан к-сі, 168а</span>`,
  contact_placeholder: "Байланысу",
  our_location: "Біздің кеңсе картада",
  call: "Қоңырау шалу",
  work_time: "07:00-ден 02:00-ге дейін",
  welcome: "Қош келдіңіз, {{firstName}}!",
  welcome_description: "Керемет түсірілім тілейміз :)",
  guest: {
    title: "Аккаунтқа кіріңіз немесе тіркеліңіз",
    subtitle: `Сатып алу, тапсырыстарды қадағалау және жеке жеңілдіктер мен ұпайларды пайдалану.`,
  },
  password_form: {
    title: "Аккаунт құпия сөзін өзгерту",
    old: "Ескі құпия сөз",
    new: "Жаңа құпия сөз",
    repeat: "Жаңа құпия сөзді қайталаңыз",
    submit: "Жаңа құпия сөзді сақтау",
    success: "Құпия сөз сәтті өзгертілді",
    error_password: "Жарамсыз ескі құпия сөз",
    error_server: "Қате орын алды"
  },
  success_edit: "Деректер сәтті өзгертілді",
}
