export const faq = {
  header: "Сұрақтар мен жауаптар",
  list: [
    [
      `Жеке кабинетті қалай құруға болады?`,
      `“Тіркелу” түймесін басу арқылы сіз yume.rent веб-сайтында ақпаратты толтыру және тіркелгіні телефон нөміріңізге байланыстыру арқылы жеке тіркелгі жасай аласыз.`,
    ],
    [
      "Адалдық бағдарламасына қалай мүше болуға болады?",
      "Біздің веб-сайтта жеке тіркелген  жасаған кезде сіз автоматты түрде біздің адалдық бағдарламамыздың мүшесі боласыз",
    ],
  ],
};
