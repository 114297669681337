import axios from "axios"
import {GrantWinner} from "core/models/grant"

class GrantsService {
  public async post(payload: any): Promise<any> {
    const res = await axios.post("/v1/shared/auth/profile/grants/", payload)
    return res.data
  }
  public async listWinners(): Promise<GrantWinner[]> {
    const res = await axios.get("/v1/shared/auth/profile/grants/winners/")
    return res.data
  }
  public async getWinner(id: number): Promise<GrantWinner> {
    const res = await axios.get(`/v1/shared/auth/profile/grants/winners/${id}/`)
    return res.data
  }
}

const grantsService = new GrantsService()
export default grantsService
