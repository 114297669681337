import {useContext} from 'react'
import {ApplicationContext} from 'global-context'
import {SetCardView} from 'pages/user/favorite/set-card'
import {useGlobalModalContext} from 'components/modal/modal-context'
import {CreateSet} from './create-set'
import {SetModel} from './model'
import {ProductModel} from 'core/models/product'
import authService from 'core/services/auth.service'

export function ChooseSet(config: {group: ProductModel; onSetAdd: (set: SetModel) => void}) {
  const {isMobile, sets} = useContext(ApplicationContext)
  const {showModal, hideModal} = useGlobalModalContext()

  const onCreate = () => {
    showModal({
      component: <CreateSet group={config.group} onSetAdd={config.onSetAdd} />,
      centered: true,
      className: isMobile && 'modal-bottom',
      header: 'Новый сет',
      backdrop: 'static',
    })
  }

  const addToSet = async (set: SetModel) => {
    const payload = {
      type: config.group.type,
      id: config.group.group_id || config.group.set_id || config.group.service_id
    }
    await authService.onSetItem(set.id, payload)
    hideModal()
    config.onSetAdd(set)
  }

  const setsView: JSX.Element[] = sets.map(set => <SetCardView key={set.id} set={set} onClick={set => addToSet(set)} />)

  return (
    <>
      <div className="set w-100" onClick={onCreate}>
        <div className="set-card-icon">
          <span className="yume yume-plus"></span>
        </div>
        <span className="set-card-title">Создать сет</span>
      </div>

      <div className="d-flex flex-column">{setsView}</div>
    </>
  )
}
