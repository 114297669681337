import {FocusEventHandler} from "react"
import {UseFormRegisterReturn} from "react-hook-form"

export const FormControlTextarea = (config: {
  label?: string
  placeholder?: string
  params: UseFormRegisterReturn<any>
  className?: string
  valid?: boolean
  required?: boolean
  disabled?: boolean
  resizable?: boolean
  rows: number
  onBlurCapture?: FocusEventHandler<HTMLTextAreaElement>
}) => {
  return <>
    {config.label && (
      <div className="d-flex gap-2 mb-1">
        <label className="overflow font-13 color-gray-400">{config.label}</label>
        {config.required && <div className="form-required"></div>}
      </div>
    )}
    <textarea
      rows={config.rows}
      disabled={config.disabled}
      onBlurCapture={config.onBlurCapture}
      className={`form-control ${config.className ? config.className : "mb-1"} ${config.resizable ? 'form-rezisable' : ''} ${!config.valid && config.valid !== undefined ? "invalid" : ""}`}
      placeholder={config.placeholder} {...config.params} />
  </>
}
