export const ORDER_REQUEST = 0
export const ORDER_RESERVE = 1
export const ORDER_INRENT = 2
export const ORDER_CANCELED = 3
export const ORDER_COMPLETED = 4
export const ORDER_DEBTOR = 5

export const ORDER_PAYMENT_PENDING = 0
export const ORDER_PAYMENT_PAID = 1
export const ORDER_PAYMENT_PARTLY_PAID = 2
