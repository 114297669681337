import {useContext, useEffect, useMemo, useRef, useState} from "react"
import {Link, useLocation, useNavigate} from "react-router-dom"
import {ApplicationContext} from "global-context"
import {Popover} from "antd"
import {ProfilePage} from "pages/user/profile"
import {toPrice} from "core/utils/price"
import {useTranslation} from "react-i18next"
import "./styles.scss"
import {ImageField} from "core/utils/image"
import {Coin} from "components/coin"

export function ProfileHeader() {
  const {t} = useTranslation()
  const {profile, basket, favorites} = useContext(ApplicationContext)
  const {authenticated} = useContext(ApplicationContext)
  const popoverRef = useRef()
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const openRegisterPage = () => navigate("/auth/request", {state: location.pathname})
  const openLoginPage = () => navigate("/auth/login", {state: location.pathname})

  useEffect(() => setOpen(false), [location.pathname])

  const basketItemsCount = useMemo(
    () => basket.children.reduce((acc, item) => acc + item.count, 0),
    [basket.children],
  )

  const profileInfo = (
    <Popover
      ref={popoverRef}
      content={<ProfilePage popover={true} />}
      overlayInnerStyle={{backgroundColor: "var(--color-white)"}}
      placement="bottomLeft"
      trigger="click"
      onOpenChange={() => setOpen(!open)}
      open={open}
    >
      <div className="d-flex flex-column align-items-center pointer">
        {authenticated && profile && <ImageField className="header-profile-avatar" fallback="avatar" src={profile.avatar} />}
        <span>{t("profile.title")}</span>
      </div>
    </Popover>
  )

  const loginForm = (
    <div className="d-flex gap-2">
      <button
        style={{padding: "12px 24px", height: 41}}
        className="btn btn-primary btn-color-black font-14 weight-500"
        onClick={openLoginPage}
      >
        {t("auth.login")}
      </button>
      <button
        style={{padding: "12px 24px", height: 41}}
        className="btn btn-black btn-color-white font-14 weight-500"
        onClick={openRegisterPage}
      >
        {t("auth.create_account")}
      </button>
    </div>
  )
  
  const onBonus = () => navigate("/bonus")

  const onFavorite = () => {
    if (authenticated) {
      navigate("/profile/favorites")
    } else {
      openLoginPage()
    }
  }


  return (
    <div className="header-profile">
      {profile && (
        <div className="header-profile-link" onClick={onBonus}>
          <Coin bonus={profile.bonus || 0} />
          <span>Y coins</span>
        </div>
      )}
      <Link to="/basket" className="header-profile-link">
        <div className="icon">
          <span className="yume yume-basket font-24"></span>
          {basket.children && <span className="icon-badge big animate-jump">{basketItemsCount}</span>}
        </div>
        <span>{t("basket_screen")}</span>
      </Link>

      <div className="header-profile-link" onClick={onFavorite}>
        <div className="icon">
          <span className="yume yume-like font-24"></span>
          {favorites.length > 0 && <span className="icon-badge small"></span>}
        </div>
        <span>{t("favorites")}</span>
      </div>

      {authenticated ? profileInfo : loginForm}
    </div>
  )
}
