import {useContext} from "react"
import {useTranslation} from "react-i18next"
import {localeString} from "core/utils/localeString"
import {toPrice} from "core/utils/price"
import {useNavigate} from "react-router"
import {ApplicationContext} from "global-context"
import {ProductModel} from "core/models/product"
import {Coin} from "components/coin"
import "./styles.scss"

export function ProductItem(config: {product: ProductModel}) {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {getCategory} = useContext(ApplicationContext)
  const product = config.product

  return (
    <div
      className="category-product"
      onClick={() => navigate(`/${getCategory(product.category).slug}/${product.slug}`)}
    >
      <div className="info-holder">
        <div className="d-flex flex-fill">
          <div className="info">
            <div className="weight-700 mb-2">{t(localeString(product.extra, "name"))}</div>
            <div className="d-flex gap-2 align-items-center">
              <div className="info-price">{toPrice(product.price_discount)}</div>
              {product.discount && <div className="info-price discount">{toPrice(product.price)}</div>}
            </div>
          </div>
        </div>

        <div className="d-flex gap-1 align-items-center">
          {product.type === 'set' && <div className="category-product__badge kit">{t("product.set")}</div>}
          {product.discount && <div className="category-product__badge discount">{product.discount_amount}%</div>}
          {product.bonus > 0 && <Coin bonus={product.bonus} />}
          <span className="yume yume-chevron-right font-14 color-gray-300" />
        </div>
      </div>
    </div>
  )
}
