import {useContext} from "react"
import {Link, useNavigate} from "react-router-dom"
import authService from "core/services/auth.service"
import basketService from "core/services/basket.service"
import {ApplicationContext} from "global-context"
import {useGlobalModalContext} from 'components/modal/modal-context'
import {useGlobalNotificationContext} from 'components/notification/notification-context'
import toDateTime from "core/utils/date-time"
import "./styles.scss"
import {toPrice} from "core/utils/price"
import {OrderModel} from "core/models/order"
import {useTranslation} from "react-i18next"
import {statusColorClass, statusName} from "../const"
import {ORDER_COMPLETED} from "core/const"
import {FeedbackPage} from 'pages/user/feedback'
import {localeString} from "core/utils/localeString"
import moment from "moment"
import {NotificationMessage} from "components/notification/notification-message"

export function OrderCardMobileComponent({order, updateOrders}: {order: OrderModel; updateOrders: () => void}) {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {isMobile, getBasket} = useContext(ApplicationContext)
  const {showModal} = useGlobalModalContext()
  const {showNotification} = useGlobalNotificationContext()

  const orderAgain = async (event) => {
    event.preventDefault()
    if (!order.bag) return
    localStorage.setItem("user_bag_id", order.bag)
    await basketService.updateBag(order.bag, {start_at: moment(), end_at: moment().add(1, 'day')})
    await getBasket()
    showNotification({
      component: <NotificationMessage
        icon="yume-basket font-24"
        label={t('order.repeat.label')}
        hint={t('order.repeat.hint')}
        onClick={() => navigate('/basket')}
      />
    })
  }

  const leaveFeedback = (event) => {
    event.preventDefault()

    showModal({
      component: <FeedbackPage orderId={order.id} postSubmit={updateOrders} />,
      centered: true,
      className: isMobile && 'modal-bottom',
      header: t("order.feedback.header"),
      backdrop: 'static',
    })
  }

  return (
    <Link className="order-card shadow" key={order.id} to={`/profile/orders/${order.id}`}>
      <div className="order-card-link">
        <div className="d-flex justify-content-between align-items-top gap-2 w-100">
          <div className={`order-card-status nowrap ${statusColorClass[order.status][1]}`}>{statusName[order.status]}</div>
          <div className="order-card-info-time">
            <span>{toDateTime(order.rent_start)} - {toDateTime(order.rent_end)}</span>
            <span className="yume yume-chevron-right font-12"></span>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center gap-2 w-100">
          <div>
            <div className="order-card-info-id mb-2">{order.unique_id || `№${order.id}`}</div>
            <div className="overflow two">{order.products.map((product) => localeString(product)).join(", ")}</div>
          </div>
          <div className="order-card-price white-space">{toPrice(+order.price_discount)}</div>
        </div>
      </div>
      {order.status === ORDER_COMPLETED && (
        <div className="d-flex gap-2 w-100">
          {order.bag && <button className="btn btn-black col nowrap" onClick={orderAgain}>
            <span className="yume yume-refresh font-18"></span>
            <span>{t("order.order_again")}</span>
          </button>}
          {order.reviews_cnt === 0 && (
            <button className="btn btn-primary col nowrap" onClick={leaveFeedback}>
              <span className="yume yume-star font-18"></span>
              <span>{t("order.feedback.commit")}</span>
            </button>
          )}
        </div>
      )}
    </Link>
  )
}
