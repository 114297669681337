import {footer} from "./footer.locale"
import {basket} from "./basket.locale"
import {auth} from "./auth.locale"
import {product} from "./product.locale"
import {grant} from "./grant.locale"
import {user} from "./user.locale"
import {order} from "./order.locale"
import {faq} from "./faq.locale"
import {eventsLocale} from "./events.locale"
import {profile} from "./profile.locale"
import {bonus} from "./bonus.locale"

export const i18n_ru = {
  translation: {
    _locale: "",
    auth,
    basket,
    product,
    footer,
    grant,
    user,
    order,
    faq,
    profile,
    bonus,
    events: eventsLocale,

    main_screen: "Главная",
    categories_screen: "Каталог",
    basket_screen: "Корзина",
    favorites: "Избранное",
    request_call: "Позвонить",

    main: {
      payment: "Оплата",
      community: "Комьюнити",
      podbor: "Подбор",
      new_products: "Новинки",
      bonus_products: "Специальное предложение",
      discount_products: "Скидки на этой неделе",
      all_products: "Все товары",
    },

    community: {
      body: `<h5><strong>yumerental - больше чем просто аренда видеооборудования!</strong></h5>
        <div>&nbsp;</div>
        <p>Мы про комьюнити и культуру взаимопомощи.</p>
        <div>Здесь мы:</div>
        <div>&bull; поддерживаем обмен полезной информацией и нетворкинг;</div>
        <div>&bull; создаем совместные проекты;</div>
        <div>&bull; разбираем актуальные темы из сферы;</div>
        <div>&bull; предоставляем возможность развиваться посредством мастер-классов с лидерами рынка.</div>
        <div>&bull; приветствуем каждого клиента, как друга.</div>
        <div>&nbsp;</div>
        <div><strong>yumerental - инструменты для творчества и созидания.</strong></div>
      `,
      btn: "Хочу быть частью",
    },
    in_process: "В процессе",
    show_all: "Смотреть все",
  },
}
