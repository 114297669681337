import axios from "axios"
import tokenService from "./token.service"
import {store} from "core/store/store"
import {setLoading} from "core/store/app"

axios.defaults.baseURL = "https://rent.api.yume.cloud"

axios.interceptors.request.use(
  (config) => {
    store.dispatch(setLoading(true))
    const token = tokenService.getAccess
    if (token) config.headers["Authorization"] = `Bearer ${token}`
    return config
  },
  (e) => Promise.reject(e)
)

axios.interceptors.response.use(
  (res) => {
    store.dispatch(setLoading(false))
    return res
  },
  async (err) => {
    store.dispatch(setLoading(false))
    const originalConfig = err.config
    const urlRefresh = originalConfig.url === "/v1/shared/auth/refresh/"
    const urlLogin = originalConfig.url === "/v1/shared/auth/"
    const notAuth = err.response.status === 401
    const hasToken = !!tokenService.getRefresh
    
    if (!urlRefresh && !urlLogin && notAuth && hasToken && err.response) {
      try {
        if (!tokenService.getRefresh) {
          tokenService.removeToken()
          window.location.href = "/"
          return axios(originalConfig)
        }

        const rs = await axios.post("/v1/shared/auth/refresh/", {
          refresh: tokenService.getRefresh,
        })
        const {access} = rs.data
        tokenService.updateAccess(access)

        return axios(originalConfig)
      } catch (_error) {
        tokenService.removeToken()
        window.location.href = "/"
        return Promise.reject(_error)
      }
    }

    return Promise.reject(err)
  }
)

export default axios
