import {useLocation, Outlet, useNavigate} from "react-router-dom"
import {useContext, useEffect} from "react"
import {ApplicationContext} from "global-context"
import {use100vh} from "react-div-100vh"
import {SwitcherComponent, SwitcherConfig} from "components/switch"
import {BreadcrumbComponent} from "components/breadcrumb"
import "./styles.scss"

export function FavoritePage() {
  const location = useLocation()
  const {setContentHeight, setContentHeaderConfig, isMobile} = useContext(ApplicationContext)
  const trueHeight = use100vh()
  const navigate = useNavigate()

  useEffect(() => {
    if (isMobile) {
      setContentHeaderConfig({hidden: true})
      setContentHeight(trueHeight - 60)
    }

    return () => {
      setContentHeaderConfig({hidden: false})
      if (isMobile) setContentHeight(trueHeight - 114)
    }
  }, [isMobile, location.pathname, trueHeight])

  const switcherConfig: SwitcherConfig[] = [
    {key: '/profile/favorites', text: "Избранное", onClick: () => navigate('/profile/favorites'), className: "px-3 py-2"},
    {key: '/profile/favorites/sets', text: "Сеты", onClick: () => navigate('/profile/favorites/sets'), className: "px-3 py-2"},
  ]

  return (
    <div className="favorite-wrapper">
      <BreadcrumbComponent items={[]} header="Избранное" />
      <SwitcherComponent className="bg-light mb-4" active={location.pathname} items={switcherConfig} />
      <Outlet />
    </div>
  )
}
