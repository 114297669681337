import {useGlobalModalContext} from "../../components/modal/modal-context"
import {useContext, useMemo} from "react"
import {ApplicationContext} from "global-context"
import useQueryParams from "core/utils/useQueryParams"
import {useOutletContext} from "react-router"
import {ProductContext} from "layouts/products"
import {useTranslation} from "react-i18next"

const sortingOptions = [
  {value: "all", text: "product.filter.empty"},
  {value: "price", text: "product.filter.price_asc"},
  {value: "-price", text: "product.filter.price_desc"},
]

function ProductListFilterModal({onToggle}: {onToggle: (val: string) => void}) {
  const {searchParams} = useQueryParams()
  const {t} = useTranslation()
  const active = searchParams['ordering']

  return <div className="d-flex flex-column">
    <div className="weight-700 font-20 mb-3">{t('product.filter.sort')}</div>
    <div className="d-flex flex-column gap-2 pointer">
      {sortingOptions.map((item) => (
        <div key={item.value} onClick={() => onToggle(item.value)} className="bg-gray-8 rounded-2 p-3 d-flex justify-content-between">
          {t(item.text)}

          {active === item.value && (
            <div className="checked-icon">
              <span className="yume yume-check color-white font-16" />
            </div>
          )}
        </div>
      ))}
    </div>
  </div>
}

export const ProductListFilter = () => {
  const {showModal} = useGlobalModalContext()
  const {isMobile} = useContext(ApplicationContext)
  const {searchParams, setSearchParams} = useQueryParams()
  const {hideModal} = useGlobalModalContext()
  const {params, reset} = useOutletContext<ProductContext>()
  const active = searchParams['ordering']
  const {t} = useTranslation()

  const sortText = useMemo(() => {
    const ordering = searchParams['ordering']
    if (!ordering) return "product.filter.empty"
    const option = sortingOptions.find(s => s.value === ordering)
    if (!option) return "product.filter.empty"
    return option.text
  }, [searchParams])

  const onToggle = (val: string) => {
    reset({...params, ordering: val})
    setSearchParams({ordering: val})
    hideModal()
  }

  const onModal = () => {
    showModal({
      component: <ProductListFilterModal onToggle={onToggle} />,
      className: "modal-bottom",
    })
  }

  return isMobile ? (
    <div className="d-flex mb-4 justify-content-between">
      <div onClick={onModal} className="d-flex align-items-center gap-2 pointer">
        <span className="yume yume-filter font-24"></span>
        <span className="">{t(sortText)}</span>
      </div>
    </div>
  ) : (
    <div className="d-flex gap-4 mb-4">
      {sortingOptions.map(option => (
        <span
          key={option.value}
          onClick={() => onToggle(option.value)}
          className={`pointer ${active === option.value ? 'color-black' : 'color-gray-400'}`}>
          {t(option.text)}
        </span>
      ))}
    </div>
  )
}
