import {ApplicationContext} from "global-context"
import {useContext} from "react"
import {useTranslation} from "react-i18next"

export const BasketFormDetailsAttention = () => {
  const {profile} = useContext(ApplicationContext)
  const {t} = useTranslation()

  return profile && !profile.verified && (
    <div className="attention mb-3">
      <span className="attention-icon">i</span>
      <span>{t("basket.attention_text")}</span>
    </div>
  )
}
