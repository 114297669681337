import {useContext, useEffect, useState} from 'react'
import {BasketChildrentModel, BasketModel} from 'core/models/basket'
import basketService from 'core/services/basket.service'
import {useTranslation} from 'react-i18next'
import {ApplicationContext} from 'global-context'
import {useGlobalModalContext} from 'components/modal/modal-context'
import {SuccessModal} from 'components/modal/modal-success'
import {useNavigate} from 'react-router'
import {localeString} from 'core/utils/localeString'
import {toPrice} from 'core/utils/price'
import {ImageField} from "core/utils/image"
import './styles.scss'

function GrantWinnerBasketItemComponent({product}: {product: BasketChildrentModel}) {
  const {getCategory} = useContext(ApplicationContext)
  const {t} = useTranslation()

  return (
    <div className="grant-wrapper-basket-product">
      <div className="grant-wrapper-basket-product-image">
        <ImageField src={product.image} alt={product.slug} fallback="background" />
      </div>
      <div className="d-flex flex-column gap-1 flex-fill">
        <div className="font-16 weight-600 overflow">{localeString(product.extra, 'name')}</div>
        <div className="font-12 color-gray-400 white-space">{localeString(getCategory(product.category).extra, 'name')}</div>
      </div>
      <div className="d-flex flex-column align-items-end gap-1">
        <div className="font-16 weight-600 white-space">{toPrice(product.price_discount)}</div>
        <div className="font-12 color-gray-400 white-space">{t('grant.winners.entity', {count: product.count})}</div>
      </div>
    </div>
  )
}

export function GrantWinnerBasketComponent({basketId}: {basketId: string}) {
  const {t} = useTranslation()
  const {getBasket, isMobile} = useContext(ApplicationContext)
  const {showModal, hideModal} = useGlobalModalContext()
  const [basket, setBasket] = useState<BasketModel>()
  const navigate = useNavigate()
  const uuid = localStorage.getItem('user_bag_id')

  const addToBasket = () => {
    const promises = basket.children.map(product => {
      const payload = {
        count: product.count,
        group: product.type === 'product' ? product.group_id : null,
        set: product.type === 'set' ? product.set_id : null,
        service: product.type === 'service' ? product.service_id : null,
      }
      return new Promise(r => r(basketService.postProduct(uuid, payload)))
    })

    Promise.all(promises).then(() => {
      getBasket().then(() => {
        showModal({
          component: (
            <SuccessModal
              header={t('grant.winners.success_add')}
              confirm_btn={t('grant.winners.to_basket')}
              onConfirm={() => {
                navigate('/basket')
                hideModal()
              }}
            />
          ),
          centered: true,
        })
      })
    })
  }

  useEffect(() => {
    basketService.getBasket(basketId).then(setBasket)
    return () => setBasket(undefined)
  }, [basketId])

  const children = basket && basket.children.map(child => <GrantWinnerBasketItemComponent product={child} key={child.id} />)

  return basket && (
    <div className="grant-wrapper-basket">
      <h4 className="grant-wrapper-basket-header mb-0">{t('grant.winners.basket_header')}</h4>

      <div className="d-flex flex-column">{children}</div>

      <div className="d-flex justify-content-end">
        <button
          className="btn btn-primary gap-2 font-14 p-3 weight-500"
          onClick={addToBasket}
          style={{width: isMobile ? '100%' : 'max-content'}}
        >
          <span className="yume yume-basket font-18"></span>
          <span>{t('grant.winners.add_to_card')}</span>
        </button>
      </div>
    </div>
  )
}
