export const order = {
  header: "Текущий заказ {{order_id}}",
  repeat: {
    label: 'Добавлено в корзину',
    hint: 'Нажмите, чтобы перейти и оформить заказ'
  },
  status: {
    order: {
      label: "Статус заказа",
      0: {
        label: "На утверждении",
        hint: "Проверяется менеджером"
      },
      1: {
        label: "Забронирован",
        hint: "Собран и готов к аренде"
      },
      2: {
        label: 'В аренде',
        hint: "Техника выдана в аренду"
      },
      3: {
        label: 'Отменeн',
        hint: "Аренда отменена"
      },
      4: {
        label: 'Аренда завершена',
        hint: "Менеджер принял технику"
      },
      5: {
        label: "Ожидается оплата",
        hint: "Ожидается оплата"
      }
    },
    payment: {
      0: 'Ожидается оплата',
      1: 'Оплата произведена',
      2: 'Частичная оплата'
    }
  },
  reserved: {
    label: "Заказ забронирован!",
    hint: "Ознакомьтесь с заказом и перейдите к оплате"
  },

  cancel: {
    commit: "Отмена заказа",
    submit: "Отменить заказ",
    restrict: "Заказ нельзя отменить менее чем за 3 часа до бронирования и если заказ полностью оплачен",
    reason: "Причина отмены заказа",
    reason_list: [
      'Отменились съемки',
      'Техника занята в нужное для меня время',
      'Нужной техники не было в наличии'
    ],
    modal: {
      header: "Ваш заказ отменён",
      confirm: "Вернуться на главную"
    }
  },
  feedback: {
    header: "Оцените заказ",
    commit: "Оставить отзыв",
    liked: "Что вам понравилось?",
    improvement: "Что можно улучшить?",
    disliked: "Что вам не понравилось?",
    comment: "Комментарий",
    submit: "Отправить",
    list: ["Сервис", "Каталог техники", "Исправность техники", "Сайт", "Доставка"],
    success: {
      label: "Спасибо за отзыв!",
      hint: "Ваша обратная связь делает нас лучше"
    }
  },

  // old
  period: "Период аренды",
  delivery_address: "Адрес доставки",
  all_text: "Все заказы",
  delivery_text: "Доставка",
  takeaway_text: "Самовывоз",
  empty_list: "У вас пока нет заказов",
  empty_favorite_list: "У вас пока нет избранных товаров",
  empty_set_list: "Сет пуст",
  go_to_catalog: "Перейти в каталог",
  list_title: "Список техники",
  other: "Другое ...",
  amount: "шт.",
  set: "комплект",
  set_content: "В комплекте",
  payment_title: "Информация об оплате",
  sum: "Сумма",
  discounted_sum: "Сумма со скидкой",
  bonus_added: "Бонусов начислено:",
  bonus_will_add: "Начислится бонусов:",
  paid: "Оплачено",
  remainder: "Остаток",
  bonus_hint: "Вы можете оплатить 30% заказа бонусами",
  spend_bonus: "Потратить",
  payment_total: "К оплате",
  pay_card: "Оплатить картой",
  pay_bonus: "Оплатить бонусами",
  pay_bill: "Счёт на оплату",
  pay_kaspi: "Оплатить через Kaspi",
  kaspi: {
    title: "Оплата через Kaspi",
    text_1: "1. Скопируйте сумму заказа",
    text_2: "2. Нажмите на кнопку «Перейти в Kaspi» и перейдите в приложение Kaspi.kz",
    text_3: "3. Вставьте скопированную сумму и оплатите заказ",
    copy: "Скопировать",
    proceed: "Перейти в Kaspi",
  },
  payment_status: "Статус оплаты",
  payment_btn: "Перейти к оплате",
  close_modal: "К заказу",
  approve_title: "Подтвердите действие",
  on_receive_text: "Вы точно хотите оплатить заказ при получении?",
  on_receive_confirm_btn: "Да, оплатить при получении",
  deny_btn: "Назад",
  payment_modal_title: "Оплата",
  bill_modal_title: "Введите данные",
  order_again: "Повторить заказ",
  problem: `Проблема с заказом? Позвоните по номеру <a href="tel:8(705)-333-69-36" class="nowrap">8 705 333 69 36</a>`,
}
