export function groupOrderByDate<T>(list: T[], keyGetter: (item: T) => Date, fieldA: keyof T, fieldB: keyof T): Map<string, T[]> {
  const map = new Map<string, T[]>()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key.toISOString())
    if (!collection) {
      map.set(key.toISOString(), [item])
    } else {
      collection.push(item)
    }
  })
  const sortedList = [...map].sort((a, b) => new Date(b[0]).valueOf() - new Date(a[0]).valueOf())
  sortedList.forEach((item) => {
    item[1] = item[1].sort((a: any, b: any) => new Date(a[fieldA]).valueOf() - new Date(b[fieldB]).valueOf())
  })
  return new Map(sortedList)
}
