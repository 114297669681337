import {useTranslation} from 'react-i18next'
import {GrantWinner} from 'core/models/grant'
import toDateTime from 'core/utils/date-time'
import {useEffect, useState} from 'react'
import grantsService from 'core/services/grants.service'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Keyboard, Mousewheel, Pagination} from 'swiper'
import {Link} from 'react-router-dom'
import {ImageField} from "core/utils/image"
import './styles.scss'

export function GrantWinnerCard(config: {winner: GrantWinner}) {
  const {t} = useTranslation()

  const name = [config.winner.user.first_name, config.winner.user.last_name].join(' ')

  return (
    <div className="grant-winner-card mb-4">
      <ImageField
        className="avatar"
        alt="avatar"
        fallback="avatar"
        src={config.winner.user.avatar}
        style={{objectFit: 'cover'}}
      />
      <div className="d-flex gap-2 flex-column">
        <div className="d-flex gap-2 flex-column">
          <h5 className="color-black font-16 weight-600 mb-0">{name}</h5>
          <span className="color-gray-400 font-14">
            {t('grant.winners.month', {date: toDateTime(config.winner.created_at, 'MMM YYYY')})}
          </span>
        </div>
        <Link to={`/grant/winner/${config.winner.id}`} className="btn btn-black weight-500">
          {t('grant.winners.detail_btn')}
        </Link>
      </div>
    </div>
  )
}

export function GrantWinnersList() {
  const {t} = useTranslation()
  const [winners, setWinners] = useState<GrantWinner[]>([])

  useEffect(() => {
    grantsService.listWinners().then(setWinners)
    return () => {
      setWinners([])
    }
  }, [])

  const slides = winners.map((winner, key) => (
    <SwiperSlide key={`${key}-${winner.created_at}`} className="slider-slide">
      <GrantWinnerCard winner={winner} />
    </SwiperSlide>
  ))

  return (
    winners.length > 0 && (
      <div className="grant-winners">
        <h1 className="grant-winners-header">{t('grant.winners.header')}</h1>
        <Swiper
          className="slider"
          spaceBetween={24}
          slidesPerView="auto"
          centeredSlides={true}
          keyboard={{enabled: true}}
          mousewheel={{forceToAxis: true, sensitivity: 0.7}}
          modules={[Keyboard, Mousewheel, Pagination]}
          pagination={true}
        >
          {slides}
        </Swiper>
      </div>
    )
  )
}
