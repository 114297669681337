import {useNavigate} from "react-router"
import icon_light from "./yume-light.svg"
import icon_dark from "./yume-dark.svg"
import './aside.scss'

export function YumeIcon({type = 'light'}: {type: 'light' | 'dark'}) {
    const navigate = useNavigate()
    return <img
        src={type === 'light' ? icon_light : icon_dark}
        alt="icon"
        className="logo pointer"
        draggable={false}
        onClick={() => navigate("/")}
    />
}
