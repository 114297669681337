import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router-dom"
import "./styles.scss"

export function Sections() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const onEvents = () => navigate("/events")
    const onGrant = () => navigate("/grant")

    return <div className="page-holder">
        <div onClick={onEvents} className="page-item">
            <span className="yume yume-date font-28"></span>
            <div className="page-item-name">{t('events.header')}</div>
        </div>

        <a
            href="https://pay.kaspi.kz/pay/snropnwt"
            target="_blank"
            rel="noopener noreferrer"
            className="page-item color-black"
        >
            <span className="yume yume-bank-card font-28"></span>
            <div className="page-item-name">{t("main.payment")}</div>
        </a>

        <div onClick={onGrant} className="page-item">
            <span className="yume yume-prize font-28"></span>
            <div className="page-item-name">Грант</div>
        </div>
    </div>
}
