import {useTranslation} from "react-i18next"
import {ImageField} from "core/utils/image"
import map from "./map.png"

export default function DeliveryMapModal() {
  const {t} = useTranslation()
  return <div className="text-align-center">
    <ImageField
      src={map}
      alt="delivery zone"
      className="mb-3 w-100 rounded"
      fallback="background"
    />
    <div className="weight-500">{t("basket.delivery_map")}</div>
  </div>
}
