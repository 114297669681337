import {ProductModel} from "core/models/product"
import {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import productService from "core/services/products.service"
import {ListSlider} from "components/list-slider"
import {ProductItem} from "components/product-item"
import "swiper/css/pagination"
import "swiper/css/navigation"

export function NewProductList({type = "new"}: {type: "new" | "discount" | "bonus"}) {
  const {t} = useTranslation()
  const [list, setList] = useState<ProductModel[]>([])

  useEffect(() => {
    if (type === "new") productService.newProductList().then(setList)
    if (type === "discount") productService.listProducts({has_discount: true}).then((response) => setList(response.results))
    if (type === "bonus") productService.listProducts({has_bonus: true}).then((response) => setList(response.results))
  }, [type])

  const header = type === "new" ? t("main.new_products") : (type === "discount" ? t("main.discount_products") : t("main.bonus_products"))

  return (
    list.length > 0 && (
      <>
        <ListSlider<ProductModel>
          header={header}
          list={list}
          render={(product) => <ProductItem product={product} border={true} isNew={type === "new"} />}
        />
        <hr className="divider mobile" />
      </>
    )
  )
}
