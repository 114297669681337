import {useGlobalModalContext} from "components/modal/modal-context"
import DragDropFile from "components/shared/drag-drop/form-drag"
import {FormControlTextarea} from "components/shared/input/form-control-textarea"
import {FormProvider, useForm} from "react-hook-form"
import grantsService from "core/services/grants.service"
import {useNavigate} from "react-router"
import {useContext, useEffect} from "react"
import {ApplicationContext} from "global-context"
import basketService from "core/services/basket.service"
import {FormControlPhone} from "components/shared/input/form-control-mask"
import {FormControl} from "components/shared/input/form-control"
import {GrantSuccessModal} from "../modal/grant-success"
import {useTranslation} from "react-i18next"

interface GrantForm {
  first_name: string
  last_name: string
  full_name: string
  phone: string

  instagram: string
  portfolio: string
  description: string
  file: File
  comment: string
  bag: string
}

export default function GrantFormComponent() {
  const form = useForm<GrantForm>()
  const {register, handleSubmit, watch, setValue, formState, reset} = form
  const {showModal, hideModal} = useGlobalModalContext()
  const {loadBasket, basket, profile} = useContext(ApplicationContext)
  const {t} = useTranslation()
  const navigate = useNavigate()
  const uuid = localStorage.getItem("user_bag_id")

  const file = watch("file")

  const onSubmit = handleSubmit((payback) => {
    const form = new FormData()
    Object.keys(payback).forEach((key) => form.append(key, payback[key]))
    form.append("bag", localStorage.getItem("user_bag_id"))
    grantsService
      .post(form)
      .then(() => {
        basketService
          .updateBag(uuid, {
            comment: basket.extra?.comment ? `${basket.extra.comment}\n ###Грант###` : "###Грант###",
          })
          .then(() => {
            basketService
              .createOrder(uuid)
              .then(() => {
                localStorage.removeItem("user_bag_id")
                loadBasket()
              })
              .then(() => {
                navigate("/")
                showModal({
                  component: <GrantSuccessModal />,
                  centered: true,
                  onHide: hideModal
                })
              })
          })
      })
      .catch(() => {
        showModal({
          component: <div className="text-align-center font-16 weight-600">Произошла ошибка</div>,
          centered: true,
        })
      })
  })

  useEffect(() => {
    if (!profile) return
    reset({
      first_name: profile.first_name,
      last_name: profile.last_name,
      full_name: `${profile.first_name} ${profile.last_name}`,
      phone: profile.phone
    })
  }, [profile])

  return (
    <FormProvider {...form}>
      <FormControl
        name="first_name"
        label={t('grant.form.first_name')}
        rootclassname="mb-2"
        className="form-control"
        disabled={true}
      />
      <FormControl
        name="last_name"
        label={t('grant.form.last_name')}
        rootclassname="mb-2"
        className="form-control"
        disabled={true}
      />
      <FormControlPhone
        name="phone"
        label={t('grant.form.phone')}
        rootclassname="mb-2"
        className="form-control"
        autoFocus={true}
        disabled={true}
      />
      <FormControl
        name="instagram"
        type="url"
        label={t('grant.form.instagram.label')}
        placeholder={t('grant.form.instagram.placeholder')}
        rootclassname="mb-2"
        className="form-control"
        params={{required: true}}
      />
      <FormControl
        name="portfolio"
        type="url"
        label={t('grant.form.portfolio.label')}
        placeholder={t('grant.form.portfolio.placeholder')}
        rootclassname="mb-2"
        className="form-control"
        params={{required: true}}
      />
      <DragDropFile
        content={file ? file.name : t('grant.form.treatment')}
        type="*"
        accept="*"
        multiple={false}
        on_select={(files: FileList) => {
          const file = Object.keys(files).map((key) => files[key])[0]
          setValue("file", file)
        }}
      />

      <FormControlTextarea
        className="mb-2"
        label={t('grant.form.description.label')}
        placeholder={t('grant.form.description.placeholder')}
        params={register("description", {required: true})}
        disabled={false}
        rows={4}
      />

      <FormControlTextarea
        className="mb-2"
        label={t('grant.form.comment.label')}
        placeholder={t('grant.form.comment.placeholder')}
        params={register("comment", {required: true})}
        disabled={false}
        rows={4}
      />
      <button
        onClick={onSubmit}
        className="btn btn-primary btn-color-black font-16 w-100"
        disabled={!(formState.isValid && watch("file")) || profile.grant_sent || !profile.grant_accessible}
      >
        {t('grant.form.submit')}
      </button>
    </FormProvider>
  )
}
