import {CoinIcon} from "components/coin"
import {BonusTransactionModel} from "core/models/user"
import authService from "core/services/auth.service"
import toDateTime from "core/utils/date-time"
import {toPrice} from "core/utils/price"
import {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"

export function BonusHistory() {
  const {t} = useTranslation()
  const [transactions, setTransactions] = useState<BonusTransactionModel[]>([])

  useEffect(() => {
    authService.bonusHistory().then(setTransactions)
    return () => setTransactions([])
  }, [])

  return <div className="bonus-history">
    <div className="font-22 weight-700 mb-3">{t('bonus.history.label')}</div>

    <div className="bonus-card">
      {transactions.map((trans) =>
        <div key={trans.id} className="bonus-card-item d-flex justify-content-between gap-1">
          <span className="font-14">{toDateTime(trans.created_at)}</span>
          <div className="d-flex gap-1 align-items-center">
            <span className="weight-500">{toPrice(trans.amount, "")}</span>
            <CoinIcon />
          </div>
        </div>
      )}
    </div>
  </div>
}
