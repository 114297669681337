import clsx from "clsx"
import {useFormContext} from "react-hook-form"

export default function StarRating({name}: {name: string}) {
  const {watch, setValue} = useFormContext()
  const rating = watch(name)
  return (
    <div className="d-flex gap-1 justify-content-between align-items-center px-4 py-2">
      {[1, 2, 3, 4, 5].map((star) => (
        <span
          key={star}
          className={clsx('yume yume-star font-48', {'color-primary': rating >= star, 'color-gray-100': rating < star})}
          onClick={() => setValue('rating', star)}
        ></span>
      ))}
    </div>
  )
}