export const eventsLocale = {
  header: 'Ивенттер',
  empty: "Ивенттер әзірге таман жоқ :(",
  comming: "Жақында",
  passing: "Өткен",
  about: "Толығырақ",
  visit: "Барғым келеді",
  register_open: "Тіркелі <b>{{date}}</b> ашылады",
  registered: "Сіз тіркелгенсіз",
  show: "Қалай өткенің қарау",
  comming_events: "Болашақ ивенттер",
  remaining_places: "<span class='weight-500'>{{count}} орын</span> қалды",
  cancel: "Тіркелуден бас тарту",

  form: {
    back: "Артқа",
    name: "Аты",
    phone: "Телефон нөмірі",
    age: "Жас",
    profession: "Сіз кімсіз",
    experience: "Бейне индустриясында қанша уақыт болдыңыз?",
    submit: "Өтінім жіберу",
    noaccess: "Тіркеу әлі ашылған жоқ",
  },

  success: {
    back: "Ивенттерге оралу",
    main: "Басты бетке",
    label: "Өтінім сәтті жіберілді!",
  }
}
