import {getNestedObject} from "core/utils/nested-object"
import {InputHTMLAttributes} from 'react'
import {RegisterOptions, useFormContext} from 'react-hook-form'

interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  rootclassname?: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
  icon?: string
  addonright?: JSX.Element
  maxnumber?: number
  allowdecimal?: boolean
}

export function FormControl(config: FormControlConfig) {
  const {register, formState: {errors}} = useFormContext()
  const error = getNestedObject(errors, config.name)
  const className = `${config.className ? config.className : ''} ${Object.keys(error).length !== 0 ? 'invalid' : ''}`

  return (
    <div className={config.rootclassname || ''}>
      {config.label && (
        <div className="d-flex gap-2 mb-1">
          <label className="overflow font-13 color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}

      <div className="input-group">
        {config.icon && <div className={`input-group-icon ${config.icon}`}></div>}
        <input
          {...config}
          {...register(config.name, config.params)}
          className={className}
          autoComplete="off"
        />
        {config.addonright && <div className="input-group-append">{config.addonright}</div>}
      </div>
    </div>
  )
}
