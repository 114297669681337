import {StylesConfig} from 'react-select'

export const colourStyles = (invalid: boolean): StylesConfig => {
  return {
    control: (styles, props) => ({
      ...styles,
      backgroundColor: props.isDisabled ? 'var(--color-gray-100)' : 'var(--color-white)',
      color: props.isDisabled ? 'var(--color-black)' : 'var(--color-black)',
      borderColor: invalid ? 'var(--color-red)' : 'var(--color-gray-200)',
      borderWidth: '1px !important',
      psadding: '0 0',
      fontSize: 14,
      transition: '0.25s all',
      boxShadow: 'none',
      borderRadius: 12,
      cursor: 'pointer',
      opacity: 1,
      minHeight: 50,
      height: props.isMulti ? 'inherit' : 50,
      zIndex: 9,
      '&:focus': {
        borderColor: 'var(--color-gray-200)',
      },
      '&:hover': {
        borderColor: 'var(--color-gray-200)',
      },
    }),
    indicatorSeparator: (styles, props) => ({
      ...styles,
      width: 0,
    }),
    indicatorsContainer: (styles, props) => ({
      ...styles,
      paddingRight: 12,
    }),
    valueContainer: (styles, props) => ({
      ...styles,
      padding: 8,
      paddingLeft: 16,
      rowGap: 6,
      columnGap: 8
    }),
    multiValue: (styles, props) => {
      return {
        ...styles,
        position: 'relative',
        backgroundColor: 'var(--color-gray-100)',
        borderRadius: 7,
        padding: '2px 4px',
        margin: 0
      }
    },
    multiValueLabel: (styles, props) => {
      return {
        ...styles,
        color: 'var(--color-black)',
      }
    },
    multiValueRemove: (styles, props) => ({
      ...styles,
      borderRadius: "50%",
      position: 'absolute',
      padding: 1,
      top: -6,
      right: -6,
      minWidth: 10,
      minHeight: 10,
      backgroundColor: 'var(--color-white)',
      color: 'var(--color-black)',
      boxShadow: '0 1px 4px 0 rgba(35, 57, 66, 0.21)',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'var(--color-red)',
        color: 'var(--color-white)'
      }
    }),
    dropdownIndicator: (base, props) => ({
      ...base,
      padding: 4,
      transition: '0.25s all',
    }),
    singleValue: (styles, props) => ({
      ...styles,
      color: 'var(--color-black)',
      '&::placeholder': {
        color: 'var(--color-gray-300)',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
      },
      zIndex: 9,
    }),
    menu: (styles, props) => ({
      ...styles,
      padding: '0px 4px',
      backgroundColor: 'var(--color-white)',
      boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.16)',
      zIndex: 82,
      borderRadius: 8,
    }),
    menuPortal: (styles, props) => ({
      ...styles,
      padding: '0px 4px',
      backgroundColor: 'var(--color-white)',
      boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.16)',
      zIndex: 99999,
      borderRadius: 8,
      fontSize: 14
    }),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
      const backgroundColor = () => {
        if (isDisabled) return 'var(--color-gray-100)'
        if (isSelected) return 'var(--color-black)'
        if (isFocused) return 'var(--color-gray-100)'
        return 'var(--color-white)'
      }
      const color = () => {
        if (isSelected) return 'var(--color-white)'
        return 'var(--color-black)'
      }
      return {
        ...styles,
        borderRadius: 4,
        padding: '6px 8px',
        marginTop: 4,
        transition: '0.25s all',
        backgroundColor: backgroundColor(),
        color: color(),
        cursor: 'pointer',
        ':first-of-type': {
          marginTop: 0,
        },
      }
    },
    placeholder: (styles, props) => ({
      ...styles,
      color: 'var(--color-gray-400)',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      margin: 0,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    }),
  }
}

export const colors = () => {
  return {
    colors: {
      danger: 'var(--color-red)',
      dangerLight: 'var(--color-red)',
      primary: 'var(--color-black)',
      primary25: 'var(--color-black)',
      primary50: 'var(--color-black)',
      primary75: 'var(--color-black)',
    },
  }
}
