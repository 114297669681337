import i18n from "i18n/i18n"
import {
  ORDER_CANCELED,
  ORDER_COMPLETED,
  ORDER_DEBTOR,
  ORDER_INRENT,
  ORDER_PAYMENT_PAID,
  ORDER_PAYMENT_PARTLY_PAID,
  ORDER_PAYMENT_PENDING,
  ORDER_REQUEST,
  ORDER_RESERVE,
} from "core/const"

const isKK = i18n.language === "kk"

export const statusName = {
  [ORDER_REQUEST]: isKK ? "Тексерілуде" : "На утверждении",
  [ORDER_RESERVE]: isKK ? "Брондалған" : "Забронирован",
  [ORDER_INRENT]: isKK ? "Жалдауда" : "В аренде",
  [ORDER_CANCELED]: isKK ? "Жойылған" : "Отменён",
  [ORDER_COMPLETED]: isKK ? "Жалға алу аяқталды" : "Аренда завершена",
  [ORDER_DEBTOR]: isKK ? "Төлем күтілуде" : "Ожидается оплата",
}

export const paymentStatusName = {
  [ORDER_PAYMENT_PENDING]: isKK ? "Төлем күтілуде" : "Ожидается оплата",
  [ORDER_PAYMENT_PAID]: isKK ? "Төлем жасалды" : "Оплата произведена",
  [ORDER_PAYMENT_PARTLY_PAID]: isKK ? "Жартылай төленген" : "Частичная оплата",
}

export const statusColorClass = {
  [ORDER_REQUEST]: ["color-primary", "color-primary bg-primary-8"],
  [ORDER_RESERVE]: ["color-green", "color-green bg-green-8"],
  [ORDER_INRENT]: ["color-green", "color-green bg-green-8"],
  [ORDER_CANCELED]: ["color-red", "color-red bg-red-8"],
  [ORDER_COMPLETED]: ["color-primary", "color-primary bg-primary-8"],
  [ORDER_DEBTOR]: ["color-red", "color-red bg-red-8"],
}
