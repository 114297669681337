import "./lang-switcher.scss"
import {SwitcherComponent, SwitcherConfig} from "components/switch"
import {useTranslation} from "react-i18next"

export default function LangSwitcher() {
  const {i18n} = useTranslation()

  const switcherConfig: SwitcherConfig[] = [
    {key: 'kk', text: "Қаз", onClick: () => i18n.changeLanguage('kk'), className: "px-3 py-2"},
    {key: 'ru', text: "Рус", onClick: () => i18n.changeLanguage('ru'), className: "px-3 py-2"},
  ]

  return <SwitcherComponent className="bg-light" active={i18n.language} items={switcherConfig} />
}
