import {loyaltyColor} from "components/coin"
import {LoyaltyModel} from "core/models/user"
import {toPrice} from "core/utils/price"
import {useTranslation} from "react-i18next"
import currentIcon from "assets/icons/current.svg"
import lockedIcon from "assets/icons/locked.svg"
import {useContext} from "react"
import {ApplicationContext} from "global-context"

function BonusHeader({plan}: {plan: LoyaltyModel}) {
  const {profile} = useContext(ApplicationContext)

  return <div className="bonus-plan-current">
    <img src={lockedIcon} alt="icon" className="icon" />
    <div className="d-flex flex-column gap-1">
      <div className="font-20 weight-700 color-white" style={{lineHeight: '20px'}}>{plan.name}</div>
      <div className="font-15 color-light-green">{toPrice(profile ? profile.orders_price : 0)} / {toPrice(plan.price)}</div>
    </div>
  </div>
}

function BonusCurrentHeader({name}: {name: string}) {
  const {t} = useTranslation()
  return <div className="bonus-plan-current">
    <img src={currentIcon} alt="icon" className="icon" />
    <div className="d-flex flex-column gap-1">
      <div className="font-20 weight-700 color-white" style={{lineHeight: '20px'}}>{name}</div>
      <div className="font-15 color-light-green">{t('bonus.level_current')}</div>
    </div>
  </div>
}

function BonusPassedHeader({name}: {name: string}) {
  const {t} = useTranslation()
  return <div className="bonus-plan-passed">
    <div className="font-20 weight-700 color-white" style={{lineHeight: '20px'}}>{name}</div>
    <div className="d-flex gap-1 font-15 color-white align-items-center" style={{opacity: .7}}>
      <span>{t('bonus.level_passed')}</span>
      <span className="yume yume-check"></span>
    </div>
  </div>
}

export function BonusPromo({plan, onSubscription}: {plan: LoyaltyModel, onSubscription: () => void}) {
  const {t} = useTranslation()

  return <div className="bonus-card bonus-plan" style={{background: loyaltyColor[plan.percent]}}>
    {plan.current ? (
      <BonusCurrentHeader name={plan.name} />
    ) : (plan.passed ? (
      <BonusPassedHeader name={plan.name} />
    ) : (
      <BonusHeader plan={plan} />
    ))}

    <div className="d-flex gap-2 flex-column">
      <div className="d-flex gap-2">
        <span
          className="bonus-plan-option"
          dangerouslySetInnerHTML={{__html: t("bonus.promo.cashback", {percent: plan.percent})}}
        />
        <span
          className="bonus-plan-option"
          dangerouslySetInnerHTML={{__html: t("bonus.promo.grant", {price: toPrice(plan.grant_limit)})}}
        />
      </div>

      <div className="d-flex gap-2">
        <span
          className="bonus-plan-option"
          dangerouslySetInnerHTML={{__html: t("bonus.promo.delivery", {price: toPrice(plan.delivery_limit)})}}
        />
        {plan.percent === 6 ? <span
          className="bonus-plan-option"
          dangerouslySetInnerHTML={{__html: t("bonus.promo.event_available")}}
        /> : (
          plan.percent === 9 ? <span
            className="bonus-plan-option"
            dangerouslySetInnerHTML={{__html: t("bonus.promo.event_reserved")}}
          /> : (
            !plan.event_limit && <span
              className="bonus-plan-option"
              dangerouslySetInnerHTML={{__html: t("bonus.promo.event_available")}}
            />
          )
        )}

      </div>
    </div>

    <button
      onClick={onSubscription}
      className="btn btn-black weight-500 gap-1"
      dangerouslySetInnerHTML={{__html: t('bonus.subscription.btn_text')}}
    ></button>
  </div>
}
