import {InputHTMLAttributes, SyntheticEvent, useState} from 'react'
import {RegisterOptions, useFormContext} from 'react-hook-form'

interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  id?: any
  rootclassname?: string
  name: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
  icon?: string
  addonright?: JSX.Element
}

export function FormControlPassword(config: FormControlConfig) {
  const {register, formState: {errors}} = useFormContext()
  const error = errors[config.name]
  const [show, setShow] = useState(false)
  const className = `${config.className ? config.className : ''} ${error ? 'invalid' : ''}`

  const togglePassword = (e: SyntheticEvent) => {
    e.stopPropagation()
    setShow(prev => !prev)
  }

  return (
    <div className={config.rootclassname || ''}>
      {config.label && (
        <div className="d-flex gap-2 mb-2">
          <label className="overflow font-13 color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}

      <div className="form-control-group">
        <input {...config} {...register(config.name, config.params)} type={show ? "text" : "password"} className={className} autoComplete="off" />
        <div className={`yume color-gray-400 form-control-group-icon ${show ? 'yume-show font-14' : 'yume-hide font-18'}`} onClick={togglePassword}></div>
      </div>
    </div>
  )
}
