import {FormControlPhone} from "components/shared/input/form-control-mask"
import {FormControlPassword} from "components/shared/input/form-control-password"
import authService from "core/services/auth.service"
import tokenService from "core/services/token.service"
import {replaceAll} from "core/utils/replaceAll"
import {ApplicationContext} from "global-context"
import {useContext, useState} from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useNavigate, useOutletContext} from "react-router"
import {Link} from "react-router-dom"

interface LoginForm {
  username: string
  password: string
}

export function AuthLogin() {
  const {authenticated} = useContext(ApplicationContext)
  const {t} = useTranslation()
  const form = useForm<LoginForm>()
  const {onBack} = useOutletContext<{onBack: () => void}>()
  const {handleSubmit, formState, setError} = form
  const [error, setErr] = useState(false)
  const navigate = useNavigate()

  if (authenticated) onBack()

  const onSubmit = handleSubmit((payload) => {
    const _payload = {...payload, username: replaceAll(payload.username, "-", "")}
    authService
      .login(_payload)
      .then(async (res) => tokenService.setToken(res))
      .catch((err) => {
        setErr(err.response.status === 401)
        setError('username', {message: t("auth.login_error")})
        setError('password', {message: t("auth.login_error")})
      })
  })

  const onRegister = () => navigate(`/auth/request`)

  return (
    <FormProvider {...form}>
      <div className="d-flex flex-column gap-4" onSubmit={onSubmit}>
        <h4 className="mb-0 weight-700">{t(`auth.login_header`)}</h4>
        <div className="d-flex flex-column gap-2">
          <FormControlPhone
            name="username"
            className="form-control"
            autoFocus={true}
            params={{required: true}}
          />
          <FormControlPassword
            name="password"
            placeholder={t("auth.password")}
            className="form-control"
            params={{required: true}}
          />
          {error && <span className="color-red animation-opacity text-align-center font-12">{t("auth.login_error")}</span>}
        </div>
        <div className="d-flex flex-column gap-2">
          <button
            className="btn btn-primary btn-color-black font-14 weight-500"
            onClick={onSubmit}
            disabled={!formState.isValid}
          >
            {t("auth.login")}
          </button>
          <button className="btn btn-black btn-color-white font-14 weight-500" onClick={onRegister}>
            {t("auth.create_account")}
          </button>
        </div>

        <span className="color-gray-400 pointer text-align-center">
          <span className='font-14 weight-500'>{t("auth.reset_password")}</span>&nbsp;
          <Link to="/auth/reset" className="color-primary link text-decoration-underline">{t("auth.reset")}</Link>
        </span>
      </div>
    </FormProvider>
  )
}
