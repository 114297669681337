import {useState} from "react"
import authService from "core/services/auth.service"
import {FormProvider, SubmitHandler, UseFormReturn} from "react-hook-form"
import {replaceAll} from "core/utils/replaceAll"
import {useTranslation} from "react-i18next"
import {useNavigate, useOutletContext} from "react-router"
import {Link} from "react-router-dom"
import {FormControlPhone} from "components/shared/input/form-control-mask"
import "./styles.scss"

export function PhoneForm() {
  const {form, type} = useOutletContext<{form: UseFormReturn<any>, type: "register" | "reset"}>()
  const {t} = useTranslation()
  const {trigger, handleSubmit} = form
  const [error, setError] = useState<string>()
  const navigate = useNavigate()

  const onSubmit: SubmitHandler<{phone: string}> = async (payload) => {
    const isValid = await trigger()
    if (!isValid) return

    const phone = replaceAll(payload.phone, "-", "")

    switch (type) {
      case "register":
        authService
          .signup({phone})
          .then((res) => {
            form.reset({confirm_id: res.confirm_id, phone: phone})
            navigate(`/auth/otp`)
          })
          .catch((error) => {
            const phoneError = error?.response?.data?.phone[0]
            if (phoneError) setError("auth.phone_form.phone_error")
            throw error
          })
        return
      case "reset":
        authService.request_reset_password({phone}).then((res) => {
          form.reset({confirm_id: res.confirm_id, phone: phone})
          navigate(`/auth/reset/otp`)
        })
          .catch((error) => {
            const phoneError = error?.response?.data?.phone[0]
            if (phoneError) setError("auth.phone_form.nouser_error")
            throw error
          })
        return
    }
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="phone-wrapper">
        <h4 className="mb-0 weight-700 align-self-baseline">
          {type === 'register' ? t("auth.phone_form.header") : t("auth.phone_form.header_reset")}
        </h4>

        <FormControlPhone
          rootclassname="w-100"
          name="phone"
          className="form-control"
          autoFocus={true}
          params={{required: true}}
        />

        {error && <div className="phone-error">{t(error)}</div>}

        <button className="btn btn-primary btn-color-black font-14 weight-500 w-100">
          {t("auth.phone_form.request_otp")}
        </button>

        <span className="weight-500 color-gray-400 text-align-center">
          <span>{t("auth.has_account")}</span>&nbsp;
          <Link to="/auth/login" className="color-primary link text-decoration-underline">{t("auth.login")}</Link>
        </span>
      </form>
    </FormProvider>
  )
}
