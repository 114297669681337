import {useState, createContext, useContext} from "react"
import {NotificationContainer} from "./notification-container"

export interface NotificationConfig {
  component: JSX.Element
  onHide?: () => void
  show?: boolean
  time?: number
}

export interface ModalHistoryConfig {
  curr: NotificationConfig
  prev: ModalHistoryConfig
}

export interface NotificationContext {
  showNotification: (props: NotificationConfig) => void
  hideNotification: () => void
}

const globalNotificationContext = createContext<NotificationContext>(undefined)

export const useGlobalNotificationContext = () => useContext(globalNotificationContext)

export const GlobalNotification = ({children, bottom}) => {
  const [config, setConfig] = useState<NotificationConfig>(undefined)

  const showNotification = (_config: NotificationConfig) => {
    setConfig(undefined)
    setTimeout(() => setConfig({..._config, show: true}), 250)
  }

  const hideNotification = () => {
    setConfig((_) => ({...config, show: false}))
    setTimeout(() => setConfig(undefined), 250)
  }

  return (
    <globalNotificationContext.Provider value={{showNotification, hideNotification}}>
      {config && <NotificationContainer config={config} bottom={bottom}/>}
      {children}
    </globalNotificationContext.Provider>
  )
}
