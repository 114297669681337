import {useGlobalModalContext} from "components/modal/modal-context"
import {SuccessModal} from "components/modal/modal-success"
import {useTranslation} from "react-i18next"

export function GrantSuccessModal() {
    const {t} = useTranslation()
    const {hideModal} = useGlobalModalContext()

    return <SuccessModal
        header={t('grant.modal.success')}
        message={<div className="text-align-center" dangerouslySetInnerHTML={{__html: t('grant.modal.success_message')}}></div>}
        confirm_btn="OK"
        onConfirm={hideModal}
    />
}