import {useTranslation} from "react-i18next"
import {useLocation, useNavigate} from "react-router"

export function AuthNoLogin() {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const onLogin = () => navigate("/auth/login", {state: location.pathname})
  const onRegister = () => navigate("/auth/request", {state: location.pathname})

  return (
    <div className="d-flex flex-column gap-2">
      <h4 className="mb-0 weitgh-700">{t("auth.intro.title")}</h4>
      <div className="font-16">{t("auth.intro_subtitle")}</div>

      <button className="btn btn-primary p-3 font-16 weight-500 mt-4 w-100" onClick={onLogin}>
        {t("auth.login")}
      </button>
      <button className="btn p-3 font-16 weight-500 w-100" onClick={onRegister}>
        {t("auth.create_account")}
      </button>
    </div>
  )
}
