import {BehaviorSubject} from "rxjs"
import {TokenModel} from './auth.service'

const storageName = 'auth_214df23fsd'

class TokenService {
  token$: BehaviorSubject<TokenModel> = new BehaviorSubject<TokenModel>(this.getToken)
  
  get hasToken() {
    return !!localStorage.getItem(storageName)
  }

  get getToken(): TokenModel {
    return JSON.parse(localStorage.getItem(storageName))
  }

  get getAccess(): string {
    const auth: TokenModel = JSON.parse(localStorage.getItem(storageName))
    return auth?.access
  }

  get getRefresh(): string {
    const auth: TokenModel = JSON.parse(localStorage.getItem(storageName))
    return auth?.refresh
  }

  updateAccess(token) {
    let auth: TokenModel = JSON.parse(localStorage.getItem(storageName))
    auth.access = token
    this.setToken(auth)
  }

  setToken(auth: TokenModel) {
    this.token$.next(auth)
    localStorage.setItem(storageName, JSON.stringify(auth))
  }

  removeToken() {
    this.token$.next(null)
    localStorage.removeItem(storageName)
  }
}

const tokenService = new TokenService()
export default tokenService
