import axios from "axios"
import {EventModel, EventsRequest} from "../models/events"

class EventsService {
  async getEvents(params: any): Promise<EventModel[]> {
    const response = await axios.get<EventModel[]>(`/v1/shared/events/`, {params})
    return response.data
  }
  async getEvent(slug: string): Promise<EventModel> {
    const response = await axios.get<EventModel>(`/v1/shared/events/${slug}/`)
    return response.data
  }

  async request(data: EventsRequest, slug: string) {
    await axios.post(`/v1/shared/events/${slug}/request/`, data)
  }
  async cancel(slug: string) {
    await axios.delete(`/v1/shared/events/${slug}/cancel/`)
  }
}

export const eventsService = new EventsService()
