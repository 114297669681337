import authService from "core/services/auth.service"
import {useContext, useEffect} from "react"
import {SubmitHandler, UseFormReturn} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useNavigate, useOutletContext} from "react-router"
import {validationRules} from "../../core/utils/validation-rules"
import {genderOptions, jobTitleOptions} from "./data"
import {SignUpForm} from "layouts/auth"
import {FormControl} from "components/shared/input/form-control"
import FormSelectV2 from "components/shared/input/form-select-v2"
import {FormControlDateV2} from "components/shared/input/form-date-v2"
import {FormControlCheckbox} from "components/shared/input/form-control-checkbox"
import {useGlobalModalContext} from "components/modal/modal-context"
import {SuccessModal} from "components/modal/modal-success"
import {ApplicationContext} from "global-context"
import "./styles.scss"

const privacyPolicy = "https://yume-cloud.object.pscloud.io/Yume_rent_%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf"
const offerta = "https://yume-cloud.object.pscloud.io/_%D0%9F%D0%A3%D0%91%D0%9B%D0%98%D0%A7%D0%9D%D0%AB%D0%98%CC%86_%D0%94%D0%9E%D0%93%D0%9E%D0%92%D0%9E%D0%A0_%D0%9E%D0%A4%D0%95%D0%A0%D0%A2%D0%90_%D0%9D%D0%90_%D0%98%D0%A1%D0%9F%D0%9E%D0%9B%D0%AC%D0%97%D0%9E%D0%92%D0%90%D0%9D%D0%98%D0%95.pdf"

export function AuthSignup() {
  const {form} = useOutletContext<{form: UseFormReturn<any>}>()
  const {t} = useTranslation()
  const {showModal} = useGlobalModalContext()
  const {isMobile} = useContext(ApplicationContext)
  const phone = form.watch('phone')
  const session_id = form.watch('session_id')

  const navigate = useNavigate()

  const onSubmit: SubmitHandler<SignUpForm> = async (values) => {
    authService.create(values).then(async () => {
      navigate('/auth/login')
      showModal({
        component: <SuccessModal header={t('auth.signup_success_title')} message={t('auth.signup_success_text')} />,
        centered: true,
        className: isMobile && "modal-bottom"
      })
    }).catch(() => {
      // showModal()
    })
  }

  useEffect(() => {
    if (!phone) {
      form.reset({phone: undefined, session_id: undefined, confirm_id: undefined})
      navigate("/auth/request")
      return
    }
    if (!session_id) {
      form.reset({session_id: undefined})
      navigate("/auth/otp")
      return
    }
  }, [])

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <h4 className="align-self-baseline mb-0 font-700 align-self-center weight-700 font-22 mb-3">
        {t("auth.signup.title")}
      </h4>
      <div className="d-flex flex-column gap-3">
        <FormControl
          name="first_name"
          type="text"
          placeholder={t("auth.signup.first_name")}
          className="form-control"
          params={{required: true}}
        />
        <FormControl
          name="last_name"
          type="text"
          placeholder={t("auth.signup.last_name")}
          className="form-control"
          params={{required: true}}
        />
        <FormControl
          name="email"
          type="text"
          placeholder={t("auth.email_signup")}
          className="form-control"
          params={{
            required: validationRules.required(t("auth.signup.email_error")),
            pattern: validationRules.emailPattern(),
          }}
        />

        <FormControlDateV2
          placeholder={t("auth.signup.birth_date")}
          name="birth_date"
          className="form-control"
          type="date"
        />

        <FormSelectV2
          name="extra.job_title"
          placeholder={t("auth.signup.job_title.placeholder")}
          options={jobTitleOptions}
          getOptionValue={(option: any) => option.value}
          getOptionLabel={(option: any) => t(option.label)}
          params={{required: true}}
          getValue={(ids: string[]) => jobTitleOptions.filter(g => ids.includes(g.value))}
          isSearchable={false}
          isMulti
        />

        <FormSelectV2
          name="extra.gender"
          placeholder={t("auth.signup.gender.none")}
          params={{required: true}}
          options={genderOptions}
          getOptionValue={(option: any) => (option ? option.value : '')}
          getOptionLabel={(option: any) => (option ? t(option.label) : '')}
          getValue={(id: string) => genderOptions.find(g => g.value === id)}
          isSearchable={false}
        />

        <FormControlCheckbox
          name="is_signed"
          label={<span dangerouslySetInnerHTML={{__html: t("auth.signup.policy", {privacy: privacyPolicy, offerta})}}></span>}
          className="form-check-input m-0"
        />

        <button disabled={!(form.formState.isValid && form.watch('is_signed') === true)} className="btn btn-primary weight-500 weight-500 font-14 w-100">
          {t("auth.signup.submit")}
        </button>
      </div>
    </form>
  )
}
