import {useContext, useRef} from "react"
import {useTranslation} from "react-i18next"
import {Link, useParams} from "react-router-dom"
import {ImageField} from "core/utils/image"
import {localeString} from "../../core/utils/localeString"
import {ApplicationContext} from "./../../global-context"
import LangSwitcher from "../lang-switcher/lang-switcher"
import {YumeIcon} from "./icon"
import "./aside.scss"

export function Aside() {
  const {t} = useTranslation()
  const asideRef = useRef(null)
  const {slug} = useParams()
  const {categories} = useContext(ApplicationContext)

  const listItems: JSX.Element[] = categories.map((category, key: number) => (
    <Link key={key} to={category.slug} className={`menu-item animation-opacity ${slug === category.slug ? "active" : ""}`}>
      <ImageField
        className="icon"
        src={category.icon}
        alt={category.slug}
        style={slug === category.slug ? {filter: "brightness(0) saturate(100%) invert(99%) sepia(73%) saturate(7094%) hue-rotate(129deg) brightness(91%) contrast(86%)"} : {}}
        fallback="icon"
      />
      <span className="overflow two">{t(localeString(category.extra, "name"))}</span>
    </Link>
  ))

  return (
    <aside ref={asideRef}>

      <div className="logo-header">
        <YumeIcon type="light" />
      </div>

      <div className="menu">
        {listItems}
        <div className="divider" style={{margin: "40px 0", width: '100%', height: "1px", opacity: 0.2}}></div>
        <LangSwitcher />
      </div>
      
    </aside>
  )
}
