import {useTranslation} from "react-i18next"
import {useContext, useRef} from "react"
import {ApplicationContext} from "global-context"
import {useNavigate} from "react-router"
import {GrantWinnersList} from "./winners/list"
import {useGlobalModalContext} from "components/modal/modal-context"
import {GrantInfoModal} from "./modal/grant-date"
import {GrantBasketModal} from "./modal/grant-basket"
import {GrantSuccessModal} from "./modal/grant-success"
import {GrantErrorModal} from "./modal/grant-error"
import "./styles.scss"

export function GrantPage() {
  const {t} = useTranslation()
  const {isMobile, basket, profile, setAuthModal} = useContext(ApplicationContext)
  const {showModal} = useGlobalModalContext()
  const navigate = useNavigate()
  const ref = useRef(null)

  const scrollTo = () => ref.current.scrollIntoView({block: "start", behavior: "smooth"})

  const onBasket = () => {
    if (!profile) {
      setAuthModal('guest')
      return
    }

    if (!profile.grant_accessible) {
      showModal({
        component: <GrantInfoModal />,
        className: isMobile && "modal-bottom",
        centered: true,
      })
      return
    }

    // if (profile && profile.grant) {
    //   showModal({
    //     component: <GrantSuccessModal />,
    //     className: isMobile && "modal-bottom",
    //     centered: true,
    //   })
    //   return
    // }

    if (profile && profile.grant_sent) {
      showModal({
        component: <GrantErrorModal />,
        className: isMobile && "modal-bottom",
        centered: true,
      })
      return
    }

    if (basket.children.length === 0) {
      navigate("/")
      showModal({
        component: <GrantBasketModal />,
        className: isMobile && "modal-bottom",
        centered: true,
      })
    } else {
      navigate("/basket/checkout")
    }
  }

  return (
    <div className="grant">
      <div className="grant-header">
        <h1 className="grant-header-slogan" dangerouslySetInnerHTML={{__html: t("grant.header.slogan")}}></h1>
        <div className="grant-header-period" dangerouslySetInnerHTML={{__html: t("grant.header.period")}}></div>
        <button onClick={scrollTo} className="btn btn-primary btn-color-black weight-500 gap-2 px-4 py-3 font-16">
          <span>{t("grant.header.btn")}</span>
          <span className="yume yume-down font-12"></span>
        </button>
      </div>

      <div className="grant-goals">
        <h1 className="grant-goals-header">{t("grant.goals.header")}</h1>
        <div className="grant-goals-text" dangerouslySetInnerHTML={{__html: t("grant.goals.text")}}></div>
      </div>

      <div className="grant-steps">
        <h1 className="grant-steps-header">{t("grant.steps.header")}</h1>
        <div className="grant-steps-steps">
          <div className="item">
            <div className="item-header">{t("grant.steps.list.0.header")}</div>
            <div className="item-text">{t("grant.steps.list.0.text")}</div>
          </div>
          <div className="item">
            <div className="item-header">{t("grant.steps.list.1.header")}</div>
            <div className="item-text">{t("grant.steps.list.1.text")}</div>
          </div>
          <div className="item">
            <div className="item-header">{t("grant.steps.list.2.header")}</div>
            <div className="item-text">{t("grant.steps.list.2.text")}</div>
          </div>
        </div>
      </div>

      <div className="grant-requirements" ref={ref}>
        <h1 className="grant-requirements-header">{t("grant.requirements.header")}</h1>
        <div className="grant-requirements-grid">
          <div className="item">
            <span className="item-check">
              <span className="yume yume-check font-12"></span>
            </span>
            <span>{t("grant.requirements.list.0")}</span>
          </div>
          <div className="item">
            <span className="item-check">
              <span className="yume yume-check font-12"></span>
            </span>
            <span>{t("grant.requirements.list.1")}</span>
          </div>
          <div className="item">
            <span className="item-check">
              <span className="yume yume-check font-12"></span>
            </span>
            <span>{t("grant.requirements.list.2")}</span>
          </div>
          <div className="item">
            <span className="item-check">
              <span className="yume yume-check font-12"></span>
            </span>
            <span>{t("grant.requirements.list.3")}</span>
          </div>
        </div>
        <button onClick={onBasket} className="btn btn-primary btn-color-black weight-500 gap-2 px-4 py-3 font-16">
          {t("grant.submit_btn")}
        </button>
      </div>

      <GrantWinnersList />
    </div>
  )
}
