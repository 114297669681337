import {Image} from "antd"
import {ProductModel} from "core/models/product"
import {ImageField} from "core/utils/image"
import {ApplicationContext} from "global-context"
import {useContext, useState} from "react"
import {Mousewheel, Pagination, Swiper as SW, Thumbs} from "swiper"
import {Swiper, SwiperSlide} from "swiper/react"
import "swiper/css/pagination"
import "swiper/css/thumbs"
import "swiper/scss"
import "./styles.scss"

export function ProductImages({product}: {product: ProductModel}) {
  const list = product.images.length > 0 ? product.images : [product.image, ...product.images]

  const {isMobile} = useContext(ApplicationContext)
  const [thumbsSwiper, setThumbsSwiper] = useState<SW | undefined>(undefined)
  const [index, setIndex] = useState(0)

  const images: JSX.Element[] = product && list.map((image: string, key: number) => (
    <SwiperSlide
      key={image + key}
      className={index === key ? "thumb-active" : ""}
      onClick={() => {
        thumbsSwiper && thumbsSwiper.slideTo(key)
        setIndex(key)
      }}
    >
      <ImageField
        className="image-holder"
        style={{borderRadius: 8, objectFit: 'cover'}}
        src={image}
        alt={product.slug}
        fallback="background"
      />
    </SwiperSlide>
  ))

  const images_main: JSX.Element[] = product && list.map((image: string, key: number) => (
    <SwiperSlide key={image + key}>
      <Image
        className="image-holder"
        wrapperStyle={{width: "100%", borderRadius: 8}}
        style={{
          objectFit: "cover",
          height: isMobile ? 340 : "calc((100vw - 955px) * 3 / 4)",
          minHeight: isMobile ? 340 : 400,
          maxHeight: isMobile ? 340 : 640,
          width: "100%",
          minWidth: 320,
          borderRadius: 8,
        }}
        src={image}
      />
    </SwiperSlide>
  ))

  return <div className="product-images">
    <Swiper
      onSwiper={setThumbsSwiper}
      className="main-swiper"
      modules={[Thumbs, Mousewheel, Pagination]}
      pagination={isMobile}
      slidesPerView={1}
      loop={false}
      spaceBetween={0}
      mousewheel={{forceToAxis: true, sensitivity: 0.7}}
      onSlideChange={(s) => setIndex(s.activeIndex)}
      children={images_main}
    />
    {product.images.length > 1 && (
      <Swiper
        className="virtual-swiper"
        modules={[Thumbs]}
        spaceBetween={16}
        slidesPerView="auto"
      >
        {images}
      </Swiper>
    )}
  </div>
}
