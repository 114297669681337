import { useEffect, useState } from "react";

const useCountdown = (seconds: number) => {
  const [time, setTime] = useState(seconds);

  useEffect(() => {
    if (time > 0) {
      const timer = setInterval(() => setTime(time - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [time]);

  return { time, setTime };
};

export { useCountdown };
