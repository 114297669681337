import {useEffect, useState} from "react"
import authService from "core/services/auth.service"
import {useCountdown} from "core/utils/countdown"
import {useTranslation} from "react-i18next"
import {useNavigate, useOutletContext} from "react-router"
import {UseFormReturn} from "react-hook-form"
import OtpInput from "react-otp-input"

const duration = 60

export function OtpForm() {
  const {form, type} = useOutletContext<{form: UseFormReturn<any>, type: "register" | "reset"}>()
  const {t} = useTranslation()
  const phone = form.watch('phone')
  const confirm_id = form.watch('confirm_id')
  const navigate = useNavigate()

  const [otp, setOtp] = useState("")
  const [error, setError] = useState("")

  async function verifyPhone() {
    const verifyData = {confirm_id, code: otp.trim()}

    try {
      const res: any = await authService.verify(verifyData)
      if (res.message) {
        setError(res.message)
      } else {
        form.setValue('session_id', res.session_id)
        navigate(type === "register" ? "/auth/password" : "/auth/reset/password")
      }
    } catch (error) {
      const otpError = error?.response?.data?.message
      if (otpError) setError(t("auth.otp_form.error"))
      throw error
    }
  }

  const {time, setTime} = useCountdown(duration)

  // Resend code
  async function ResendCode() {
    setOtp("")
    const res: any = await authService.signup({phone})
    form.reset({confirm_id: res.confirm_id, phone: phone})
    setTime(duration)
  }

  const formattedTime = time < 10 ? `00:0${time}` : `00:${time}`
  const parsedPhoneNumber = phone && phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 $2 *** **$5")

  useEffect(() => {
    if (!phone || !confirm_id) {
      form.reset({phone: undefined, confirm_id: undefined})
      navigate(type === "register" ? "/auth/request" : "/auth/reset")
    }
  }, [])

  return (
    <div className={`otp-wrapper ${error ? "otp-errored" : ""}`}>
      <h4 className="mb-0 align-self-baseline" style={{fontWeight: 700}}>{t("auth.otp_form.confirm_title")}</h4>

      <div className="sent-text align-self-baseline">
        {t("auth.otp_form.confirm_text", {phone: parsedPhoneNumber})}
      </div>

      <OtpInput
        shouldAutoFocus
        inputType="number"
        value={otp}
        onChange={setOtp}
        numInputs={4}
        containerStyle={{justifyContent: 'space-between', width: '100%', gap: 8}}
        renderInput={(props) => <input
          {...props}
          className="form-control text-align-center font-18 weight-600 w-100"
          style={{borderRadius: 12, height: 80, minWidth: 64, border: '1.5px solid var(--color-gray-200)'}}
        />}
      />

      {error && <div className="otp-error">{error}</div>}
      <div className="d-flex flex-column gap-3 col-12">
        <button
          disabled={otp.length < 4}
          className="btn btn-primary btn-color-black p-3 w-100"
          onClick={verifyPhone}
        >
          {t("auth.phone_form.next")}
        </button>

        {time ? (
          <div className="send-again-counter-text">
            {t("auth.otp_form.send_again")}&nbsp;
            <span className="send-again-counter">{formattedTime}</span>
          </div>
        ) : (
          <div className="send-again-counter-text" onClick={ResendCode}>
            {t("auth.otp_form.send_again")}
          </div>
        )}
      </div>
    </div>
  )
}
