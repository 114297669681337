import {useGlobalModalContext} from "components/modal/modal-context"
import {useTranslation} from "react-i18next"

export function GrantInfoModal() {
    const {t} = useTranslation()
    const {hideModal} = useGlobalModalContext()

    return <div className="text-align-center">
        <h3 className="mb-3 weight-700 font-18">{t("grant.about.header")}</h3>
        <div className="color-gray-500 font-14 mb-3" dangerouslySetInnerHTML={{__html: t(`grant.about.text`)}} />
        <button onClick={hideModal} className="btn btn-primary btn-color-black weight-500 font-16 w-100">OK</button>
    </div>
}