import {Progress} from "antd"
import {CoinIcon, loyaltyColor, loyaltyColorRgb} from "components/coin"
import {toPrice} from "core/utils/price"
import {ApplicationContext} from "global-context"
import {useContext} from "react"
import {useTranslation} from "react-i18next"

function BonusAmountMaxProgress() {
  const {t} = useTranslation()
  return <div className="btn" style={{color: '#ED9936', backgroundColor: "#ed98361a"}}>{t('bonus.max_level')}</div>
}

function BonusAmountProgress() {
  const {profile} = useContext(ApplicationContext)
  const color = `rgb(${loyaltyColorRgb[profile.next_loyalty.percent]})`
  const background = `rgb(${loyaltyColorRgb[profile.next_loyalty.percent]}, 0.2)`

  return profile.next_loyalty ? (
    <>
      <Progress
        percent={100 * profile.orders_price / profile.next_loyalty.price}
        showInfo={false}
        trailColor='var(--color-gray-50)'
        strokeColor={color}
      />
      <div className="d-flex justify-content-between">
        <div className="btn p-2" style={{color, background}}>{toPrice(profile.orders_price)} / {toPrice(profile.next_loyalty.price)}</div>
        <div className="btn p-2 btn-color-white weight-600" style={{background: loyaltyColor[profile.next_loyalty.percent]}}>{profile.next_loyalty.name}</div>
      </div>
    </>
  ) : (
    <BonusAmountMaxProgress />
  )
}

export function BonusAmount() {
  const {profile} = useContext(ApplicationContext)

  return profile && (
    <div className="bonus-card d-flex flex-column justify-content-center">
      <div className="d-flex justify-content-center align-items-center gap-1 mb-2">
        <span className="font-24 weight-700">{toPrice(profile.bonus, "")}</span>
        <CoinIcon width={24} />
      </div>
      <BonusAmountProgress />
    </div>
  )
}
