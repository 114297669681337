import {Breadcrumb} from "antd"
import {BreadcrumbItemType, BreadcrumbSeparatorType} from "antd/es/breadcrumb/Breadcrumb"
import {useNavigate} from "react-router"
import "./styles.scss"

export function BreadcrumbComponent({
    items,
    header,
    backPath,
    contentRight = <></>,
    navigatable = true
}: {
    items: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[],
    header: string,
    backPath?: string
    contentRight?: string | JSX.Element,
    navigatable?: boolean
}) {
    const navigate = useNavigate()
    const onBack = () => backPath ? navigate(backPath) : navigate(-1)

    return <div className="breadcrumb">
        {items.length > 0 && (
            <Breadcrumb
                separator={<span className="yume yume-chevron-right font-12 color-black" />}
                items={items}
            />
        )}

        <div className="breadcrumb-header text-capitalize">
            <div className="d-flex gap-2 align-items-center">
                {navigatable && <span onClick={onBack} className="yume yume-left" />}
                <h1 className="breadcrumb-header-text">{header}</h1>
            </div>
            <div className="flex-fill d-flex justify-content-end nowrap">{contentRight}</div>
        </div>
    </div>
}