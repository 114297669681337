import {Category} from "../models/category"
import axios from "axios"

export class CategoriesService {
  public async listCategories(): Promise<Category[]> {
    const response = await axios.get("/v1/shared/categories/")
    return response.data
  }
}

const categoryService = new CategoriesService()
export default categoryService
