export const profile = {
  download_smeta: "Скачать смету",
  edit_title: "Редактировать профиль",
  phone_label: "Номер телефона",
  date_label: "Дата рождения",
  name_label: "Имя",
  lastname_label: "Фамилия",
  job_title_label: "Выберите вид деятельности",
  password: "Пароль",
  password_change: "Изменить пароль",
  submit: "Сохранить изменения",
  signin: "Войти",
  signup: "Создать аккаунт",
  lang: "Язык",
  tech_list: "Оборудование списком",
  contacts: "Контакты и адрес",
  title: "Профиль",
  address_placeholder: "Адрес",
  address: `Казахстан, Алматы, <span class="color-primary">ул.Желтоксан 168а</span>`,
  contact_placeholder: "Контактный номер",
  our_location: "Наш офис на карте",
  call: "Позвонить",
  work_time: "с 07:00 до 02:00 (без выходных)",
  welcome: "С возвращением, {{firstName}}!",
  welcome_description: "Желаем отличных съемок :)",
  guest: {
    title: "Войдите или зарегистрируйтесь",
    subtitle: `Чтобы делать покупки, отслеживать заказы и пользоваться персональными скидками и баллами.`,
    product: 'Для того, чтобы добавить товар в избранное – войдите или создайте аккаунт'
  },
  password_form: {
    title: "Изменения паролья учетной записи",
    old: "Старый пароль",
    new: "Новый пароль",
    repeat: "Повторите новый пароль",
    submit: "Сохранить новый пароль",
    success: "Пароль был успешно изменен",
    error_password: "Неверный старый пароль",
    error_server: "Произошла ошибка"
  },
  success_edit: "Данные успешно изменены",
}
