export function OrderInfoRow({
    label,
    value = "-",
    labelClass,
    valueClass
}: {
    label: string | JSX.Element,
    value?: string | JSX.Element,
    labelClass?: string,
    valueClass?: string
}) {
    return (
        <div className="order-payment-row">
            <div className={"order-payment-row-left " + labelClass}>{label}</div>
            <div className={"order-payment-row-right " + valueClass}>{value}</div>
        </div>
    )
}
