export const product = {
  empty: "Бос",
  add_card: "Себетке қосу",
  to_card: "Себетте көрсету",
  searching: "іздеу...",
  articule: "артикул: {{articule}}",
  required_service: "Міндетті сервис: ",
  instruction: "Бейне нұсқау",
  set: "жинақ",
  set_items: "Жинаққа не кіреді:",
  related: "Осы өніммен бірге алады",
  specs: "Cипаттамалары",
  specs_header: "Негізгі сипаттамалары",
  favorite_title: "Таңдаулыларға",
  share: "Бөлісу",
  fav_title: "Таңдаулыларға қосылды",
  fav_subtitle: "Жиынтыққа қосу үшін басыңыз",
  fav_set_title: "Жиынтыққа таңдаңыз",
  list_count: "{{count}} өнім",
  load_more: "Көбірек көрсету",
  filter: {
    sort: 'Сұрыптау',
    empty: 'Фильтрсіз',
    price_asc: 'Баға өсуі бойынша',
    price_desc: 'Баға артуы бойынша',
  }
}
