import {DetailedHTMLProps, HTMLAttributes, useEffect, useState} from "react"
import avatar from "assets/icons/profle-icon.svg"
import background from 'assets/images/background.png'

type FallbackType = 'avatar' | 'background' | 'icon'
const fallbackImage: Record<FallbackType, string> = {
  avatar: avatar,
  background: background,
  icon: background
}

export function ImageField(props: React.ImgHTMLAttributes<HTMLImageElement> & {fallback: FallbackType}) {
  const fallback = fallbackImage[props.fallback]
  const [image, setImage] = useState<string | undefined>(fallback)
  const onError = () => setImage(fallback)

  useEffect(() => setImage(props.src), [props.src])

  return <img
    {...props}
    style={{objectFit: 'contain', ...props.style}}
    src={image || ''}
    alt={props.alt}
    onError={onError}
    draggable="false"
  />
}

export function LazyBackgroundImage(props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {src: string, children: JSX.Element[] | JSX.Element}) {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(var(--color-black-50), var(--color-black-50)), url(${props.src})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        ...props.style
      }}
      {...props}
    >
      {props.children}
    </div >
  )
}
