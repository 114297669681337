import "../styles.scss"
import {useContext} from "react"
import {ApplicationContext} from "global-context"
import {BasketProductChild} from "./child"
import {useTranslation} from "react-i18next"
import basketService from "core/services/basket.service"

export function BasketProductList() {
  const {t} = useTranslation()
  const {basket, getBasket} = useContext(ApplicationContext)
  const uuid: string | null = localStorage.getItem("user_bag_id")

  const clearBasket = async () => {
    await Promise.all(basket.children.map((b) => basketService.deleteProduct(uuid, b.id)))
    await getBasket()
  }

  const children = basket.children.map((child) => <BasketProductChild child={child} key={child.id} />)

  return (
    <div className="basket-card list">

      <button onClick={() => clearBasket()} className="btn btn-red-8 btn-color-red p-2 w-max-content weight-600 font-12">
        <span className="yume yume-trash font-22"></span>
        <span>{t('basket.clear_basket')}</span>
      </button>

      <div className="product-list">{children}</div>

    </div>
  )
}
