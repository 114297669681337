import {Navigate, Outlet, useLocation, useNavigate} from "react-router"
import {useTranslation} from "react-i18next"
import LangSwitcher from "components/lang-switcher/lang-switcher"
import {FormProvider, useForm} from "react-hook-form"
import {useContext, useState} from "react"
import {YumeIcon} from "components/aside/icon"
import {ApplicationContext} from "global-context"
import {use100vh} from "react-div-100vh"
import "./styles.scss"

export interface SignUpForm {
    phone: string
    email: string
    birth_date: string
    first_name: string
    last_name: string
    is_signed: boolean
    password: string
    extra: {
        gender: string
        job_title: string
    }

    session_id: string
    confirm_id: string
}

export function AuthLayout() {
    const height = use100vh()
    const {profile} = useContext(ApplicationContext)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const location = useLocation()
    const [backPath, _] = useState(location.state || '/')
    const onBack = () => navigate(backPath)

    return profile ? (
        <Navigate to={backPath} />
    ) : (
        <div className="layout-auth" style={{maxHeight: height}}>

            <div className="header layout-auth-header shadow">
                <YumeIcon type='dark' />
                <LangSwitcher />
            </div>

            <div className="layout-auth-outlet-wrapper">
                <div className="layout-auth-outlet">
                    <div onClick={() => navigate(-1)} className="d-flex gap-2 align-items-center pointer mb-3">
                        <span className="yume yume-left color-gray-400 font-16" />
                        <span className="color-gray-400">{t("auth.back")}</span>
                    </div>

                    <Outlet context={{onBack}} />
                </div>
            </div>

        </div>
    )
}

export function AuthRegisterLayout() {
    const form = useForm<SignUpForm>()

    return (
        <FormProvider {...form}>
            <Outlet context={{form, type: 'register'}} />
        </FormProvider>
    )
}

export function AuthResetLayout() {
    const form = useForm()

    return (
        <FormProvider {...form}>
            <Outlet context={{form, type: 'reset'}} />
        </FormProvider>
    )
}