import StarRating from "./star-rating"
import {useTranslation} from "react-i18next"
import {FormProvider, useForm} from "react-hook-form"
import {FormControlTextarea} from "components/shared/input/form-control-textarea"
import {useGlobalModalContext} from "components/modal/modal-context"
import authService from "core/services/auth.service"
import clsx from "clsx"
import "./styles.scss"
// import {useGlobalNotificationContext} from "components/notification/notification-context"
// import {NotificationMessage} from "components/notification/notification-message"

export function FeedbackPage({orderId, postSubmit}: {orderId: number, postSubmit: () => void}) {
  const form = useForm({defaultValues: {rating: null, comment: "", list: []}})
  const {register, handleSubmit, watch, setValue, formState} = form
  const {t} = useTranslation()
  const {hideModal} = useGlobalModalContext()
  // const {showNotification, hideNotification} = useGlobalNotificationContext()

  const rating: number = watch('rating')
  const list: string[] = watch('list')

  const feedbackList = [
    {title: t('order.feedback.list.0'), enum: "SERVICE"},
    {title: t('order.feedback.list.1'), enum: "CATALOG"},
    {title: t('order.feedback.list.2'), enum: "SERVICE_QUALITY"},
    {title: t('order.feedback.list.3'), enum: "WEBSITE"},
    {title: t('order.feedback.list.4'), enum: "DELIVERY"},
  ]

  const feedbackTitle = () => {
    if (rating && rating < 3) {
      return t("order.feedback.disliked")
    } else if (rating && rating < 5) {
      return t("order.feedback.improvement")
    } else {
      return t("order.feedback.liked")
    }
  }

  const onToggle = (key: string) => {
    if (list.includes(key)) {
      setValue('list', list.filter((s) => s !== key))
    } else {
      setValue('list', [...list, key])
    }
  }

  const onSubmit = handleSubmit(async (payload) => {
    await authService.postFeedback(orderId, payload)
    hideModal()
    // showNotification({
    //   component: <NotificationMessage
    //     icon="yume-check"
    //     label={t('order.feedback.success.label')}
    //     hint={t('order.feedback.success.hint')}
    //     onClick={hideNotification}
    //   />
    // })
    postSubmit()
  })

  return <FormProvider {...form}>
    <div className="d-flex flex-column gap-4">
      <StarRating name="rating" />

      {rating && (
        <div className="d-flex flex-column gap-2">
          <span className="font-14 color-gray-400">{feedbackTitle()}</span>
          <div className="d-flex gap-2 flex-wrap">
            {feedbackList.map((item) => (
              <div
                key={item.enum}
                className={clsx("feedback-item", {selected: list.includes(item.enum)})}
                onClick={() => onToggle(item.enum)}
              >
                {item.title}
              </div>
            ))}
          </div>
        </div>
      )}

      <FormControlTextarea
        placeholder={t('order.feedback.comment')}
        params={register("comment")}
        rows={4}
      />

      <button className="btn btn-primary" onClick={onSubmit} disabled={!formState.isValid}>
        {t("order.feedback.submit")}
      </button>
    </div>
  </FormProvider>
}
