import {useGlobalModalContext} from "components/modal/modal-context"
import {ErrorModal} from "components/modal/modal-error"
import {SuccessModal} from "components/modal/modal-success"
import {FormControlPassword} from "components/shared/input/form-control-password"
import authService from "core/services/auth.service"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"

interface PasswordEditForm {
    old_password: string
    new_password: string
    repeat: string
}

export function PasswordEdit() {
    const form = useForm<PasswordEditForm>()
    const {t} = useTranslation()
    const {showModal} = useGlobalModalContext()
    const valid = form.formState.isValid && form.watch('repeat') === form.watch('new_password')

    const onSubmit = form.handleSubmit((payload) => {
        authService.editPassword(payload).then(() => {
            showModal({
                component: <SuccessModal header={t("profile.password_form.success")} />,
                centered: true
            })
        }).catch(() => {
            showModal({
                component: <ErrorModal header={t("profile.password_form.error_server")} />,
                centered: true
            })
        })
        // editPassword
    })

    return <FormProvider {...form}>
        <h4 className="mb-3 align-self-baseline weight-700">{t("profile.password_form.title")}</h4>
        <FormControlPassword
            name="old_password"
            placeholder={t("profile.password_form.old")}
            className="form-control mb-3"
            params={{required: true}}
        />
        <FormControlPassword
            name="new_password"
            placeholder={t("profile.password_form.new")}
            className="form-control mb-3"
            params={{required: true}}
        />
        <FormControlPassword
            name="repeat"
            placeholder={t("profile.password_form.repeat")}
            className="form-control mb-3"
            params={{required: true}}
        />
        <button className="btn btn-primary mb-0 auth-submit p-3" disabled={!valid} onClick={onSubmit}>
            {t("profile.password_form.submit")}
        </button>
    </FormProvider>
}