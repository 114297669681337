import axios from "axios";

export class BasketService {
  public async init(payload: any = {}): Promise<{ id: string }> {
    const res = await axios.post("/v1/shared/bag/", payload);
    return res.data;
  }

  public async getBasket(uuid: string | null): Promise<any> {
    const res = await axios.get(`/v1/shared/bag/${uuid}/`);
    return res.data;
  }

  public async updateBag(uuid: string | null, payload: any): Promise<any> {
    const res = await axios.patch(`/v1/shared/bag/${uuid}/`, payload);
    return res.data;
  }

  public async postProduct(uuid: string | null, payload: any): Promise<any> {
    const res = await axios.post(`/v1/shared/bag/${uuid}/products/`, payload);
    return res.data;
  }

  public async patchProduct(uuid: string | null, id: number | undefined, payload: any): Promise<any> {
    const res = await axios.patch(`/v1/shared/bag/${uuid}/products/${id}/`, payload);
    return res.data;
  }

  public async deleteProduct(uuid: string | null, id: number | undefined): Promise<undefined> {
    const res = await axios.delete(`/v1/shared/bag/${uuid}/products/${id}/`);
    return res.data;
  }

  public async createOrder(uuid: string | null): Promise<{ request_id: number }> {
    const res = await axios.post(`/v1/shared/bag/${uuid}/create_order/`);
    return res.data;
  }
}

const basketService = new BasketService();
export default basketService;
