import {ArticleModel} from "core/models/article"
import {useTranslation} from "react-i18next"
import {ImageField} from "core/utils/image"
import {localeString} from "core/utils/localeString"
import "./article-list.scss"

export function ArticleModal({article}: {article: ArticleModel}) {
  const {t} = useTranslation()

  return article && (
    <div className="article">
      <ImageField className="article-image" src={localeString(article.extra, 'image') || article.image} alt={article.slug} fallback='background' />
      <div className="article-content" dangerouslySetInnerHTML={{__html: t(localeString(article.extra, "body"))}}></div>
    </div>
  )
}
