import "./styles.scss"
import {useContext} from "react"
import {ApplicationContext} from "global-context"
import {Timeline} from "antd"
import {ORDER_COMPLETED, ORDER_DEBTOR, ORDER_INRENT, ORDER_REQUEST, ORDER_RESERVE} from "core/const"
import {OrderDetailModel} from "core/models/order"
import {statusName} from "../orders/const"
import {useTranslation} from "react-i18next"

const defaultTimelineStatuses = [ORDER_REQUEST, "|", ORDER_RESERVE, "|", ORDER_INRENT, "|", ORDER_COMPLETED]

export const statusTimeline = {
  [ORDER_REQUEST]: {
    detail: `order.status.order.${ORDER_REQUEST}.hint`,
    color: (status: number) => {
      if (status >= ORDER_REQUEST) return "color-primary"
      return "color-gray-400"
    },
    colorMobile: (status: number) => {
      if (status === ORDER_REQUEST) return "color-primary"
      return "color-gray-400"
    },
    status: (status: number) => {
      if (status === ORDER_REQUEST) return "pending"
      if (status > ORDER_REQUEST) return "done"
      return ""
    },
    defaultColor: "color-gray-400",
  },
  [ORDER_RESERVE]: {
    detail: `order.status.order.${ORDER_RESERVE}.hint`,
    color: (status: number) => {
      if (status >= ORDER_RESERVE) return "color-primary"
      return "color-gray-400"
    },
    colorMobile: (status: number) => {
      if (status === ORDER_RESERVE) return "color-primary"
      return "color-gray-400"
    },
    status: (status: number) => {
      if (status === ORDER_RESERVE) return "pending"
      if (status > ORDER_RESERVE) return "done"
      return ""
    },
  },
  [ORDER_INRENT]: {
    detail: `order.status.order.${ORDER_INRENT}.hint`,
    color: (status: number) => {
      if (status >= ORDER_INRENT) return "color-primary"
      return "color-gray-400"
    },
    colorMobile: (status: number) => {
      if (status === ORDER_INRENT) return "color-primary"
      return "color-gray-400"
    },
    status: (status: number) => {
      if (status === ORDER_INRENT) return "pending"
      if (status > ORDER_INRENT) return "done"
      return ""
    },
  },
  [ORDER_COMPLETED]: {
    detail: `order.status.order.${ORDER_COMPLETED}.hint`,
    color: (status: number) => {
      if (status >= ORDER_COMPLETED) return "color-primary"
      return "color-gray-400"
    },
    colorMobile: (status: number) => {
      if (status === ORDER_COMPLETED) return "color-primary"
      return "color-gray-400"
    },
    status: (status: number) => {
      if (status === ORDER_COMPLETED) return "pending"
      if (status > ORDER_COMPLETED) return "done"
      return ""
    },
  },
  [ORDER_DEBTOR]: {
    detail: `order.status.order.${ORDER_DEBTOR}.hint`,
    color: (status: number) => {
      if (status === ORDER_DEBTOR) return "color-primary"
      return "color-gray-400"
    },
    colorMobile: (status: number) => {
      if (status === ORDER_DEBTOR) return "color-primary"
      return "color-gray-400"
    },
    status: (status: number) => {
      if (status === ORDER_DEBTOR) return "pending"
      if (status > ORDER_DEBTOR) return "done"
      return ""
    },
  },
}

export function OrderTimeline(config: {order: OrderDetailModel}) {
  const order = config.order
  const {t} = useTranslation()
  const {isMobile} = useContext(ApplicationContext)
  const timelineStatuses = order && order.status !== ORDER_DEBTOR ? defaultTimelineStatuses : [...defaultTimelineStatuses, "|", ORDER_DEBTOR]

  const statusItems = order && timelineStatuses
    .filter((obj) => obj !== "|")
    .map((status) => {
      const timeline = statusTimeline[status]
      const color = timeline.colorMobile(order.status)
      return {
        color: color,
        dot: <div className={`status-dot ${timeline.status(order.status)}`} />,
        children: (
          <>
            <div className={`weight-700 ${color}`}>{t(`order.status.order.${status}.hint`)}</div>
            <div className={color}>{t(timeline.detail)}</div>
          </>
        ),
      }
    })

  const desktopStatusItems = order && timelineStatuses.map((status, index) => {
    const nextStatus = status === "|" && +timelineStatuses[index + 1]

    return status === "|" ? (
      <div key={index} className={`yume yume-right ${statusTimeline[nextStatus].color(order.status)}`}></div>
    ) : (
      <div key={index} className="d-flex flex-column gap-1 align-items-center text-align-center">
        <div className={`status-dot ${statusTimeline[status].status(order.status)}`}></div>
        <div className={statusTimeline[status].color(order.status)}>{statusName[status]}</div>
      </div>
    )
  })

  return isMobile ? (
    <Timeline items={statusItems} />
  ) : (
    <div className="d-flex gap-2 justify-content-between align-items-center w-100">
      {desktopStatusItems}
    </div>
  )
}
