import {createContext, Dispatch, MutableRefObject, SetStateAction, useEffect, useLayoutEffect, useRef, useState} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {Aside} from "components/aside/aside"
import Footer from "components/footer"
import {Header} from "components/header"
import {BasketModel} from "core/models/basket"
import {Category} from "core/models/category"
import basketService from "core/services/basket.service"
import categoryService from "core/services/category.service"
import {Navigation} from "components/navigation/navigation"
import Authorization, {AuthPageConfig, AuthPageType} from "pages/auth/modals/authorization"
import {SetModel} from "components/sets-modal/model"
import authService from "core/services/auth.service"
import tokenService from "core/services/token.service"
import {useWindowSize} from "core/utils/window-resize"
import {ContentHeader, ContentHeaderConfig, defaultContentHeaderConfig} from "components/content-header"
import {ProductModel} from "core/models/product"
import {ProfileModel} from "core/models/user"
import {Loader} from "components/loader"
import {GlobalNotification} from "components/notification/notification-context"
import {use100vh} from "react-div-100vh"
import {GlobalModal} from "components/modal/modal-context"
import "./App.scss"

export type ApplicationContextType = {
  contentRef: MutableRefObject<any>
  categories: Category[]
  getCategory: (key: number | string) => Category
  basket: BasketModel
  loadBasket: () => void
  getBasket: () => Promise<void>
  profile: ProfileModel
  favorites: ProductModel[]
  sets: SetModel[]
  setSets: (any) => void
  nameOfSet: string
  setNameOfSet: (any) => void

  logout: () => void
  authenticated: boolean
  isMobile: boolean

  // authorization modal navigation
  authModal: AuthPageConfig
  setAuthModal: (page: AuthPageType) => void

  // contentHeader control
  contentHeaderConfig: ContentHeaderConfig
  setContentHeaderConfig: Dispatch<SetStateAction<ContentHeaderConfig>>
  contentHeight: number | string
  setContentHeight: Dispatch<SetStateAction<number | string>>
}

export const ApplicationContext = createContext<ApplicationContextType>(undefined)

export const Application = ({children}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [profile, setProfile] = useState<ProfileModel>()
  const [categories, setCategories] = useState<Category[]>([])
  const [basket, setBasket] = useState<BasketModel>()
  const [favorites, setFavorites] = useState<ProductModel[]>([])
  const [sets, _setSets] = useState<SetModel[]>([])
  const [nameOfSet, setNameOfSet] = useState<string>("")
  const [authenticated, setAuthenticated] = useState(false)

  const [authModal, _setAuthModal] = useState<AuthPageConfig>()
  const [contentHeaderConfig, setContentHeaderConfig] = useState<ContentHeaderConfig>(defaultContentHeaderConfig)

  const contentRef = useRef(null)

  const getCategory = (key: number | string) => {
    return categories.find((category) => category.id === key || category.slug === key)
  }

  const createBasket = async () => {
    const {id} = await basketService.init({})
    localStorage.setItem("user_bag_id", id)
    return id
  }

  const getBasket = () => {
    const uuid = localStorage.getItem("user_bag_id")
    return basketService.getBasket(uuid).then(setBasket)
  }

  const loadBasket = () => {
    const uuid = localStorage.getItem("user_bag_id")

    if (!!!uuid) {
      createBasket().then(getBasket)
    } else {
      getBasket().catch(() => createBasket().then(getBasket))
    }
  }

  const setAuthModal = (page: AuthPageType) => {
    if (page === undefined) {
      _setAuthModal(undefined)
    } else {
      _setAuthModal({page})
    }
  }

  const setSets = (data: SetModel[]) => _setSets(data)

  const logout = () => {
    authService.logout().then(() => {
      tokenService.removeToken()
      setAuthenticated(false)
      setProfile(undefined)
      setFavorites([])
      navigate("/")
    })
  }

  const [width, _] = useWindowSize()
  const isMobile = width <= 1024
  const loaded = tokenService.hasToken ? profile !== undefined : true

  const isAuthPage = location.pathname.startsWith("/auth")
  const isBasketCheckout = location.pathname.startsWith("/basket/checkout")
  const isEventFormCheckout = location.pathname.includes("/visit")

  const trueHeight = use100vh()
  const [contentHeight, setContentHeight] = useState<number | string>(isMobile ? trueHeight && trueHeight - 114 : trueHeight)

  const [headerHeight, setHeaderHeight] = useState<number>(0)
  const [bottomHeight, setBottomHeight] = useState<number>(0)

  useLayoutEffect(() => {
    if (!!trueHeight) setContentHeight(isMobile ? trueHeight - 114 : trueHeight)
  }, [trueHeight, isMobile])

  useEffect(() => {
    if (!isMobile) setContentHeight(trueHeight)
  }, [isMobile])

  useEffect(() => {
    const sub = authService.profile$.pipe().subscribe((profile) => {
      setAuthenticated(profile !== null)
      setProfile(profile)
    })
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = authService.favorites$.pipe().subscribe(setFavorites)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const sub = authService.setGroups$.pipe().subscribe(_setSets)
    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    categoryService.listCategories().then(setCategories).then(() => loadBasket())
  }, [])

  useEffect(() => {
    if (!contentRef.current) return
    contentRef.current.scrollIntoView({top: 0, behavior: 'smooth'})
  }, [location])

  return (
    <ApplicationContext.Provider
      value={{
        contentRef,
        getCategory,
        categories,
        basket,
        loadBasket,
        getBasket,
        favorites,
        logout,
        contentHeaderConfig,
        setContentHeaderConfig,
        authenticated,
        profile,
        isMobile,
        authModal,
        setAuthModal,
        sets,
        setSets,
        nameOfSet,
        setNameOfSet,
        setContentHeight,
        contentHeight,
      }}
    >
      <div ref={contentRef}>
        <GlobalModal>
          <GlobalNotification bottom={bottomHeight}>
            <Authorization />
            <Loader />

            {isAuthPage && children}
            {isBasketCheckout && basket && children}
            {isEventFormCheckout && children}

            {!isAuthPage && !isBasketCheckout && !isEventFormCheckout && categories.length > 0 && basket && loaded && (
              <div className="wrapper" style={{height: trueHeight}}>
                <Aside />
                <Header setHeaderHeight={setHeaderHeight} />
                <div className="right-side" style={{paddingTop: headerHeight, paddingBottom: bottomHeight}}>
                  <ContentHeader default={false} />
                  <div className="content">
                    <div className="content-holder" id="content-holder">
                      {children}
                    </div>
                    <Footer />
                  </div>
                </div>

                <Navigation setBottomHeight={setBottomHeight} />
              </div>
            )}
          </GlobalNotification>
        </GlobalModal>
      </div>
    </ApplicationContext.Provider >
  )
}
