import {useMemo} from 'react'
import {useSearchParams} from 'react-router-dom'

export default function useQueryParams() {
    const [searchParams, setSearchParams] = useSearchParams()

    const query: Record<string, any> = useMemo(() => Object.fromEntries(searchParams), [searchParams])
    const setParams = params => setSearchParams(params, {replace: false, relative: 'route'})

    return {searchParams: query, setSearchParams: setParams}
}
