import ProductSearch from "pages/product-list/search"
import {ProfileHeader} from "pages/user/profile-header"
import {ApplicationContext} from "global-context"
import {useContext, useEffect, useRef} from "react"
import {useLocation} from "react-router-dom"
import "./styles.scss"

export interface ContentHeaderConfig {
  backLocation?: any
  content?: JSX.Element
  rightContent?: JSX.Element
  hidden?: boolean
}

export const defaultContentHeaderConfig: ContentHeaderConfig = {
  backLocation: -1,
}

export function ContentHeader(config: {default: boolean}) {
  const {isMobile, contentHeaderConfig, setContentHeaderConfig} = useContext(ApplicationContext)
  const ref = useRef<HTMLDivElement | null>(null)
  const location = useLocation()
  const hidden = contentHeaderConfig.hidden || false
  const hiddenRoutes = ["/basket/checkout", "/events/visit/success"]

  useEffect(() => {
    if (config.default) setContentHeaderConfig(defaultContentHeaderConfig)
  }, [location, config.default, setContentHeaderConfig])

  const show = !isMobile && hiddenRoutes.every(route => route !== location.pathname)

  return !hidden && (
    <div className="content-header gap-2">
      <div ref={ref} className="d-flex flex-column w-100">{contentHeaderConfig.content || <ProductSearch />}</div>
      {show && <ProfileHeader />}
    </div>
  )
}
