import {InputHTMLAttributes} from 'react'
import {RegisterOptions, useFormContext} from 'react-hook-form'
import {DatePicker} from 'antd'
import dayjs from 'dayjs'

interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  id?: any
  rootclassname?: string
  name: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
}

export function FormControlDateV2(config: FormControlConfig) {
  const {register, watch, setValue, formState: {errors}} = useFormContext()
  const error = errors[config.name]

  const className = `${config.className ? config.className : ''} ${error ? 'invalid' : ''}`
  const value = watch(config.name) ? dayjs(watch(config.name)) : undefined
  const onChange = (date: dayjs.Dayjs) => {
    if (date) {
      setValue(config.name, date.format('YYYY-MM-DD'), {shouldDirty: true, shouldTouch: true})
    } else {
      setValue(config.name, null, {shouldDirty: true, shouldTouch: true})
    }
  }

  return (
    <div className={config.rootclassname || ''}>
      {config.label && (
        <div className="d-flex gap-2 mb-2">
          <label className="overflow font-13 color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}
      <DatePicker
        {...register(config.name, config.params)}
        format="DD-MM-YYYY"
        value={value}
        className={className}
        onChange={onChange}
        disabled={config.disabled}
        placeholder={config.placeholder}
        inputReadOnly
      />
    </div>
  )
}
