export const order = {
  header: "Қазіргі тапсырыс {{order_id}}",
  repeat: {
    label: 'Себетке қосылды',
    hint: 'Тапсырыс беру үшін басыңыз'
  },
  status: {
    order: {
      label: "Тапсырыс күйі",
      0: {
        label: "Тексерілуде",
        hint: "Менеджер тексеруде"
      },
      1: {
        label: "Брондалған",
        hint: "Жалға беруге дайын"
      },
      2: {
        label: 'Жалдауда',
        hint: "Жабдықтар жалға берілді"
      },
      3: {
        label: 'Жойылған',
        hint: "Жалға беруден бас тартылды"
      },
      4: {
        label: 'Жалға алу аяқталды',
        hint: "Менеджер жабдықты қабылдады"
      },
      5: {
        label: "Төлем күтілуде",
        hint: "Төлем күтілуде"
      }
    },
    payment: {
      0: 'Төлем күтілуде',
      1: 'Төлем жасалды',
      2: 'Жартылай төленген'
    }
  },
  reserved: {
    label: "Тапсырыс брондалған!",
    hint: "Тапсырысты тексеріп, төлеуге болады"
  },
  cancel: {
    commit: "Тапсырысты жою",
    submit: "Тапсырыстан бас тарту",
    restrict: "Тапсырыстан брондауға дейін 3 сағаттан аз уақыт бұрын және егер тапсырыс толығымен төленген болса, бас тартуға болмайды",
    reason: "Тапсырысты тоқтатудың себебі",
    reason_list: [
      'Түсірілім тоқтатылды',
      'Жабдық маған қажет уақытта бос емес',
      'Қажетті құрал-жабдықтар болмады'
    ],
    modal: {
      header: "Тапсырыс бас тартылды",
      confirm: "Басты бетке оралу"
    }
  },
  feedback: {
    header: "Тапсырысты бағалаңыз",
    commit: "Пікір қалдыру",
    liked: "Сізге не ұнады?",
    improvement: "Нені жақсартуға болады?",
    disliked: "Сізге не ұнамады?",
    comment: "Пікір",
    submit: "Жіберу",
    list: ["Қызмет", "Жабдықтардың каталогы", "Жабдықтың жұмысқа жарамдылығы", "Сайт", "Жеткізу"],
    success: {
      label: "Пікіріңізге рахмет!",
      hint: "Сіздің пікіріңіз бізді жақсартады"
    }
  },

  // old
  period: "Жалдау мерзімі",
  delivery_address: "Жеткізу мекенжайы",
  all_text: "Барлық тапсырыстар",
  delivery_text: "Жеткізу",
  takeaway_text: "Алып кету",
  empty_list: "Сізде әлі тапсырыс жоқ",
  empty_favorite_list: "Сізде әлі таңдаулы өнімдер жоқ",
  empty_set_list: "Жинақ бос",
  go_to_catalog: "Каталогқа өтіңіз",
  list_title: "Техника тізімі",
  other: "Басқа ...",
  amount: "дана",
  set: "жиынтық",
  set_content: "Жиынтыққа кіреді",
  payment_title: "Төлем туралы ақпарат",
  sum: "Сома",
  discounted_sum: "Жеңілдікпен сомасы",
  bonus_added: "Есептелген бонустар:",
  bonus_will_add: "Есептелетін бонустар:",
  paid: "Төленді",
  remainder: "Қалдық",
  bonus_hint: "Сіз тапсырыстың 30%-ын бонустармен төлей аласыз",
  spend_bonus: "Жұмсау",
  payment_total: "Төлемге",
  pay_card: "Картамен төлеу",
  pay_bonus: "Бонустармен төлеу",
  pay_bill: "Төлемақы",
  pay_kaspi: "Kaspi арқылы төлеу",
  kaspi: {
    title: "Kaspi арқылы төлеу",
    text_1: "1. Тапсырыс сомасын көшіріңіз",
    text_2: "2. «Kaspi-ге өту» түймесін басып, Kaspi.kz қосымшасына өтіңіз",
    text_3: "3. Көшірілген соманы енгізіп, тапсырысты төлеңіз",
    copy: "Көшіру",
    proceed: "Kaspi-ге өту",
  },
  payment_status: "Төлем күйі",
  payment_btn: "Тапсырысты төлеу",
  close_modal: "Тапсырысты ашу",
  approve_title: "Әрекетті растаңыз",
  cancen_confirm_btn: "Тапсырысты жою",
  on_receive_text: "Тапсырысты алғаннан кейін төлегіңіз келетініне сенімдісіз бе?",
  on_receive_confirm_btn: "Иә, алғаннан кейін төлеймін",
  deny_btn: "Жоқ",
  payment_modal_title: "Төлеу",
  bill_modal_title: "Деректерді енгізіңіз",
  order_again: "Тапсырысты қайталау",
  problem: `Тапсырысқа қатысты сұрағыныз бар ма? Менеджерге қоңырау шалыңыз <a href="tel:8(705)-333-69-36" class="nowrap">8 705 333 69 36</a>`,
}
