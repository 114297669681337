import {EventModel} from "../../core/models/events"
import {useTranslation} from "react-i18next"
import {localeString} from "../../core/utils/localeString"
import toDateTime from "core/utils/date-time"
import clsx from "clsx"
import "./style.scss"

export const EventBadge = ({event, dark = false, upcomming = true}: {event: EventModel, dark?: boolean, upcomming?: boolean}) => {
  const {t} = useTranslation()
  const onLink = () => window.open(event.location_link, "_blank")
  
  return event && (
    <div className="d-flex gap-2 align-items-center w-100">

      <div className={clsx("events-card-badge nowrap", {dark})}>
        <span className="yume yume-date font-16"></span>
        <span className="weight-500 font-12">{toDateTime(event.date)}</span>
      </div>

      {event.location_link && <div onClick={onLink} className={clsx("events-card-badge overflow", {dark})}>
        <span className="d-flex gap-1 align-items-center">
          <span className="yume yume-location font-16"></span>
          <span className="weight-500 font-12 text-truncate">{t(localeString(event.extra, "location"))}</span>
        </span>
      </div>}

      {upcomming && <div className={clsx("events-card-badge nowrap", {dark})}>
        <span className="yume yume-user font-16"></span>
        <span className="weight-500 font-12">{event.user_registered}/{event.user_limit}</span>
      </div>}

    </div>
  )
}

