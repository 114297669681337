import {useGlobalNotificationContext} from 'components/notification/notification-context'

interface NotificationMessageConfig {
  icon: string
  label: string
  hint: string
  onClick: () => void
}

export function NotificationMessage({icon, label, hint, onClick}: NotificationMessageConfig) {
  const {hideNotification} = useGlobalNotificationContext()

  const click = () => {
    onClick()
    hideNotification()
  }

  return (
    <div className="notification p-3" onClick={click}>
      <div className="d-flex align-items-center gap-3">
        <div className="notification-icon bg-primary-8">
          <span className={`yume ${icon} color-primary`}></span>
        </div>
        <div className="d-flex flex-column gap-1">
          <div className="font-14 weight-600 color-black nowrap">{label}</div>
          <div className="font-12 color-gray-400">{hint}</div>
        </div>
      </div>
      <div className="yume yume-chevron-right font-12 color-gray-300"></div>
    </div>
  )
}