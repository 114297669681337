import {footer} from "./footer.locale"
import {basket} from "./basket.locale"
import {auth} from "./auth.locale"
import {product} from "./product.locale"
import {grant} from "./grant.locale"
import {user} from "./user.locale"
import {order} from "./order.locale"
import {faq} from "./faq.locale"
import {profile} from "./profile.locale"
import {bonus} from "./bonus.locale"
import {eventsLocale} from "./events.locale"

export const i18n_kk = {
  translation: {
    _locale: "",
    product,
    basket,
    auth,
    footer,
    grant,
    user,
    order,
    profile,
    bonus,
    
    main_screen: "Басты бет",
    categories_screen: "Санаттар",
    basket_screen: "Себет",
    favorites: "Таңдаулылар",
    events: eventsLocale,
    faq,
    request_call: "Қоңырау шалу",
    
    main: {
      payment: "Төлем",
      community: "Қауымдастық",
      podbor: "Таңдау",
      new_products: "Жаңа тауарлар",
      all_products: "Барлық тауарлар",
      bonus_products: "Арнайы ұсыныстар",
      discount_products: "Осы апта жеңілдіктері",
    },
    community: {
      body: `<h5><strong>yumerental - видео-жабдықтарды жалға беру ғана емес!</strong></h5>
        <div>&nbsp;</div>
        <p>Біз өзәра көмек көрсету мәдениеті мен қауымдастықтықты дәріптейміз.</p>
        <div>Yume.rent-те біз:</div>
        <div>&bull; пайдалы ақпарат алмасуды және нетворкингті қолдаймыз;</div>
        <div>&bull; бірлескен жобалар жасаймыз;</div>
        <div>&bull; саладағы өзекті тақырыптарды талдаймыз;</div>
        <div>&bull; нарық көшбасшыларымен шеберлік сабақтары арқылы даму мүмкіндігін береміз;</div>
        <div>&bull; әрбір тұтынушыны дос ретінде қарсы аламыз.</div>
        <div>&nbsp;</div>
        <div><strong>yumerental - шығармашылық пен жасампаздыққа арналған құралдар.</strong></div>
      `,
      btn: "қоғамдас болғым келеді",
    },
    in_process: "Процессте",
    show_all: "Барлығын көру",
  },
}
