import {useCountdown} from "core/utils/countdown"
import {BasketChildrentModel} from "core/models/basket"
import {useContext, useEffect} from "react"
import {localeString} from "core/utils/localeString"
import {useTranslation} from "react-i18next"
import {ApplicationContext} from "global-context"
import basketService from "core/services/basket.service"
import "./styles.scss"

export function BasketProductDelete(config: {
  product: BasketChildrentModel
  onCancel: () => void
}) {
  const {t} = useTranslation()
  const uuid: string | null = localStorage.getItem("user_bag_id")
  const {time} = useCountdown(10)
  const {getBasket} = useContext(ApplicationContext)

  const onDelete = () => {
    basketService
      .deleteProduct(uuid, config.product.id)
      .then(getBasket)
      .finally(() => config.onCancel())
  }

  useEffect(() => {
    if (time === 0) onDelete()
  }, [time])

  return (
    <div className="basket-product basket-product-delete animate-fade">
      <div className="d-flex align-items-center" style={{width: "100%"}}>
        <div className="delete-countdown">{time}</div>
        <div className="basket-item-info">
          <div className="delete-message overflow">
            {t("basket.deleting.message", {name: t(localeString(config.product.extra, "name"))})}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <button
          className="btn btn-black white-space"
          onClick={config.onCancel}
        >
          {t("basket.deleting.cancel")}
        </button>
        <button
          className="btn btn-black white-space"
          style={{marginLeft: 12}}
          onClick={onDelete}
        >
          {t("basket.deleting.delete")}
        </button>
      </div>
    </div>
  )
}
