import {useTranslation} from "react-i18next"
import "./community.scss"
import background from "./back.png"
import qrCode from "./qr-code.png"
import {ImageField} from "../../core/utils/image"

export function CommunityPage() {
  const {t} = useTranslation()

  return (
    <div className="community-holder">
      <ImageField className="community-image" src={background} fallback="background" />
      <div className="community-content-holder">
        <div
          className="community-content flex-fill"
          dangerouslySetInnerHTML={{__html: t("community.body")}}
        ></div>
        <div className="community-links">
          <ImageField className="community-links-qr" src={qrCode} fallback="background" />
          <a
            href="https://t.me/yumerentalcommunity"
            target="_blank"
            rel="noreferrer"
            className="btn btn-black community-links-btn"
          >
            {t("community.btn")}
          </a>
        </div>
      </div>
    </div>
  )
}
