export const basket = {
  title: "Себет",
  products: "{{count}} тауар",
  step: "Қадам {{step}} / {{steps}}",
  clear_basket: "Cебетті босату",
  clear: "Тауарларды жою",

  price: "{{count}} дана х {{price_discount}}",
  price_discount: "{{count}} дана х {{price_discount}} / <span class='weight-500 text-decoration-line-through color-red'>{{price}}</span>",

  empty_list: "Себет бос",

  main_info: "Негізгі ақпарат",
  details: "Толығырақ",
  date: "Күні",
  time: "Уақыты",
  comment: "Түсініктеме (міндетті емес)",
  delivery_map: "Біз Саин - Әл-фараби - ШАЖ - Райымбек Батыр шаршысында жеткіземіз",
  attention_text: "Бірінші жалға алу үшін жеткізу сервисі мүмкін емес",

  empty: {
    label: "Себет бос",
    hint: "Қажетті нәрсені табу үшін каталогты пайдаланыңыз немесе іздеңіз.",
    main: "Басты бетке",
    auth: "Аккаунтқа кіру",
  },

  deleting: {
    message: `Cіз жоясыз: "{{name}}"`,
    cancel: "бас тарту",
    delete: "жою",
  },
  grant: {
    title: "ГРАНТ ҰТЫП АЛЫҢЫЗ",
    subtitle: "300.000 ₸-ге дейін",
    footer: `<span class="d-inline-block mt-2 font-13 color-white">Каждый месяц <span className="color-primary">с 20 по 22 число</span></span>`,
  },
  form: {
    header: "Мәліметтерді толтырыңыз",
    name_placeholder: "Атыңызды енгізіңіз",
    address_placeholder: "Жеткізу мекенжайын енгізіңіз",
    address_return_placeholder: "Қайтару мекенжайын енгізіңіз",
    address_message: "Мекен - жайымыз: Желтоқсан 168А",
    delivery_message: "Жеткізу аймағымен танысыңыз",
    pickup_option: "Өзіммен әкету",
    delivery_option: "Жеткізу",
    delivery_free: "Тегін",
    phone_placeholder: "Телефон нөмірін енгізіңіз",
    comment_placeholder: "Пікір...",
    rent_period: "Жалдау мерзімін таңдаңыз",
    rent_start: "Басталуы",
    rent_finish: "Аяқталуы",
    date_placeholder: "кк.аа.жжжж",
    delivery_zone: "Жеткізу аймағы",
  },
  banner: {
    title: `
      <h1 class='mb-0 color-white text-uppercase font-18 weight-700'>{{price}} - ДЕН БАСТАП ТАПСЫРЫС БЕРУ КЕЗІНДЕ</h1>
      <h1 class='mb-0 color-primary text-uppercase font-18 weight-700'>ТЕГІН ЖЕТКІЗУ</h1> 
    `,
    subtitle: "Аккаунт болған кезде және тексеруден кейін",
    button: "Кіру немесе Тіркелу",
  },
  submit: {
    checkout: "Рәсімдеуге өту",
    order: "Брондау",
    grant: "Грантқа қатысу <br/> (айдың 20-22 күндері қол жетімді)"
  },
  checkout: {
    title: "Тапсырысты рәсімдеу",
    back: "Себетке оралу",
  },
  delivery: {
    pickup: "Алып кету",
    delivery: "Жеткізу",
    unavailable: "уақытша мүмкін емес",
    progress: {
      title: 'Тегін жеткізу',
      pending: `
        <span>Тегін жеткізуге дейін тағы</span>
        <span class='color-primary font-13'>{{price}} қалды</span> 
      `,
      reached: "Сіздің тапсырысыңыз {{price}}-ден астам суммаға жетті"
    }
  },
  success: {
    title: "Күте тұрыңыз, біздің менеджер сізге хабарласады",
    back: "Негізгіге",
    request: "Тапсырысқа өту",
    bot: {
      message: "Telegram ботына қосылып, тапсырысыңыздың статусы туралы хабарландырулар алыңыз",
      connect: "Ботты қосу"
    }
  }
}
