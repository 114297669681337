import './styles.scss'
import {ApplicationContext} from 'global-context'
import {useContext} from 'react'
import {SetModel} from 'components/sets-modal/model'
import {Link} from 'react-router-dom'
import noImage from 'assets/icons/camera-icon.svg'

export function SetCardView(config: {set: SetModel; onClick?: (set: SetModel) => void}) {
  const {isMobile} = useContext(ApplicationContext)
  const set = config.set
  const images = [...set.group_images, ...set.set_images, ...set.service_images]
  const image = images.length > 0 ? images[0] : noImage

  const onClick = event => {
    if (config.onClick !== undefined) {
      event.preventDefault()
      config.onClick(set)
    }
  }

  return (
    <Link to={`/profile/favorites/sets/${set.id}`} className="set w-100" onClick={onClick}>
      <div className="set-card-icon">
        <img src={image} alt="" />
      </div>
      <div className="d-flex flex-fill align-items-center justify-content-between">
        <div>
          <div className="set-card-title color-black">{set.name}</div>
          <div className="set-card-subtitle">Товаров: {set.count}</div>
        </div>
        {isMobile && <div className="yume yume-chevron-right color-black font-12"></div>}
      </div>
    </Link>
  )
}
