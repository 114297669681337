import {useContext, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useNavigate, useParams} from "react-router-dom"
import {EventBadge} from "../event-badge"
import {EventsDetailUpcommingList} from "./upcomming"
import {EventsDetailPlayer} from "./player"
import {EventsDetailContent} from "./actions"
import {ApplicationContext} from "global-context"
import {EventModel} from "core/models/events"
import {eventsService} from "core/services/events.service"
import {localeString} from "core/utils/localeString"
import "./style.scss"
import moment from "moment"

export const EventsDetailPage = () => {
  const {isMobile, setContentHeaderConfig} = useContext(ApplicationContext)
  const {slug} = useParams<{slug: string}>()
  const {t} = useTranslation()
  const [event, setEvent] = useState<EventModel>()
  const upcomming = event ? moment(event.date) > moment() : false
  const navigate = useNavigate()

  useEffect(() => {
    eventsService.getEvent(slug).then(setEvent)
    return () => setEvent(undefined)
  }, [slug])

  useEffect(() => {
    if (isMobile) setContentHeaderConfig({hidden: true})
    return () => setContentHeaderConfig({hidden: false})
  }, [setContentHeaderConfig, isMobile])

  const onBack = () => navigate(-1)
  const onCancel = async () => {
    await eventsService.cancel(event.slug)
    await eventsService.getEvent(slug).then(setEvent)
  }

  return event && (
    <>
      <div className="event-detail">

        <div className="event-detail-info">

          <div className="visit-header">
            <div className="d-flex gap-2 align-items-center pointer" onClick={onBack}>
              <span className="yume yume-left color-gray-400 font-18" />
              <span className="color-gray-400">{t("auth.back")}</span>
            </div>
            <h1 className="visit-header-title mb-0 color-black">{t(localeString(event.extra, "title"))}</h1>
            {upcomming && <EventBadge event={event} dark />}
          </div>

          <EventsDetailContent event={event} onCancel={onCancel} />

        </div>

        <EventsDetailPlayer event={event} />

        <EventsDetailContent event={event} onCancel={onCancel} />

      </div >

      <EventsDetailUpcommingList />
    </>
  )
}
