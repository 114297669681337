import {useContext} from "react"
import {ApplicationContext} from "../../global-context"
import {useLocation, useNavigate} from "react-router"
import {useTranslation} from "react-i18next"

export const BasketEmptyCard = () => {
  const {authenticated} = useContext(ApplicationContext)
  const navigate = useNavigate()
  const location = useLocation()
  const {t} = useTranslation()
  
  const onLogin = () => navigate('/auth/login', {state: location.pathname})
  const onMain = () => navigate('/')

  return (
    <div className="d-flex flex-column justify-content-center align-items-center text-align-center my-5">
      <h1 className="font-24 weight-700 mb-3">{t('basket.empty.label')}</h1>
      <span className="font-14 mb-3">{t('basket.empty.hint')}</span>

      <div className="d-flex gap-2">
        <button onClick={onMain} className="btn btn-primary font-13 weight-500 nowrap">
          {t('basket.empty.main')}
        </button>

        {!authenticated && (
          <button onClick={onLogin} className="btn btn-gray weight-500 font-13 nowrap">
            {t('basket.empty.auth')}
          </button>
        )}
      </div>
    </div>
  )
}
