import {ApplicationContext} from "global-context"
import {Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState} from "react"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router"
import {Link, useLocation} from "react-router-dom"
import {useGlobalModalContext} from "../modal/modal-context"
import "./naviagation.scss"
import {AuthGuestModal} from "pages/auth/modals/guess"


export function Navigation({setBottomHeight}: {setBottomHeight: Dispatch<SetStateAction<number>>}) {
  const {t} = useTranslation()
  const {basket, authenticated, isMobile} = useContext(ApplicationContext)
  const ref = useRef()
  const history = useLocation()
  const navigate = useNavigate()
  const {showModal} = useGlobalModalContext()

  const [location, setLocation] = useState<"main" | "categories" | "basket" | "login" | "profile" | "profile/favorites">()

  const count = useMemo(
    () => basket.children.reduce((acc, item) => acc + item.count, 0),
    [basket.children],
  )

  const onFavorite = () => {
    if (!authenticated) {
      showModal({
        component: <AuthGuestModal text="profile.guest.product" />,
        className: isMobile && "modal-bottom",
        centered: true
      })
      return
    }
    navigate("/profile/favorites")
  }

  useEffect(() => {
    if (history.pathname.includes("categories")) {
      setLocation("categories")
      return
    }
    if (history.pathname.includes("basket")) {
      setLocation("basket")
      return
    }
    if (
      (history.pathname.startsWith("/profile") && history.pathname !== "/profile/favorites") ||
      history.pathname === "/products/all" ||
      history.pathname === "/faqs"
    ) {
      setLocation("profile")
      return
    }
    if (history.pathname.includes("profile/favorites")) {
      setLocation("profile/favorites")
      return
    }
    setLocation("main")
  }, [history])

  useEffect(() => {
    if (!ref.current) return
    const resizeObserver = new ResizeObserver(() => setBottomHeight((ref.current as any).clientHeight))
    resizeObserver.observe(ref.current)
    return () => resizeObserver.disconnect()
  }, [ref])

  if (history.pathname === "/events/visit" && isMobile) return null

  return (
    <div className="navigation" ref={ref}>
      <Link to="/" className={`navigation-item ${location === "main" ? "active" : ""}`}>
        <span className="yume yume-home font-24"></span>
        <div className="name">{t("main_screen")}</div>
      </Link>
      <Link to="/categories" className={`navigation-item ${location === "categories" ? "active" : ""}`}>
        <span className="yume yume-catalog font-24"></span>
        <div className="name">{t("categories_screen")}</div>
      </Link>
      <Link to="/basket" className={`navigation-item ${location === "basket" ? "active" : ""}`}>
        <span className="yume yume-basket font-24"></span>
        {basket.children.length > 0 && <div className="basket-count animate-jump">{count}</div>}
        <div className="name">{t("basket_screen")}</div>
      </Link>
      <div onClick={onFavorite} className={`navigation-item ${location === "profile/favorites" ? "active" : ""}`}>
        <span className="yume yume-like font-24"></span>
        <div className="name">{t("favorites")}</div>
      </div>
      {!authenticated && (
        <div onClick={() => navigate("/profile/guest")} className={`navigation-item ${location === "login" ? "active" : ""}`}>
          <span className="yume yume-user font-24"></span>
          <div className="name">{t("auth.login")}</div>
        </div>
      )}
      {authenticated && (
        <Link to="/profile" className={`navigation-item ${location === "profile" ? "active" : ""}`}>
          <span className="yume yume-user font-24"></span>
          <div className="name">{t("profile.title")}</div>
        </Link>
      )}
    </div>
  )
}
