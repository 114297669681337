import {InputHTMLAttributes, useRef} from 'react'
import {RegisterOptions, useFormContext} from 'react-hook-form'
import InputMask, {Props} from 'react-input-mask'

interface FormControlConfig extends InputHTMLAttributes<HTMLInputElement> {
  id?: any
  rootclassname?: string
  name: string
  params?: RegisterOptions<any, any>
  label?: string
  className?: string
}

export function FormControlPhone(config: FormControlConfig & Partial<Props>) {
  const {register, formState: {errors}, setValue} = useFormContext()
  const error = errors[config.name]
  const ref = useRef<HTMLInputElement>(null)
  const className = `${config.className ? config.className : ''} ${error ? 'invalid' : ''}`

  const handleChange = event => {
    const value = event.target.value.replace(/[-_]/g, "")
    setValue(config.name, value, {shouldDirty: true, shouldValidate: true})
  }

  return (
    <div className={config.rootclassname || ''}>
      {config.label && (
        <div className="d-flex gap-2 mb-2">
          <label className="overflow font-13 color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}
      <InputMask
        {...config}
        {...register(config.name, config.params)}
        inputRef={ref}
        mask="+7-999-999-9999"
        onChange={handleChange}
        placeholder="+7-777-777-77-77"
        className={className}
        autoComplete="off"
      />
    </div>
  )
}
