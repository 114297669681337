export const eventsLocale = {
  header: 'Ивенты',
  empty: "Ивентов пока что нет :(",
  comming: "Скоро",
  passing: "Прошедшие",
  about: "Подробнее",
  visit: "Хочу посетить",
  register_open: "Регистрация откроется <b>{{date}}</b>",
  registered: "Вы зарегистрированы",
  show: "Смотреть как это было",
  comming_events: "Предстоящие ивенты",
  remaining_places: "Осталось <span class='weight-500'>{{count}} мест</span>",
  cancel: "Отменить регистрацию",
  
  form: {
    back: "Назад",
    name: "Имя",
    phone: "Телефон",
    age: "Ваш возраст",
    profession: "Кем являетесь",
    experience: "Как давно в видео индустрии",
    submit: "Отправить заявку",
    noaccess: "Регистрация еще не открыта",
  },

  success: {
    back: "Назад к ивентам",
    main: "На главную",
    label: "Заявка успешно отправлена!",
  }
};
