import {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useParams} from "react-router-dom"
import {ListSlider} from "components/list-slider"
import {EventModel} from "core/models/events"
import {eventsService} from "core/services/events.service"
import {EventCard} from "../event-card"
import "./style.scss"

export const EventsDetailUpcommingList = () => {
  const {t} = useTranslation()
  const {slug} = useParams<{slug: string}>()
  const [list, setList] = useState<EventModel[]>([])

  useEffect(() => {
    eventsService.getEvents({upcomming: "true"}).then(l => setList(l.filter(d => d.slug !== slug)))
    return () => setList([])
  }, [slug])

  const breakpoints = () => {
    let w = window.innerWidth
    if (w > 1024) return 3
    if (w <= 1024 && w >= 768) return 2
    return 1
  }

  return <ListSlider<EventModel>
    header={t("events.comming_events")}
    list={list}
    breakpoints={breakpoints}
    render={(event) => <EventCard upcomming event={event} />}
  />
}
