import axios from "axios"
import {SetModel} from "components/sets-modal/model"
import {OrderDetailModel, OrderModel} from "core/models/order"
import {ProductModel, ProductType} from "core/models/product"
import {BonusTransactionModel, LoyaltyModel, ProfileModel, ProfileStatsModel} from "core/models/user"
import {BehaviorSubject, Observable, from, of, shareReplay, switchMap} from "rxjs"
import {axiosResponce} from "./axios-response"
import tokenService from "./token.service"

export interface TokenModel {
  access: string
  refresh: string
}

class AuthService {
  refreshing: boolean = false

  getProfile$ = new BehaviorSubject<void>(null)
  getProfile = () => this.getProfile$.next()

  profile$: Observable<ProfileModel> = tokenService.token$.pipe(
    switchMap((token) => this.getProfile$.pipe(switchMap(() => {
      if (!token) return of(null)
      return from(axios.get<ProfileModel>('/v1/shared/auth/profile/')).pipe(axiosResponce)
    }))),
    shareReplay(1)
  )

  getFavorites$ = new BehaviorSubject<void>(null)
  getFavorites = () => this.getFavorites$.next()

  favorites$: Observable<ProductModel[]> = tokenService.token$.pipe(
    switchMap((token) => this.getFavorites$.pipe(switchMap(() => {
      if (!token) return of([])
      return from(axios.get<ProductModel[]>('/v1/shared/auth/profile/saves/')).pipe(axiosResponce)
    }))),
    shareReplay(1)
  )

  getSetGroups$ = new BehaviorSubject<void>(null)
  getSetGroups = () => this.getSetGroups$.next()

  setGroups$: Observable<SetModel[]> = tokenService.token$.pipe(
    switchMap((token) => this.getFavorites$.pipe(switchMap(() => {
      if (!token) return of([])
      return from(axios.get<SetModel[]>('/v1/shared/auth/profile/sets/')).pipe(axiosResponce)
    }))),
    shareReplay(1)
  )

  public async login(payload: any): Promise<TokenModel> {
    const res = await axios.post("/v1/shared/auth/", payload)
    return res.data
  }

  public async logout(): Promise<undefined> {
    const res = await axios.post("/v1/shared/auth/logout/", {refresh: tokenService.getRefresh})
    return res.data
  }

  public async signup(payload: any): Promise<any> {
    const res = await axios.post("/v1/shared/auth/register/", payload)
    return res.data
  }

  public async verify(payload: any): Promise<undefined> {
    const res = await axios.post("/v1/shared/auth/register/verify/", payload)
    return res.data
  }

  public async create(payload: any): Promise<undefined> {
    const res = await axios.post("/v1/shared/auth/register/create/", payload)
    return res.data
  }

  public async request_reset_password(payload: any): Promise<any> {
    const res = await axios.post("/v1/shared/auth/password/reset/request/", payload)
    return res.data
  }

  public async reset_password(payload: any): Promise<undefined> {
    const res = await axios.post("/v1/shared/auth/password/reset/", payload)
    return res.data
  }

  public async profile(): Promise<ProfileModel> {
    const res = await axios.get("/v1/shared/auth/profile/")
    return res.data
  }

  public async editAvatar(payload: any): Promise<ProfileModel> {
    const res = await axios.patch("/v1/shared/auth/profile/", payload)
    return res.data
  }

  public async editProfile(payload: any): Promise<ProfileModel> {
    const res = await axios.patch("/v1/shared/auth/profile/", payload)
    return res.data
  }

  public async editPassword(payload: any): Promise<ProfileModel> {
    const res = await axios.patch("/v1/shared/auth/profile/password/", payload)
    return res.data
  }

  public async getStats(): Promise<ProfileStatsModel> {
    const res = await axios.get("/v1/shared/auth/profile/orders/stats/")
    return res.data
  }

  public async bonusHistory(): Promise<BonusTransactionModel[]> {
    const res = await axios.get("/v1/shared/auth/profile/bonuses/")
    return res.data
  }

  public async getOrders(params?: any): Promise<OrderModel[]> {
    const res = await axios.get("/v1/shared/auth/profile/orders/", {params})
    return res.data
  }

  public async getOrder(payload: any): Promise<OrderDetailModel> {
    const res = await axios.get(`/v1/shared/auth/profile/orders/${payload}/`)
    return res.data
  }

  public async createOrderBonus(orderId: number): Promise<OrderDetailModel> {
    const res = await axios.post(`/v1/shared/auth/profile/orders/${orderId}/create_bonus/`)
    return res.data
  }

  public async deleteOrderBonus(orderId: number): Promise<OrderDetailModel> {
    const res = await axios.delete(`/v1/shared/auth/profile/orders/${orderId}/delete_bonus/`)
    return res.data
  }

  public async cancelOrder(id: number, payload: any): Promise<any> {
    const res = await axios.post(`/v1/shared/auth/profile/orders/${id}/cancel/`, payload)
    return res.data
  }

  public async getGroups(payload: any): Promise<Record<ProductType, ProductModel[]>> {
    const res = await axios.get(`/v1/shared/auth/profile/orders/${payload}/groups/`)
    return res.data
  }

  public async postFeedback(id: any, payload: any): Promise<any> {
    const res = await axios.post(`/v1/shared/auth/profile/orders/${id}/reviews/`, payload)
    return res.data
  }

  public async addToFavorites(payload: any): Promise<undefined> {
    const res = await axios.post(`/v1/shared/auth/profile/saves/`, payload)
    this.getFavorites()
    return res.data
  }

  public async billPayment(payload: any): Promise<undefined> {
    const res = await axios.post("/v1/shared/auth/profile/orders/payment/document/", payload)
    return res.data
  }

  public async createSetGroups(payload: any): Promise<any> {
    const res = await axios.post("/v1/shared/auth/profile/sets/", payload)
    this.getSetGroups()
    return res.data
  }

  public async onSetItem(id: number, payload: any): Promise<any> {
    const res = await axios.post(`/v1/shared/auth/profile/sets/${id}/`, payload)
    this.getSetGroups()
    return res.data
  }

  public async listItemsSet(id: number): Promise<ProductModel[]> {
    const res = await axios.get(`/v1/shared/auth/profile/sets/${id}/`)
    return res.data
  }

  public async deleteSet(id: number): Promise<any> {
    const res = await axios.delete(`/v1/shared/auth/profile/sets/${id}/`)
    this.getSetGroups()
    return res.data
  }

  public async loyaltyPlans(): Promise<LoyaltyModel[]> {
    const res = await axios.get(`/v1/shared/auth/loyalties/`)
    return res.data
  }
}

const authService = new AuthService()
export default authService
