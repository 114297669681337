import {useGlobalModalContext} from "components/modal/modal-context"
import {useTranslation} from "react-i18next"
import {useLocation, useNavigate} from "react-router-dom"
import "../styles.scss"

export const AuthGuestModal = ({
    text,
    icon = <span className="yume yume-like color-primary font-24"></span>
}: {
    text: string,
    icon?: JSX.Element
}) => {
    const navigate = useNavigate()
    const {hideModal} = useGlobalModalContext()
    const {t} = useTranslation()
    const location = useLocation()

    const onLogin = () => {hideModal(); navigate(`/auth/login`, {state: location.pathname})}
    const onRegister = () => {hideModal(); navigate(`/auth/request`, {state: location.pathname})}

    return (
        <div className="d-flex flex-column">
            {icon && <div className="favorite-icon mb-2">{icon}</div>}
            <h1 className="mb-0 weight-700 font-20">{t('auth.intro.title')}</h1>
            <p className="mb-0 p-0 mt-3 font-14">{t(text)}</p>
            <div className="d-flex mt-4 gap-2 flex-column">
                <button onClick={onLogin} className="btn btn-primary font-14 weight-500">
                    {t("auth.login")}
                </button>
                <button onClick={onRegister} className="btn btn-gray-100 font-14 weight-500">
                    {t("auth.create_account")}
                </button>
            </div>
        </div>
    )
}
