import {useNavigate} from "react-router"
import timeIcon from "assets/icons/time-icon.svg"
import {useTranslation} from "react-i18next"
import {useSearchParams} from "react-router-dom"


export const BasketCheckoutSuccessPage = () => {
  const [query] = useSearchParams()
  const {t} = useTranslation()
  const navigate = useNavigate()

  const onMain = () => navigate("/")
  const onRequest = () => navigate(`/profile/orders/${query.get("request_id")}`)

  return (
    <div
      style={{maxWidth: "448px", margin: "auto"}}
      className="d-flex flex-column justify-content-center align-items-center text-center"
    >
      <img src={timeIcon} alt="time icon" className="mb-4" />
      <h1 className="weight-700 font-22 mb-0 mb-2">{t("basket.success.title")}</h1>
      <span className="d-inline-block mb-2 color-gray-500">{t("basket.success.bot.message")}</span>
      {/* <a href="https://t.me/YumeRent_bot" target="_blank" className="link btn btn-primary weight-500 col-12 mb-2 d-flex gap-2 align-items-center font-14" rel="noreferrer">
        <span className="yume yume-outer-link font-18"></span>
        <span>{t("basket.success.bot.connect")}</span>
      </a> */}
      <button onClick={onMain} className="btn btn-gray-100 col-12 btn-color-gray-500 mb-2 weight-500 font-14">
        {t("basket.success.back")}
      </button>
      <button onClick={onRequest} className="btn btn-black col-12 weight-500 font-14">
        {t("basket.success.request")}
      </button>
    </div>
  )
}
