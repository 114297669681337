import Select, {ActionMeta, Props} from 'react-select'
import {useFormContext, Controller, RegisterOptions} from 'react-hook-form'
import {colourStyles} from './form-select-color-style'
import {useEffect, useState} from 'react'
import {isNil} from "core/utils/isNil"

interface FormSelectConfig<T> extends Props {
  label?: string
  name: string
  params?: RegisterOptions<any, any>
  options: T[]
  getValue: (id: any) => T | T[]

  disabled?: boolean
}

export default function FormSelectV2<T>(config: FormSelectConfig<T>) {
  const {control, watch} = useFormContext()
  const [selectedOption, setSelectedOption] = useState<T | T[]>(null)
  const value: any | any[] = watch(config.name)

  useEffect(() => {
    if (!isNil(value) && config.getValue !== undefined) {
      setSelectedOption(config.getValue(value))
    }
  }, [value])

  return (
    <div className={config.className}>
      {config.label && (
        <div className="d-flex gap-2 mb-2">
          <label className="overflow font-13 color-gray-400">{config.label}</label>
          {config.required && <div className="form-required"></div>}
        </div>
      )}
      <Controller
        name={config.name}
        control={control}
        rules={config.params}
        render={({field: {onBlur, onChange, ref}, fieldState}) => (
          <Select
            isClearable
            isSearchable
            name={config.name}
            styles={colourStyles(fieldState.invalid)}
            {...config}
            ref={ref}
            value={selectedOption}
            onChange={(val: T | T[], action: ActionMeta<any>) => {
              if (Array.isArray(val)) {
                onChange(val.map(obj => config.getOptionValue(obj)))
              } else {
                onChange(config.getOptionValue(val))
              }
              if (!isNil(config.onChange)) config.onChange(val, action)
            }}
            onBlur={onBlur}
            isDisabled={config.disabled}
            options={config.options}
          />
        )}
      />
    </div>
  )
}
