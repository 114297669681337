import {CategoryProducts} from "../models/category"
import {ListModel} from "../models/common"
import {ProductModel, ProductDetail} from "../models/product"
import axios from "axios"

class ProductService {
  queryparams: any & {page: number}
  productList: ListModel<ProductModel>
  slug: string

  public async categoryProductList(): Promise<CategoryProducts[]> {
    const res = await axios.get("/v1/shared/products/all/")
    return res.data
  }

  public async newProductList(): Promise<ProductModel[]> {
    const res = await axios.get("/v1/shared/products/new/")
    return res.data
  }

  public async listProducts(params?: any): Promise<ListModel<ProductModel>> {
    const res = await axios.get("/v1/shared/products/", {params})
    return res.data
  }

  public async getProduct(slug: string | undefined): Promise<ProductDetail> {
    const res = await axios.get(`/v1/shared/products/${slug}/`)
    return res.data
  }

  public async getRelatedProduct(slug: string | undefined): Promise<ProductModel[]> {
    const res = await axios.get(`/v1/shared/products/${slug}/related/`)
    return res.data
  }
}

const productService = new ProductService()
export default productService
