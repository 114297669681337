import Vimeo from '@u-wave/react-vimeo'
import {useContext, useEffect, useState} from "react"
import {ApplicationContext} from "global-context"
import {ImageField} from "core/utils/image"
import {EventModel} from "core/models/events"
import "./style.scss"

function getVimeoVideoId(url) {
  var match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:$|\/|\?))/i)
  return match ? match[2] || match[1] : null
}

export const EventsDetailPlayer = ({event}: {event: EventModel}) => {
  const link = event.video
  const videoId = getVimeoVideoId(link)
  const {isMobile} = useContext(ApplicationContext)
  const [width, setWidth] = useState<number>(isMobile ? (window.innerWidth - 32) : (window.innerWidth - 80 - 331 - 24) / 2)

  useEffect(() => {
    const handleWindowResize = () => setWidth(isMobile ? (window.innerWidth - 32) : (window.innerWidth - 80 - 331 - 24) / 2)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [isMobile])

  return videoId ? (
    <Vimeo video={videoId} autoplay style={{width}} width={width} height={width * 4 / 3} />
  ) : (
    <ImageField fallback="avatar" src={event.image} style={{objectFit: 'cover', borderRadius: 12}} />
  )
}
