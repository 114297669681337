import {ArticleModel} from "../models/article"
import axios from "axios"

export class ArticleService {
  public async listArticles(): Promise<ArticleModel[]> {
    const res = await axios.get("/v1/shared/articles/")
    return res.data
  }

  public async getArticle(slug: string, params?: any): Promise<ArticleModel> {
    const res = await axios.get(`/v1/shared/articles/${slug}/`, {params})
    return res.data
  }
}

const articleService = new ArticleService()
export default articleService
