export const bonus = {
  level_current: "Мой уровень",
  level_passed: "Уровень пройден",
  max_level: "Вы достигли последнего уровня",
  promo: {
    cashback: `
      <div class="color-white font-14">
        <div class="weight-600 font-15">{{percent}}% кэшбека</div>
        <div>с каждой аренды</div>
      </div>
    `,
    grant: `
      <div class="color-white font-14">
        <div class="weight-600 font-15">{{price}}</div>
        <div>в конкурсе гарантов</div>
      </div>
    `,
    delivery: `
      <div class="color-white font-14">
        <div class="weight-600 font-15">При аренде от {{price}}</div>
        <div>Бесплатная доставка в одну сторону</div>
      </div>
    `,
    event_available: `
      <div class="color-white font-14">
        <div class="weight-600 font-15">Предварительный доступ</div>
        <div>к нашим мероприятиям с предварительной записью</div>
      </div>
    `,
    event_reserved: `
      <div class="color-white font-14">
        <div class="weight-600 font-15">Гарантированное место</div>
        <div>на наших ивентах для вашего удобства</div>
      </div>
    `,
  },
  faq: {
    label: 'Ответы на вопросы',
    questions: [
      'Как накопить Y коины?',
      'Как потратить Y коины?',
      'Как долго хранятся Y коины?',
      'Как увеличить кэшбек?',
    ],
    answers: [
      `
        Каждый раз, когда вы делаете заказ на сайте <span><a href="https://yume.rent/">yume.rent</a></span>, вы автоматически зарабатываете Y коины. Это наш способ выразить благодарность за вашу лояльность. Собирайте Y коины и используйте их для получения н новых уровне и преимуществ.
      `,
      `
        Если у вас есть личный кабинет на нашем сайте, вы можете использовать Y коины при оплате заказов. Это возможно как при оплате через эквайринг на сайте, так и при дистанционной оплате через менеджера. Y коинами можно оплачивать до <b>30% стоимости</b> вашего заказа, что делает каждую вашу покупку ещё более выгодной.
      `,
      `
        Мы храним ваши Y коины в течение <b>365 дней</b>. Однако, если в течение этого срока вы не используете ваши Y коины ни в одной транзакции, они, к сожалению, сгорают. Мы рекомендуем регулярно использовать Y коины, чтобы вы могли наслаждаться всеми преимуществами нашей программы лояльности.
      `,
      `
        <div class="d-flex flex-column gap-2">
          <div>В рамках нашей программы лояльности, мы предлагаем нашим клиентам увеличивать размер кэшбека по мере того, как они совершают покупки на сайте <a href="https://yume.rent/">yume.rent</a>. Вот как это работает:</div>
          <div>Уровень <b>“Blue”</b>: Каждому клиенту, совершившему покупки на общую сумму <b>50 000 тг</b> и более, присваивается уровень <b>“Blue”</b>. Это означает постоянный кэшбек в размере <b>3%.</b></div>
          <div>Переход на уровень <b>“Silver”</b>: Чтобы получить кэшбек <b>6%</b>, необходимо потратить <b>1 000 000 ₸</b> и более. Как только вы достигнете этой суммы, ваш уровень автоматически повысится до “Silver”.</div>
          <div>Максимальный уровень <b>“Gold”</b>: Для достижения уровня <b>“Gold”</b> с кэшбеком в <b>9%</b>, вам нужно потратить более <b>3 000 000 ₸.</b></div>
          <div>Также у вас есть возможность сразу перейти на уровни <b>“Silver”</b> или <b>“Gold”</b>, воспользовавшись подпиской <b>YUME Plus.</b></div>
          <div>Важно отметить: Сумма для перехода на следующий уровень учитывает только те деньги, которые вы платите картой на нашем сайте или наличными в офисе. Оплата бонусами в расчет не идет. Например, если общая стоимость вашего заказа составляет <b>10 000 ₸</b>, и из них <b>3 000 ₸</b> оплачены бонусами, то в вашу сумму покупок зачисляется только <b>7 000 ₸.</b></div>
        </div>
      `
    ]
  },
  subscription: {
    btn_text: `Подключить <span class="weight-600">YUME Plus</span>`,
    label: "Подписка YUME Plus",
    hint: "Повышение уровня и все доп.бонусы",
    sign: 'Оформить подписку',
    durations: ['6 месяцев', '12 месяцев'],
    sections: [
      "<span class='weight-600'>{{percent}}% кэшбека</span> с каждой аренды",
      "Возможность принять участие в грантах на сумму до <span class='weight-600'>{{price}}</span>",
      "Бесплатная доставка в одну сторону при аренде <span class='weight-600'>от {{price}}</span>",
      "<span class='weight-600'>Предварительный доступ</span> к нашим мероприятиям с предварительной записью",
      "<span class='weight-600'>Гарантированное место</span> на наших ивентах для вашего удобства."
    ],
    recommended: "Рекомендуем",
    submit: "Перейти к оформлению"
  },
  history: {
    label: 'История бонусов',
  }
}