import "./styles.scss"

export default function CounterComponent({
    onDecrement,
    onIncrement,
    count,
    max,
    onAdd,
    content,
    className = ""
}: {
    onDecrement: (e: any) => void,
    onIncrement: (e: any) => void,
    count: number,
    max: number,
    onAdd: (e: any) => void,
    content: JSX.Element,
    className?: string
}) {
    return count !== 0 ? (
        <div className={`counter ${className}`}>
            <button className="btn counter-btn animation-opacity col" onClick={onDecrement}>
                <span className={`yume font-18 ${count === 1 ? 'yume-trash' : 'yume-minus'}`}></span>
            </button>
            <span className="animation-opacity counter-count col">{count}</span>
            <button disabled={max <= count} className="btn counter-btn animation-opacity col" onClick={onIncrement}>
                <span className="yume yume-plus font-18"></span>
            </button>
        </div>
    ) : (
        <button className={`btn weight-500 counter-add btn-primary nowrap ${className}`} onClick={onAdd}>
            {content}
        </button>
    )
}
