import {useTranslation} from "react-i18next"

export const ProfileAddressModal = () => {
  const {t} = useTranslation()

  const onAddress = () => window.open("https://2gis.kz/almaty/geo/70000001063258450", "_blank")

  return (
    <div className="d-flex flex-column">
      <h1 className="font-20 weight-700">{t("profile.contacts")}</h1>
      
      <div className="address-field">
        <span className="address-field-label">Адрес</span>
        <div className="address-field-value" dangerouslySetInnerHTML={{__html: t("profile.address")}}></div>
      </div>

      <div className="address-field">
        <span className="address-field-label">{t("profile.contact_placeholder")}</span>
        <div className="address-field-value color-primary">+7 705 333 69 36</div>
      </div>

      <div className="address-field">
        <span className="address-field-label">{t("profile.address_placeholder")}</span>
        <div className="address-field-value color-primary">
          {t("profile.work_time")}
        </div>
      </div>

      <a href="tel:+77053336936" className="btn btn-primary d-flex gap-2 align-items-center weight-500 p-3">
        <span className="yume yume-phone font-18" />
        {t("profile.call")}
      </a>

      <button onClick={onAddress} className="btn btn-black d-flex gap-2 align-items-center weight-500 p-3 mt-2">
        <span className="yume yume-location font-18"></span>
        {t("profile.our_location")}
      </button>
    </div>
  )
}
