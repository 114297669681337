import {useEffect, useContext} from "react"
import {useNavigate} from "react-router-dom"
import {ApplicationContext} from "global-context"
import authService from "core/services/auth.service"
import {FormProvider, useForm} from "react-hook-form"
import {useGlobalModalContext} from "components/modal/modal-context"
import {SuccessModal} from "components/modal/modal-success"
import {useTranslation} from "react-i18next"
import {ImageField} from "core/utils/image"
import FormSelectV2 from "components/shared/input/form-select-v2"
import {genderOptions, jobTitleOptions} from "pages/auth/data"
import {FormControlDateV2} from "components/shared/input/form-date-v2"
import {FormControl} from "components/shared/input/form-control"
import {PasswordEdit} from "./password"
import {HeaderWrapper} from "components/content-header/wrapper"
import "./styles.scss"

interface ProfileEditForm {
  first_name: string
  last_name: string
  birth_date: string
  avatar: any
  extra: {
    gender: string
    job_title: string
  }

  old_password: any
  new_password: any
}

export function ProfileEditPage() {
  const {profile, setContentHeaderConfig, isMobile} = useContext(ApplicationContext)
  const form = useForm<ProfileEditForm>()
  const {handleSubmit, setValue, reset, formState, watch} = form
  const {showModal, hideModal} = useGlobalModalContext()
  const navigate = useNavigate()
  const {t} = useTranslation()

  const handleChange = (e) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      setValue("avatar", e.target.files[0], {shouldDirty: true})
    }
  }

  const onSubmit = handleSubmit((payback) => {
    const form = new FormData()
    if (typeof payback.avatar === 'object') form.append('avatar', payback.avatar)

    delete payback.avatar

    Promise.all([
      authService.editAvatar(form),
      authService.editProfile(payback)
    ]).then(() => {
      showModal({
        component: (
          <SuccessModal
            header={t("profile.success_edit")}
            onConfirm={hideModal}
            confirm_btn="OK"
          />
        ),
        centered: true,
      })
    })
  })

  const onPasswordChange = () => {
    showModal({
      component: <PasswordEdit />,
      centered: true,
      className: isMobile && "modal-bottom"
    })
  }

  useEffect(() => {
    if (profile) reset(profile)
    setContentHeaderConfig({content: <HeaderWrapper>{t("profile.edit_title")}</HeaderWrapper>})
    return () => setContentHeaderConfig({hidden: false})
  }, [profile, isMobile])

  const valid = formState.isValid

  return profile && (
    <FormProvider {...form}>
      <div className="d-flex flex-column gap-3">
        <input
          className="d-none"
          type="file"
          accept="image/png, image/jpg, image/jpeg"
          multiple={false}
          id="input-avatar"
          onChange={handleChange}
        />

        <label className="profile-edit-avatar" htmlFor="input-avatar">
          {watch('avatar') instanceof File ? (
            <ImageField src={URL.createObjectURL(watch('avatar'))} style={{objectFit: 'cover'}} fallback="avatar" alt="avatar" />
          ) : (
            <ImageField src={profile.avatar} style={{objectFit: 'cover'}} fallback="avatar" alt="avatar" />
          )}
        </label>

        <FormControl
          name="phone"
          label={t("profile.phone_label")}
          className="form-control"
          params={{required: true}}
          disabled={true}
        />

        <FormControlDateV2
          label={t("profile.date_label")}
          placeholder={t("auth.signup.birth_date")}
          name="birth_date"
          className="form-control"
          type="date"
        />

        <FormControl
          name="first_name"
          type="text"
          label={t("profile.name_label")}
          placeholder={t("profile.name_label")}
          className="form-control"
          params={{required: true}}
        />

        <FormControl
          name="last_name"
          type="text"
          label={t("profile.lastname_label")}
          placeholder={t("profile.lastname_label")}
          className="form-control"
          params={{required: true}}
        />

        <FormSelectV2
          name="extra.job_title"
          label={t("auth.signup.job_title.label")}
          placeholder={t("auth.signup.job_title.placeholder")}
          options={jobTitleOptions}
          getOptionValue={(option: any) => option.value}
          getOptionLabel={(option: any) => t(option.label)}
          getValue={(ids: string[]) => jobTitleOptions.filter(g => ids.includes(g.value))}
          menuPortalTarget={document.body}
          isSearchable={false}
          isMulti
        />

        <FormSelectV2
          name="extra.gender"
          label={t("auth.signup.gender.none")}
          placeholder={t("auth.signup.gender.none")}
          options={genderOptions}
          getOptionValue={(option: any) => option.value}
          getOptionLabel={(option: any) => t(option.label)}
          getValue={(id: string) => genderOptions.find(g => g.value === id)}
          isSearchable={false}
        />
        <button onClick={onPasswordChange} className="btn btn-black weight-500 d-flex gap-2 w-100 font-14">
          <span className="yume yume-secret font-18"></span>
          {t("profile.password_change")}
        </button>
        <button onClick={onSubmit} disabled={!valid} className="btn btn-primary font-14 weight-500 w-100">
          {t("profile.submit")}
        </button>
      </div>
    </FormProvider>
  )
}
