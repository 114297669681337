export function ErrorModal(config: {
  header: string | JSX.Element
  message?: string | JSX.Element
  confirm_btn?: string
  onConfirm?: () => void
  additional?: JSX.Element
}) {
  return (
    <div className="d-flex flex-column align-items-center">
      <h3 className="font-18 weight-700 text-align-center">{config.header}</h3>

      {config.message !== undefined && <div className="color-gray-500 font-16 weight-400 text-align-center mt-3">{config.message}</div>}

      {config.confirm_btn !== undefined && config.onConfirm && (
        <button onClick={config.onConfirm} className="btn btn-primary mt-3 font-16 weight-500 w-100">
          {config.confirm_btn}
        </button>
      )}
      {config.additional}
    </div>
  )
}
