import "./styles.scss"
import {useTranslation} from "react-i18next"
import callIcon from "assets/icons/call-icon.svg"
import {Link} from "react-router-dom"
const privacyPolicy = "https://yume-cloud.object.pscloud.io/Yume_Rent_%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8_%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf"
const publicOffer = "https://yume-cloud.object.pscloud.io/Yume.rent%20%D0%9F%D0%A3%D0%91%D0%9B%D0%98%D0%A7%D0%9D%D0%AB%D0%98%CC%86%20%D0%94%D0%9E%D0%93%D0%9E%D0%92%D0%9E%D0%A0-%D0%9E%D0%A4%D0%95%D0%A0%D0%A2%D0%90.pdf"
const paymentOperation = "https://yume-cloud.object.pscloud.io/%D0%9E%D0%9F%D0%98%D0%A1%D0%90%D0%9D%D0%98%D0%95%20%D0%9F%D0%A0%D0%9E%D0%A6%D0%95%D0%94%D0%A3%D0%A0%D0%AB%20%D0%9E%D0%9F%D0%9B%D0%90%D0%A2%D0%AB.pdf"
const rentalRules = "https://yume-cloud.object.pscloud.io/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%B0%D1%80%D0%B5%D0%BD%D0%B4%D1%8B%20%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE%D0%BE%D0%B1%D0%BE%D1%80%D1%83%D0%B4%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F.pdf"
const rules = "https://yume-cloud.object.pscloud.io/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%B8%20%D0%BF%D0%BE%D1%80%D1%8F%D0%B4%D0%BE%D0%BA%20%D0%B2%D0%BE%D0%B7%D0%B2%D1%80%D0%B0%D1%82%D0%B0%20%D1%81%D1%80%D0%B5%D0%B4%D1%81%D1%82%D0%B2%20.pdf"

function Footer() {
  const {t} = useTranslation()

  return (
    <footer>
      <div className="contacts">
        <div className="d-flex gap-2 flex-column align-items-start">
          <div
            className="font-16 weight-600 text-align-left "
            dangerouslySetInnerHTML={{__html: t("footer.address")}}
          ></div>
          <div className="font-14 color-gray-400 text-align-left">
            {t("footer.time")}
          </div>
        </div>
        <div style={{gap: 24}} className="d-flex flex-column align-items-end">
          <a
            href="tel:8(705)-333-69-36"
            style={{gap: 12}}
            className="color-black font-16 weight-600 text-align-right d-flex align-items-baseline"
          >
            <img src={callIcon} height={14} alt="call icon" />{" "}
            {t("footer.phone")}
          </a>
          <div className="d-flex gap-3 font-14 gap-2">
            <a
              className="link color-black"
              href="https://t.me/+77053336936"
              target="_blank"
              rel="noreferrer"
            >
              Telegram
            </a>
            <a
              className="link color-black"
              href="https://www.instagram.com/yumerental/"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
            <a
              className="link color-black"
              href="https://wa.me/+77053336936"
              target="_blank"
              rel="noreferrer"
            >
              WhatsApp
            </a>
          </div>
        </div>
      </div>
      <div className="documents">
        <div className="d-flex gap-3 flex-column">
          <div className="d-flex gap-4">
            <Link to="faqs" className="link color-black">
              FAQs
            </Link>
            <Link to="/products/all" className="link color-black">
              {t("profile.tech_list")}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
