export const basket = {
  title: "Корзина",
  products: "{{count}} товаров",
  step: "Шаг {{step}} из {{steps}}",
  clear_basket: "Очистить корзину",
  delivery_map: "Мы доставляем в квадрате Саина - Аль-фараби - ВОАД - Райымбек Батыра",

  empty_list: "Корзина пустая",

  price: "{{count}} шт х {{price_discount}}",
  price_discount: "{{count}} шт х {{price_discount}} / <span class='weight-500 text-decoration-line-through color-red'>{{price}}</span>",

  main_info: "Основная информация",
  details: "Детали",
  attention_text: "Доставка не доступна для первой аренды",
  date: "Дата",
  time: "Время",
  comment: "Комментарий",

  empty: {
    label: "Корзина пуста",
    hint: "Воспользуйтесь каталогом или поиском, чтобы найти всё, что нужно",
    main: "На главную",
    auth: "Войти в аккаунт",
  },

  deleting: {
    message: `Вы удаляете: "{{name}}"`,
    cancel: "отменить",
    delete: "удалить",
  },
  grant: {
    title: "Выиграй грант",
    subtitle: "ДО 300.000 ₸",
    footer: `<span class="d-inline-block mt-2 font-13 color-white">Каждый месяц <span class="color-primary">с 20 по 22 число</span></span>`,
  },
  form: {
    header: "Заполните данные",
    name_placeholder: "Имя",
    last_name_placeholder: "Фамилия",
    address_placeholder: "Адрес доставки",
    address_return_placeholder: "Введите адрес возврата",
    address_message: "Наш адрес: Желтоксан 168А",
    delivery_message: "Ознакомьтесь с зоной доставки",
    pickup_option: "Самовывоз",
    delivery_option: "Доставка",
    delivery_free: "Бесплатно",
    phone_placeholder: "Введите номер телефона",
    comment_placeholder: "Комментарий...",
    rent_period: "Выберите период аренды",
    rent_start: "Начало аренды",
    rent_finish: "Конец аренды",
    date_placeholder: "дд.мм.гггг",
    delivery_zone: "Зоны доставки",
  },
  banner: {
    title: `
      <h1 class='mb-0 color-primary text-uppercase font-18 weight-700'>БЕСПЛАТНАЯ ДОСТАВКА</h1> 
      <h1 class='mb-0 color-white text-uppercase font-18 weight-700'>ПРИ ЗАКАЗЕ ОТ {{price}}</h1>
    `,
    subtitle: "При наличии аккаунта и после верификации",
    button: "Войти или Создать аккаунт",
  },
  submit: {
    checkout: "Перейти к оформлению",
    order: "Оформить заявку на бронь",
    grant: "Подать на грант <br/> (Доступно в 20-22 числах)"
  },
  checkout: {
    title: "Оформление заказа",
    back: "Вернуться в корзину",
  },
  delivery: {
    pickup: "Самовывоз",
    delivery: "Доставка",
    unavailable: "временно недоступна",
    progress: {
      title: 'Бесплатная доставка',
      pending: `
        <span>До бесплатной доставки еще</span>
        <span class='color-primary font-13'>{{price}}</span> 
      `,
      reached: "Ваш заказ достиг суммы более {{price}}"
    }
  },
  success: {
    title: "Ожидайте, с вами свяжется наш менеджер",
    back: "На главную",
    request: "Перейти к заказу",
    bot: {
      message: "Подключитесь к телеграм-боту и получайте уведомления о статусе своего заказа",
      connect: "Подключить бота"
    }
  },
}
