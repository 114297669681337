import {useTranslation} from "react-i18next"

export const UserWelcomeModal = ({firstName}: {firstName: string}) => {
  const {t} = useTranslation()
  return (
    <div className="d-flex gap-3 align-items-center p-3">
      <div style={{borderRadius: '50%', width: 36, height: 36, outline: '5px solid rgba(50, 217, 217, 0.15)', padding: 6, backgroundColor: "var(--color-primary)"}}>
        <span className="yume yume-hello font-24 color-white"></span>
      </div>
      <div className="d-flex flex-column gap-1">
        <div className="font-15 weight-600">{t("profile.welcome", {firstName})}</div>
        <span className="font-12 color-gray-300 ">{t("profile.welcome_description")}</span>
      </div>
    </div>
  )
}
