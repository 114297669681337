export const product = {
  empty: "Пусто",
  add_card: "В корзину",
  to_card: "Перейти в корзину",
  searching: "Поиск...",
  articule: "артикул: {{articule}}",
  required_service: "обязательный пункт: ",
  instruction: "Видеоинструкция",
  set: "комплект",
  set_items: "Что входит в комплект:",
  related: "С этим товаром берут",
  specs: "Xарактеристики",
  specs_header: "Основные характеристики",
  favorite_title: "В избранное",
  share: "Поделиться",
  fav_title: "Добавлено в избранные",
  fav_subtitle: "Нажмите, чтобы добавить в сет",
  fav_set_title: "Выберите сет",
  list_count: "{{count}} товаров",
  load_more: "Показать ещё",
  filter: {
    sort: 'Сортировка',
    empty: 'Без фильтров',
    price_asc: 'Цена по возрастанию',
    price_desc: 'Цена по убыванию',
  }
};
