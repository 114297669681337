import "../styles.scss"
import "dayjs/locale/ru"
import {useContext} from "react"
import type {Dayjs} from "dayjs"
import {DatePicker, TimePicker} from "antd"
import {ApplicationContext} from "global-context"
import dayjs from "dayjs"
import basketService from "core/services/basket.service"
import moment from "moment"
import {useNavigate} from "react-router-dom"
import {useGlobalModalContext} from "components/modal/modal-context"
import {useTranslation} from "react-i18next"
import GrantFormComponent from "pages/grant/form"
import {BasketFormDetails} from "./basket-form-details"
import {BasketFormDetailsAttention} from "./basket-form-details-attention"
import {BasketFormGrantBanner} from "./basket-form-grant-banner"
import {FormControlPhone} from "components/shared/input/form-control-mask"
import {FormControl} from "components/shared/input/form-control"
import {useFormContext} from "react-hook-form"
import {OrderForm} from ".."
import {FormControlTextarea} from "components/shared/input/form-control-textarea"
import DeliveryMapModal from "../delivery-map"

const dateFormat = "DD MMMM"
const timeFormat = "HH:mm"
const hours = Array.from(Array(24).keys())

const disabledHours = (date: Dayjs) => {
  const now = new Date()
  const isToday = now.getMonth() === date.month() && now.getDate() === date.date()
  if (isToday) return hours.filter(h => h < now.getHours() || [3, 4, 5, 6].includes(h))
  return [3, 4, 5, 6]
}

const disabledMinutes = (date: Dayjs, hour: number) => {
  if (hour >= 2 && hour < 7) return [10, 20, 30, 40, 50]
  return []
}

dayjs.locale("ru-RU")

export function BasketFormComponent() {
  const {isMobile, profile, loadBasket} = useContext(ApplicationContext)
  const {showModal, hideModal} = useGlobalModalContext()
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {register, watch, setValue, handleSubmit, formState} = useFormContext<OrderForm>()

  const uuid = localStorage.getItem("user_bag_id")

  const values = watch()
  const isValid = formState.isValid
  const isDelivery = values.delivery

  const createOrder = () =>
    basketService.createOrder(uuid).then((res) => {
      localStorage.removeItem("user_bag_id")
      navigate(`/basket/checkout/success?request_id=${res.request_id}`)
      loadBasket()
    })

  const onSubmit = handleSubmit(async (payload) => {
    await basketService.updateBag(uuid, payload)
    await createOrder()
  })

  const onGrant = handleSubmit((payload) => {
    basketService.updateBag(uuid, payload).then(() => {
      if (profile && profile.grant_sent) {
        aboutGrant(null, "error")
        showModal({
          component: (
            <div className="text-align-center">
              <h3 className="mb-3 weight-700 font-18">
                {t("grant.about.header")}
              </h3>
              <div
                dangerouslySetInnerHTML={{__html: t("grant.about.error")}}
                className="color-gray-500 mb-4"
              />
              <button
                className="btn btn-primary btn-color-black weight-500 font-16"
                style={{width: "100%"}}
                onClick={() => {
                  navigate("/grant")
                  hideModal()
                }}
              >
                Как подать на грант?
              </button>
            </div>
          ),
          className: isMobile && "modal-bottom",
          centered: true,
        })

        return
      }

      if (isMobile) {
        navigate("/grant/form")
      } else {
        showModal({
          component: <GrantFormComponent />,
          centered: true,
          className: isMobile && "modal-bottom",
        })
      }
    })
  })

  const onZoneModal = () => {
    showModal({
      component: <DeliveryMapModal />,
      centered: true,
      className: isMobile && "modal-bottom",
    })
  }

  const aboutGrant = (e, text: string = "text") => {
    showModal({
      component: (
        <div className="text-align-center">
          <h3 className="mb-3 weight-700 font-18">{t("grant.about.header")}</h3>
          <div
            dangerouslySetInnerHTML={{__html: t(`grant.about.${text}`)}}
            className="color-gray-500 mb-4"
          />
          <button
            className="btn btn-primary btn-color-black weight-500 font-16"
            style={{width: "100%"}}
            onClick={() => {
              navigate("/grant")
              hideModal()
            }}
          >
            Как подать на грант?
          </button>
        </div>
      ),
      className: isMobile && "modal-bottom",
      centered: true,
    })
  }

  const startDateTime = values.start_at ? dayjs(values.start_at) : null
  const endDateTime = values.end_at ? dayjs(values.end_at) : null

  const onStartChange = (_date: Dayjs) => {
    const date = _date.toDate()
    if (date.getHours() < 7 && date.getHours() > 2) date.setHours(7)
    if (date > moment(watch('end_at')).toDate()) setValue('end_at', moment(date).add(1, 'hours').toDate())
    setValue('start_at', date)
  }

  const onEndChange = (_date: Dayjs) => {
    const date = _date.toDate()
    if (date.getHours() < 7 && date.getHours() > 2) date.setHours(7)
    if (date < moment(watch('start_at')).toDate()) {
      setValue('end_at', moment(watch('start_at')).toDate())
      return
    }
    setValue('end_at', date)
  }

  const onBack = () => navigate(-1)

  return (
    <div className="form mx-auto">
      <div className="d-none d-lg-flex justify-content-between align-items-center mb-3 gap-2">
        <div onClick={onBack} className="d-flex gap-2 align-items-center pointer">
          <span className="yume yume-left font-24" />
          <span className="weight-700 font-28">{t("basket.checkout.title")}</span>
        </div>
        <span className="color-primary weight-400 font-13">
          {t("basket.step", {step: 2, steps: 2})}
        </span>
      </div>

      <div className="font-13 color-gray-400 mb-2">{t("basket.main_info")}</div>

      <FormControl
        name="extra.name"
        type="text"
        placeholder={t("basket.form.name_placeholder")}
        className="form-control mb-3"
        params={{required: true}}
      />

      <FormControl
        name="extra.last_name"
        type="text"
        placeholder={t("basket.form.last_name_placeholder")}
        className="form-control mb-3"
        params={{required: true}}
      />

      <FormControlPhone
        name="extra.phone"
        className="form-control mb-3"
        params={{required: true}}
      />

      <BasketFormDetails />
      <BasketFormDetailsAttention />

      {isDelivery && (
        <FormControl
          name="address"
          type="text"
          placeholder={t("basket.form.address_placeholder")}
          className="form-control"
          rootclassname="mb-3"
          params={{required: true}}
        />
      )}

      {isDelivery && (
        <div onClick={onZoneModal} className="btn btn-black w-100 weight-500 d-flex gap-2 mb-3">
          <span className="yume yume-assets font-18"></span>
          <span>{t("basket.form.delivery_zone")}</span>
        </div>
      )}

      <div className="font-13 color-gray-400 mb-2">{t("basket.form.rent_start")}</div>
      <div className="d-flex gap-2 mb-3">
        <DatePicker
          placeholder={t("basket.date")}
          className="flex-fill form-control"
          value={startDateTime}
          style={{width: '50%'}}
          onChange={(d) => onStartChange(d)}
          allowClear={false}
          format={dateFormat}
          placement="topRight"
          disabledDate={(date) => {
            if (date.isBefore(dayjs())) return date.date() < dayjs().date()
            return false
          }}
          inputReadOnly
          changeOnBlur
        />
        <TimePicker
          className="flex-fill form-control"
          value={startDateTime}
          style={{width: '50%'}}
          popupStyle={{margin: '12px'}}
          onChange={onStartChange}
          format={timeFormat}
          minuteStep={10}
          allowClear={false}
          disabledTime={date => ({
            disabledHours: () => disabledHours(startDateTime),
            disabledMinutes: (hour) => disabledMinutes(startDateTime, hour)
          })}
          inputReadOnly
          changeOnBlur
        />
      </div>

      <div className="font-13 color-gray-400 mb-2">{t("basket.form.rent_finish")}</div>
      <div className="d-flex gap-2 mb-3">
        <DatePicker
          placeholder={t("basket.date")}
          className="flex-fill form-control"
          value={endDateTime}
          style={{width: '50%'}}
          onChange={(d) => onEndChange(d)}
          allowClear={false}
          placement="topRight"
          format={dateFormat}
          disabledDate={(date) => date < dayjs(watch("start_at"), "YYYY-MM-DD")}
          inputReadOnly
          changeOnBlur
        />
        <TimePicker
          className="flex-fill form-control"
          value={endDateTime}
          style={{width: '50%'}}
          onChange={onEndChange}
          format={timeFormat}
          minuteStep={10}
          allowClear={false}
          disabledTime={date => ({
            disabledHours: () => disabledHours(endDateTime),
            disabledMinutes: (hour) => disabledMinutes(date, hour)
          })}
          inputReadOnly
          changeOnBlur
        />
      </div>

      <FormControlTextarea
        className="mb-3"
        label={t("basket.comment")}
        placeholder="Комментарий..."
        params={register("extra.comment")}
        rows={6}
      />

      <BasketFormGrantBanner />

      <div className="d-flex gap-2 flex-column">
        <button onClick={onSubmit} disabled={!(isValid && !!values.start_at && !!values.end_at)} className="btn btn-primary color-black font-14 weight-500">
          {t("basket.submit.order")}
        </button>
        <button
          onClick={onGrant}
          className="btn btn-gray-200 flex-fill"
          disabled={profile ? !(profile.grant_accessible && !profile.grant_sent) : true}
          dangerouslySetInnerHTML={{__html: t("basket.submit.grant")}}
        ></button>
      </div>
    </div>
  )
}