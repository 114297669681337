import {useContext} from "react"
import {useTranslation} from "react-i18next"
import {Link} from "react-router-dom"
import {ApplicationContext} from "../../global-context"
import {ImageField} from "../../core/utils/image"
import {localeString} from "../../core/utils/localeString"
import "./styles.scss"

export function CategoryList() {
  const {t} = useTranslation()
  const {categories} = useContext(ApplicationContext)

  const categoryList = categories.map((category, key) => (
    <Link to={`/${category.slug}`} className="mobile-menu-item" key={key}>
      <ImageField className="mobile-menu-icon" src={category.icon} alt={category.slug} fallback="icon" />
      <span>{t(localeString(category.extra, "name"))}</span>
    </Link>
  ))

  return <div className="mobile-menu">{categoryList}</div>
}
