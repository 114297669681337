import {useTranslation} from "react-i18next"
import {GrantWinner} from "core/models/grant"
import {useEffect, useState} from "react"
import grantsService from "core/services/grants.service"
import {useParams} from "react-router"
import toDateTime from "core/utils/date-time"
import {GrantWinnerBasketComponent} from "./basket-list"
import "./styles.scss"
import {ImageField} from "core/utils/image"

export function GrantWinnerComponent() {
  const {t} = useTranslation()
  const {id} = useParams()
  const [winner, setWinner] = useState<GrantWinner>()

  useEffect(() => {
    grantsService.getWinner(+id).then(setWinner)
  }, [id])

  const name = winner && [winner.user.first_name, winner.user.last_name].join(" ")

  return winner && (
    <div className="grant-wrapper animation-opacity">
      <div className="winner">
        <ImageField src={winner.user.avatar} style={{objectFit: 'cover'}} className="winner-avatar" alt="avatar" fallback="avatar" />
        <div className="d-flex flex-column">
          <h4 className="font-24 weight-600">{name}</h4>
          <div className="color-gray-400 mb-3">{t("grant.winners.month", {date: toDateTime(winner.created_at, "MMM YYYY")})}</div>
          <a href={winner.portfolio} target="_blank" className="btn btn-primary p-3 py-2 font-16 weight-500" rel="noreferrer">
            {t("grant.winners.file_btn")}
          </a>
        </div>
      </div>

      <hr className="divider mobile" />

      <div className="detail">
        <h4 className="detail-header mb-0">{t("grant.winners.idea_header")}</h4>
        <div className="detail-content">{winner.description}</div>
      </div>
      <hr className="divider mobile" />

      <GrantWinnerBasketComponent basketId={winner.bag} />
    </div>
  )
}
