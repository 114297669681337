import authService from 'core/services/auth.service'
import {ProductModel} from 'core/models/product'
import {useGlobalModalContext} from 'components/modal/modal-context'
import {SetModel} from './model'
import {FormControl} from "components/shared/input/form-control"
import {FormProvider, useForm} from "react-hook-form"

export function CreateSet(config: {group?: ProductModel; onSetAdd: (set: SetModel) => void}) {
  const {hideModal} = useGlobalModalContext()

  const form = useForm()

  const createSetGroups = form.handleSubmit((payload) => {
    authService.createSetGroups(payload).then(async set => {
      if (config.group) {
        const productPayload = {
          type: config.group.type,
          id: config.group.group_id || config.group.set_id || config.group.service_id
        }
        await authService.onSetItem(set.id, productPayload)
      }

      hideModal()

      config.onSetAdd(set)
    })
  })

  return (
    <FormProvider {...form}>
      <div className="create-set">
        <FormControl name="name" className="form-control" params={{required: true}} />
        <div className="buttons">
          <button className="btn btn-primary btn-color-black weight-500 w-100 p-3 font-14" disabled={!form.formState.isValid} onClick={createSetGroups}>
            Сохранить и добавить
          </button>
        </div>
      </div>
    </FormProvider>
  )
}
