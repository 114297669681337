import {useEffect, useState} from "react"
import coin from "./coin.png"
import authService from "core/services/auth.service"
import {ProfileModel} from "core/models/user"
import "./styles.scss"
import {toPrice} from "core/utils/price"

export const loyaltyColor: Record<number, string> = {
    0: 'var(--color-gray-400)',
    3: 'linear-gradient(135deg, #23a8e2 0%, #23cbe2 50%, #67a6ef 100%)',
    6: 'linear-gradient(135deg, #7c98b5 0%, #bdc4d7 50%, #95a7ca 100%)',
    9: 'linear-gradient(135deg, #edaf0d 0%, #eace6d 50%, #e7a117 100%)'
}

export const loyaltyColorRgb: Record<number, string> = {
    0: '134, 134, 136',
    3: '35, 168, 226',
    6: '124, 152, 181',
    9: '250, 184, 49'
}

export function CoinIcon({width = 14}: {width?: number}) {
    return <img src={coin} alt="coin" style={{objectFit: 'contain'}} width={width} />
}

export function Coin({bonus, className}: {bonus: number, className?: string}) {
    const [profile, setProfile] = useState<ProfileModel>()

    useEffect(() => {
        const sub = authService.profile$.subscribe(setProfile)
        return () => sub.unsubscribe()
    }, [])

    return <span className={`${className} y-coin`} style={{background: loyaltyColor[profile ? profile.loyalty_percent || 0 : 0]}}>
        <span>{toPrice(bonus, "")}</span>
        <CoinIcon />
    </span>
}
