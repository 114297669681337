import {useNavigate} from "react-router-dom"
import {ApplicationContext} from "global-context"
import {Dispatch, SetStateAction, useContext, useEffect, useRef} from "react"
import clsx from "clsx"
import {YumeIcon} from "components/aside/icon"
import {ContentHeader} from "components/content-header"
import "./styles.scss"
import {Coin} from "components/coin"

export function Header({setHeaderHeight}: {setHeaderHeight: Dispatch<SetStateAction<number>>}) {
  const ref = useRef()
  const navigate = useNavigate()
  const {isMobile, profile} = useContext(ApplicationContext)

  const onBonus = () => navigate("/bonus")

  useEffect(() => {
    if (!ref.current) return
    const resizeObserver = new ResizeObserver(() => setHeaderHeight((ref.current as any).clientHeight))
    resizeObserver.observe(ref.current)
    return () => resizeObserver.disconnect()
  }, [ref])

  return (
    <div className="header-wrapper" ref={ref}>

      <div className={clsx("header", {shadow: !isMobile})}>

        <YumeIcon type='dark' />

        {profile ? (
          <span onClick={onBonus}><Coin bonus={profile.bonus} className="pointer"/></span>
        ) : (
          <button onClick={onBonus} className="btn btn-primary header-bonus">
            <span className="yume yume-bonus"></span>
            <span className="font-13 weight-600">О бонусах</span>
          </button>
        )}

      </div>

      <ContentHeader default={false} />
    </div>
  )
}
