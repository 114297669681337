import {useContext, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useLocation, useNavigate} from "react-router-dom"
import {ApplicationContext} from "../../global-context"
import {EventModel} from "../../core/models/events"
import {eventsService} from "../../core/services/events.service"
import {BreadcrumbComponent} from "../../components/breadcrumb"
import {SwitcherComponent, SwitcherConfig} from "../../components/switch"
import {EventCard} from "./event-card"
import "./style.scss"


export const EventsList = ({upcomming}: {upcomming: boolean}) => {
  const [events, setEvents] = useState<EventModel[]>([])
  const {setContentHeaderConfig, isMobile} = useContext(ApplicationContext)
  const {t} = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (isMobile) setContentHeaderConfig({hidden: true})
    return () => setContentHeaderConfig({hidden: false})
  }, [isMobile])

  useEffect(() => {
    eventsService.getEvents({upcomming}).then(setEvents)
    return () => setEvents([])
  }, [upcomming])

  const switcherConfig: SwitcherConfig[] = [
    {key: '/events/upcomming', text: t("events.comming"), onClick: () => navigate('/events/upcomming'), className: "px-3 py-2"},
    {key: '/events/previous', text: t("events.passing"), onClick: () => navigate('/events/previous'), className: "px-3 py-2"},
  ]

  return (
    <div className="events">

      <BreadcrumbComponent items={[]} header={t('events.header')} />
      <SwitcherComponent className="bg-light mb-4" items={switcherConfig} active={location.pathname} />

      <div className="events-list">
        {events.length > 0 && events.map((event, i) => <EventCard upcomming={upcomming} key={i} event={event} />)}
      </div>

      {events.length === 0 && <div className="font-20 weight-700 color-gray-400 text-center">{t("events.empty")}</div>}

    </div>
  )
}
