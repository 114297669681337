import {useContext, useEffect} from "react"
import {ApplicationContext} from "../../../global-context"
import checkIcon from "assets/icons/check-gradient.png"
import {useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next"

export const EventsVisitSuccess = () => {
  const {isMobile, setContentHeaderConfig} = useContext(ApplicationContext)
  const {t} = useTranslation()
  const navigate = useNavigate()
  const onMain = () => navigate("/events/upcomming")
  const onEventsPage = () => navigate("/events/upcomming")

  useEffect(() => {
    setContentHeaderConfig({
      content: (
        <div onClick={onEventsPage} className="d-flex gap-2 align-items-center">
          <span className="yume yume-left-arrow color-gray-400 font-24" />
          <span className="color-gray-400 font-14 weight-500">{t('events.success.back')}</span>
        </div>
      ),
    })
    return () => setContentHeaderConfig({hidden: false})
  }, [isMobile])

  return (
    <div className="d-flex flex-column justify-content-center align-items-center text-center">
      <img style={{width: 100, objectFit: 'contain'}} src={checkIcon} alt="" />

      <h1 className="weight-700 font-22 mb-0 my-2 mb-3">
        {t('events.success.label')}
      </h1>
      <button onClick={onEventsPage} className="btn btn-primary weight-500 font-14 mb-3">
        {t('events.success.back')}
      </button>
      <button onClick={onMain} className="btn btn-gray-100 weight-500 font-14">
        {t('events.success.main')}
      </button>
    </div>
  )
}
