import {useGlobalModalContext} from "components/modal/modal-context"
import {ORDER_CANCELED, ORDER_COMPLETED, ORDER_REQUEST} from "core/const"
import {OrderDetailModel} from "core/models/order"
import {useTranslation} from "react-i18next"

const paymentAccess = [ORDER_REQUEST, ORDER_CANCELED, ORDER_COMPLETED]

export function OrderReservedModal({order, onToggle}: {order: OrderDetailModel, onToggle: () => void}) {
    const {hideModal} = useGlobalModalContext()
    const {t} = useTranslation()

    return <>
        <div className="text-align-center mb-4">
            <div className="payment-confirm-header mb-2">{t("order.reserved.label")}</div>
            <div className="payment-confirm-text">{t("order.reserved.hint")}</div>
        </div>
        <div className="payment-buttons-container no-padding">
            <button className="btn btn-primary p-3" disabled={paymentAccess.includes(order.status)} onClick={onToggle}>
                {t("order.payment_btn")}
            </button>
            <div className="btn payment-btn" onClick={hideModal}>{t("order.close_modal")}</div>
        </div>
    </>
}