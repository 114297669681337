import successIcon from "assets/icons/checkbox-success.svg"
import {ApplicationContext} from "global-context"
import {useCallback, useContext, useEffect} from "react"
import {useTranslation} from "react-i18next"
import {Link, Navigate, useNavigate, useSearchParams} from "react-router-dom"
import {useGlobalNotificationContext} from "../../../components/notification/notification-context"
import {ProfileControls} from "./profile-contorls"
import {ImageField} from "core/utils/image"
import {CoinIcon} from "components/coin"
import "./styles.scss"
import {toPrice} from "core/utils/price"

export function ProfilePage(config: {popover: boolean}) {
  const {t} = useTranslation()
  const [query] = useSearchParams()
  const {isMobile, profile, authenticated, logout, setContentHeaderConfig, } = useContext(ApplicationContext)
  const {showNotification} = useGlobalNotificationContext()
  const navigate = useNavigate()

  const onProfileEdit = useCallback(() => navigate('/profile/edit'), [])
  const onBonus = useCallback(() => navigate("/bonus"), [])

  useEffect(() => {
    if (isMobile) setContentHeaderConfig({hidden: true})

    return () => {
      if (isMobile) setContentHeaderConfig({hidden: false, content: undefined})
    }
  }, [profile, isMobile])

  useEffect(() => {
    if (
      query.has("change_password_toast") &&
      JSON.parse(query.get("change_password_toast"))
    ) {
      showNotification({
        component: (
          <div className="shadow rounded-2 p-3 d-flex gap-2 align-items-center">
            <img width={48} height={48} src={successIcon} alt="success icon" />
            <span className="font-15 weight-500">
              Пароль изменен
            </span>
          </div>
        ),
      })
      navigate({search: ""})
    }
  }, [])

  return authenticated ? (
    <div className="profile-wrapper">
      <div className="d-flex align-items-center gap-3 mb-3">
        <ImageField className="profile-wrapper-avatar" src={profile.avatar} fallback="avatar" alt="user icon" />
        <div className="d-flex flex-column gap-1">
          <h4 className="mb-0 weight-700 font-18">{profile.first_name}</h4>
          <span onClick={onProfileEdit} className="color-primary pointer">{t("profile.edit_title")}</span>
        </div>
      </div>

      {!isMobile && config.popover && profile && (
        <div className="d-flex align-items-center mb-3">
          <h5 className="mb-0">{t("user.greet", {name: profile.first_name})}</h5>
          <button className="btn yume yume-edit font-16 mx-2 p-2" onClick={onProfileEdit} />
        </div>
      )}

      <div onClick={onBonus} className="profile-wrapper-bonus mb-3">
        <div className="profile-wrapper-bonus-icon">
          <CoinIcon />
        </div>
        <div className="d-flex flex-column gap-1">
          <div className="profile-wrapper-bonus-count">{t("user.bonus", {value: toPrice(profile.bonus, "")})}</div>
          <div className="profile-wrapper-bonus-info">{t("user.cashback", {percent: profile.loyalty_percent || 0})}</div>
        </div>
        <div className="yume yume-chevron-right color-white font-13 weight-600" style={{marginLeft: 'auto'}}></div>
      </div>

      <Link to="/profile/orders" className="profile-wrapper-card d-flex color-black justify-content-between align-items-center shadow mb-3">
        <div className="d-flex gap-2 align-items-center">
          <span className="yume yume-assets-pending font-24"></span>
          <span className="font-14 weight-500">{t("user.orders_history")}</span>
        </div>
        <div className="yume yume-chevron-right"></div>
      </Link>

      <ProfileControls />

      <div onClick={logout} className="btn gap-2 shadow profile-wrapper-card profile-wrapper-logout">
        <span className="yume yume-upload"></span>
        <span className="font-14 pointer weight-500">{t("user.logout")}</span>
      </div>
    </div>
  ) : (
    <Navigate to="/" />
  )
}
