import {useGlobalModalContext} from 'components/modal/modal-context'
import {ProductItem} from 'components/product-item'
import {SetModel} from "components/sets-modal/model"
import {ProductModel} from "core/models/product"
import authService from 'core/services/auth.service'
import basketService from 'core/services/basket.service'
import {ApplicationContext} from 'global-context'
import {useContext, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router'
import {Link} from 'react-router-dom'
import './styles.scss'
import {toPrice} from "core/utils/price"

export function SetProductPage() {
  const {id} = useParams()
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {isMobile, basket, getBasket, sets, setContentHeaderConfig} = useContext(ApplicationContext)
  const {showModal, hideModal} = useGlobalModalContext()

  const [products, setProducts] = useState<ProductModel[]>([])

  const uuid = localStorage.getItem('user_bag_id')
  const overallPrice = useMemo(() => products.reduce((prev, next) => prev + next.price_discount, 0), [products])

  const set: SetModel = id && sets.find(set => set.id === +id)
  const getProducts = (setId: number) => authService.listItemsSet(setId).then(setProducts)

  useEffect(() => {
    getProducts(+id)
    return () => {
      setProducts([])
    }
  }, [id])

  useEffect(() => {
    setContentHeaderConfig({hidden: isMobile})
  }, [isMobile, id])

  const confirmDelete = () => {
    showModal({
      component: (
        <>
          <div className="mb-2 font-20 weight-700 text-align-center">Подтвердите действие</div>
          <div className="mb-4 font-15 text-align-center">
            Вы точно хотите <span className="color-red">{`удалить сет «${set.name}»?`}</span>
          </div>
          <div className="delete-set-btns">
            <button className="delete-set-btns-confirm color-white" onClick={deleteSet}>
              Удалить сет
            </button>
            <button className="delete-set-btns-reject" onClick={hideModal}>
              Отмена
            </button>
          </div>
        </>
      ),
      centered: true,
      backdrop: 'static',
    })
  }

  const deleteSet = () =>
    authService.deleteSet(+id).then(() => {
      hideModal()
      navigate(-1)
    })

  const sendBasket = () => {
    Promise.all(products.filter(p => {
      return !basket.children.some(c => {
        const isGroup = c.group_id === p.group_id && p.group_id !== null
        const isSet = c.set_id === p.set_id && p.set_id !== null
        const isService = c.service_id === p.service_id && p.service_id !== null
        return isGroup || isSet || isService
      })
    }).map(product => {
      const payload = {
        count: 1,
        group: product.type === 'product' ? product.group_id : null,
        set: product.type === 'set' ? product.set_id : null,
        service: product.type === 'service' ? product.service_id : null
      }
      return basketService.postProduct(uuid, payload)
    })).then(getBasket)
  }

  const groupViews = products.map(product => (
    <ProductItem
      key={product.id}
      product={product}
      set={set}
      action={(id) =>
        authService
          .onSetItem(set.id, {type: product.type, id: id})
          .then(() => getProducts(set.id))
      }
    />
  ))

  return id && set && (
    <div className="set-page animation-opacity">
      <div className="set-page">
        <div className="set-page-header">
          <div className="set-page-header-left">
            <Link to="/profile/favorites/sets" className="yume yume-left font-24 color-black" />
            <div className="set-name">{set.name}</div>
            {isMobile && (
              <button className="btn btn-color-red bg-red-8 gap-2 font-12 py-2 delete-set" onClick={confirmDelete}>
                <span className="yume yume-trash font-18"></span>
                Удалить сет
              </button>
            )}
          </div>

          <div className="set-page-header-right">
            {!isMobile && (
              <button className="btn delete-set btn-color-red bg-red-8 gap-2" onClick={confirmDelete}>
                <span className="yume yume-trash"></span>
                Удалить сет
              </button>
            )}

            <div className="set-page-header-right-add-to-cart">
              <div className="basket-block">
                <button className="btn gap-2 btn-primary w-100" disabled={products.length === 0} onClick={sendBasket}>
                  <span className="yume yume-plus font-16"></span>
                  <span>Добавить сет в корзину</span>
                </button>
              </div>
              <span className="overall-price">Общая сумма: {toPrice(overallPrice)}</span>
            </div>
          </div>
        </div>

        {products.length > 0 ? (
          <div className="product-list-holder animation-opacity pb-5" children={groupViews} />
        ) : (
          <div className="favorite-list-empty">
            <div className="font-16 color-gray-400">{t('order.empty_set_list')}</div>
            <Link to="/" className="btn btn-black font-15 weight-500 color-white">
              {t('order.go_to_catalog')}
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
